.top{
  &_mainvisual{
    position: relative;
    background-color: #efebe2;
    &_slider{
      &_item{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        width: 100%;
        margin: 0;
        @include media($middle){
          background-attachment: inherit;
        }
      }
    }
    &_box{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };
    }
    &_slider-mini{
      display: flex;
      justify-content: flex-end;
      padding: 20px 0;
      @include media($middle){

      };
      &_item{
        margin-left: 28px;
        position: relative;
        cursor: pointer;
        @include media($middle){
          margin-left: 10px;
        };

        &::after{
          content:"";
          display: inline-block;
          width: 68px;
          height: 68px;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 100%;
          position: absolute;
          top: 0;
          transition: 0.5s;
          @include media($middle){
            width: 40px;
            height: 40px;
          };
        }
        figure img{
          border-radius: 100%;
          @include media($middle){
            width: 40px;
          };
        }
        &--active::after{
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
    &_catch{
      font-family: $font-helvetica;
      font-weight: bold;
      font-size: 8.5rem;
      line-height: 1.3;
      letter-spacing: 0.25em;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      margin-bottom: 0;
      height: 380px;
      @include media($middle){
        font-size: 3.4rem;
        height: 180px;
      }
      &_row{
        display: block;
        position: relative;
        &--01{
          left: -6.5px;
          @include media($middle){
            left: -4px;
          }
        }
        &--03{
          left: -6.5px;
          @include media($middle){
            left: -4px;
          }
        }
        &--ja{
          font-size: 1.8rem;
          font-weight: bold;
          letter-spacing: 0.2em;
          margin: 20px 0 0 100px;
          opacity: 0;
          @include media($middle){
            margin: 20px 0 0 15px;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  &_event{
    background-color: #efebe2;
    position: relative;
    z-index: 1;
    padding: 10px 0 50px;
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };
    }
    &_main{
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      @include media($middle){
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
      };
      .heading-ptn-01{
        margin-right: 30px;
        @include media($middle){
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 10px;
        };
      }
      .heading-ptn-02{
        @include media($middle){
          margin: 0 auto 10px;
        };
      }
      .btn-ptn-01{
        margin-right: 120px;
        @include media($middle){
          margin-right: 0;
          margin-bottom: 20px;
        };
      }
    }
    &_archive{
      display: flex;
      margin: 0 0 40px 0;
      @include media($middle){
        flex-wrap: wrap;
        margin: 0 0 10px 0;
      }
      &_item{
        position: relative;
        width: 250px;
        margin-right: 28px;
        background-color: #fcfcfa;
        @include omega(4n);
        @include media($middle){
          width: 49%;
          margin-right: 2%;
          @include omega(2n);
          margin-bottom: 5%;
        }
        &_tag{
          position: absolute;
          font-size: 1.4rem;
          font-weight: bold;
          background-color: #fff;
          width: 90px;
          text-align: center;
          border-left: 17px solid #666;
          margin-right: 5px;
          @include media($middle){
            font-size: 1rem;
            width: 60px;
            border-left: 11px solid #666;
            margin-right: 5px;
          };
        }
        &_tag.event_event{
          border-left: 17px solid #96a96d;
          @include media($middle){
            border-left: 11px solid #96a96d;
          };
        }
        &_tag.event_openhouse{
          border-left: 17px solid #d19c82;
          @include media($middle){
            border-left: 11px solid #d19c82;
          };
        }
        &_tag.event_seminar{
          border-left: 17px solid #cbb277;
          @include media($middle){
            border-left: 11px solid #cbb277;
          };
        }
        &_tag.event_end{
          border-left: 17px solid #666;
          @include media($middle){
            border-left: 11px solid #666;
          };
        }


        &_area{
          font-size: 1.1rem;
          font-weight: bold;
          margin-bottom: 5px;
          padding: 0 20px;
          letter-spacing: 0;
          line-height: 1.6;
          @include media($middle){
            padding: 0 10px;
          };
        }
        a{
          display: block;
          padding-bottom: 20px;
          background-color: #fcfcfa;
          @include media($middle){
            min-height: 245px;
          };
        }
        &_thumb{
          margin-bottom: 10px;
        }

        &_heading{
          font-size: 1.4rem;
          font-weight: bold;
          margin-bottom: 0;
          line-height: 1.5;
          padding: 0 15px;
          @include media($middle){
            font-size: 1.2rem;
            padding: 0 10px;
          }
        }
        &_time{
          font-size: 1.2rem;
          margin: 0;
          line-height: 1.5;
          display: block;
          margin-top: 5px;
          padding: 0 15px;
          letter-spacing: 0;
          @include media($middle){
            font-size: 1.1rem;
            padding: 0 10px;
          }
        }
      }
    }
    &_link{
      text-align: center;
      .btn-ptn-01{
        text-align: left;
      }
    }
  }

  &_modelhouse{
    padding: 100px 0 80px;
    text-align: center;
    @include media($middle){
      padding: 40px 0;
    };
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };
    }
    .heading-ptn-01{
      margin-bottom: 50px;
      @include media($middle){
        margin-bottom: 30px;
        font-size: 3.3rem;
      };
    }
    .heading-ptn-02{
      margin-bottom: 50px;
      @include media($middle){
        margin-bottom: 30px;
      };
    }
  }


  &_concept{
    background-color: #babdab;
    @include media($middle){
      padding: 40px 0;
    }
    &_col-02{
      display: flex;
      @include media($middle){
        display: block;

      };
      &_slider{
        width: 45%;
      }
      &_left{
        width: 100%;
        transition: 1s cubic-bezier(.86,0,.07,1);
      }
      &_right{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 55%;
        @include media($middle){
          width: auto;
        }

        .heading-ptn-01{
          margin-bottom: 50px;
          @include media($middle){
            margin-bottom: 20px;
            text-align: center;
          };
        }
        &_link li{
          margin-bottom: 20px;
          @include media($middle){
            margin-bottom: 10px;
          };
        }
        &_box{
          @include media($middle){
            margin: 0 5%;
          };
          &_slider{
            display: none;
            @include media($middle){
              display: block;
              margin-bottom: 15px;
            }
          }
          &_img{
            @include media($middle){
              width: 100%;
              margin-bottom: 10px;
            };

          }
        }
      }
      &_heading{
        font-weight: bold;
        font-size: 4rem;
        letter-spacing: 0.05em;
        line-height: 1.5;
        position: relative;
        right: 15px;
        margin-bottom: 50px;
        @include media($middle){
          position: static;
          font-size: 1.8rem;
          margin-bottom: 15px;
        }
      }
    }

    &_txt{
      font-size: 1.4rem;
      margin-bottom: 30px;
      width: 500px;
      line-height: 2;
      @include media($middle){
        width: 100%;
        font-size: 1.2rem;
      };
    }
    &_abs-01{
      position: absolute;
      left: -110px;
      bottom: -365px;
      @include media($middle){
        position: static;
        display: none;
      }
    }
    &_abs-02{
      position: absolute;
      left: 245px;
      bottom: -290px;
      @include media($middle){
        position: static;
        display: none;
      }
    }
  }

  &_service{
    background-color: #efebe2;
    padding: 100px 0 60px;
    @include media($middle){
      padding: 40px 0;
    };
    &_container{
      text-align: center;
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };
    }
    .heading-ptn-01{
      margin-bottom: 60px;
      @include media($middle){
        margin-bottom: 30px;
      };
    }
    .heading-ptn-02{
      margin-bottom: 60px;
      @include media($middle){
        margin-bottom: 40px;
      };
    }
    &_txt{
      font-size: 1.4rem;
      line-height: 2.2;
      margin-bottom: 50px;
      @include media($middle){
        font-size: 1.2rem;
        margin-bottom: 30px;
      };
    }
    &_list{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 40px;
      @include media($middle){
        margin-bottom: 0;
      };
      &_item{
        min-height: 143px;
        @include media($middle){
          width: 49%;
          margin-bottom: 30px;
        };

        &_name{
          font-size: 1.4rem;
          font-weight: bold;
          line-height: 1;
          background-color: #ffffff;
          width: 165px;
          height: 42px;
          border: 1px solid #dedee0;
          padding-top: 7px;
          margin-bottom: 0;
          @include media($middle){
            font-size: 1.2rem;
            width: auto;
            line-height: 1.2;
          };
          span{
            font-size: 1rem;
            font-family: $font-helvetica;
            font-weight: normal;
          }
        }
        &_name.reform{
          font-size: 1.2rem;
          letter-spacing: 0;
          @include media($middle){
            font-size: 1rem;
            line-height: 1.2;
          };
          span{
            letter-spacing: 0.05em;
            margin-top: 6px;
            display: inline-block;
            @include media($middle){
              margin-top: 4px;
            }
          }
        }

        figure{
          height: 102px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          @include media($middle){
            img{
              padding: 5px;
            }
          }
        }
      }
      &:nth-of-type(2) figure{
        height: 112px;
      }
    }
  }

  &_works{
    padding: 100px 0 160px;
    @include media($middle){
      padding: 40px 0 60px;
    }
    &_main{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @include outer-container($content-width);
      @include media($middle){
        flex-direction: column;
        align-items: flex-start;
        margin: 0 5% 20px;
      };

      .heading-ptn-01{
        margin-right: 30px;
        @include media($middle){
          margin-right: auto;
          margin-left: auto;
          margin-right: 10px;
          font-size: 3rem;
        };
      }
      .heading-ptn-02{
        @include media($middle){
          margin: 0 auto 20px;
        };
      }
      .btn-ptn-01{
        margin-right: 120px;
        @include media($middle){
          margin-right: 0;
        };
      }
      &_title{
        display: flex;
        align-items: center;
        @include media($middle){
          margin-bottom: 20px;
          justify-content: center;
        };
      }
    }

    &_slider{
      position: relative;
      @include clearfix;
      margin-bottom: 35px;
      @include media($middle){
        margin-bottom: 30px;
      }
      &_item{
        background: #ffffff;
        &_thumb{
          position: relative;
          img{
            width: 100%;
            height: auto;
          }
        }
        &_icon-movie{
          display: inline-block;
          width: 65px;
          position: absolute;
          right: 10px;
          top: 10px;
          @include media($middle){
            width: 40px;
            right: 5px;
            top: 5px;
          }
        }
        &_contents{
          border: 1px solid #dedee0;
          position: relative;
          padding: 20px 30px;
          @include media($middle){
            padding: 15px 25px;
            min-height: 140px;
          }
          &:after{
            content: "";
            display: inline-block;
            width: 12px;
            height: 22px;
            background: url(./assets/img/icon_arrow_white_right.png) no-repeat center center;
            background-size: cover;
            position: absolute;
            top: 0;
            right: 20px;
            bottom: 0;
            margin: auto;
            @include media($middle){
              width: 6px;
              height: 11px;
              right: 10px;
            }
          }
          &_num{
            font-size: 7.5rem;
            font-weight: bold;
            font-family: $font-helvetica;
            position: absolute;
            top: -62px;
            right: 22px;
            @include media($middle){
              font-size: 5rem;
              top: -47px;
              right: 15px;
            };
          }
        }
        &_cats{
          font-size: 0;
          p{
            margin-right: 10px;
            @include media($middle){
              margin-right: 5px;
            }
          }
        }
        &_category{
          color: #333333;
          background-color: #f1ece2;
          text-align: center;
          font-size: 1.4rem;
          display: inline-block;
          line-height: 1.7;
          padding: 0 10px;
          min-width: 108px;
          font-weight: bold;
          @include media($middle){
            font-size: 1.1rem;
            min-width: 60px;
            padding: 0 5px;
          }
        }
        &_type-category{
          background-color: #ccc;
          text-align: center;
          font-size: 1.4rem;
          display: inline-block;
          line-height: 1.7;
          padding: 0 10px;
          min-width: 108px;
          @include media($middle){
            font-size: 1.1rem;
            min-width: 60px;
            padding: 0 5px;
          }
        }
        &_heading{
          font-weight: 500;
          margin-bottom: 25px;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1;
          @include media($middle){
            font-size: 1.4rem;
          }
        }
        &_area{
          margin-bottom: 0;
          line-height: 1.2;
          font-size: 1.4rem;
          margin-bottom: 9px;
          @include media($middle){
            font-size: 1.2rem;
          }
        }
      }
    }
    .slick-track{
      display: flex;
      .slick-slide{
          height: auto !important;
          display: flex;
          flex-grow: 1;
          a{
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            height: 100% !important;
            .top_works_slider_item_thumb{
                }
                .top_works_slider_item_contents{
                  flex-grow: 1;
                }
          }
        // display: flex;
        // flex-direction: column;
        // flex-grow: 1;
        // height: 100%;
        // a{
        //   display: flex;
        //   flex-direction: column;
        //   .top_works_slider_item_thumb{
        //     flex-grow: 1;
        //   }
        //   .top_works_slider_item_contents{
        //     flex-grow: 1;
        //   }
        // }
      }

    }
    .slick-arrow{
      width: 45px;
      height: 79px;
      &.slick-prev{
        position: absolute;
        left: 43%;
        top: 111%;
        z-index: 2;
        @include media($middle){
          left: 35%;
        };
        &:before{
          content: "";
          display: inline-block;
          width: 82px;
          height: 80px;
          background: url(./assets/img/top_works_prev.png) no-repeat center center;
          background-size: cover;
          @include media($middle){
            width: 41px;
            height: 40px;
          }
        }
      }
      &.slick-next{
        position: absolute;
        right: 43%;
        top: 111%;
        z-index: 2;
        @include media($middle){
          right: 35%;
        };
        &:before{
          content: "";
          display: inline-block;
          width: 82px;
          height: 80px;
          background: url(./assets/img/top_works_next.png) no-repeat center center;
          background-size: cover;
          @include media($middle){
            width: 41px;
            height: 40px;
          }
        }
      }
    }
  }

  &_blog{
    background-color: #babdab;
    padding: 100px 0 55px;
    @include media($middle){
      padding: 40px 0 0;
    }
    &_container{
    @include outer-container($content-width);
    display: flex;
    @include media($middle){
      margin: 0 5%;
      flex-direction: column;
    };
    }
    &_header{
      width: 240px;
      @include media($middle){
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      };
      .heading-ptn-01{
        margin-bottom: 52px;
        letter-spacing: 0.05em;
        @include media($middle){
          margin-bottom: 0;
          margin-right: 20px;
        };
      }
    }
    &_archive{
      display: flex;
      margin-left: 30px;
      margin-bottom: 45px;
      @include media($middle){
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0 30px;
      }
      &_item{
        margin-right: 30px;
        background: #fff;
        &:nth-of-type(3){
          @include media($large){
            margin-right: 0;
          }
        }
        @include media($middle){
          width: 49%;
          margin: 0 0 2.5%;
        }
        &_under{
          padding: 20px;
          background-color: #ffffff;
          @include media($middle){
            padding: 20px 15px;
          }
        }
        &_info{
          &_time{
            font-size: 1.2rem;
            display: inline-block;
            @include media($middle){
              font-size: 1.2rem;
            }
          }
        }
        &_heading{
          width: 210px;
          font-size: 1.4rem;
          font-weight: bold;
          margin-bottom: 0;
          @include media($middle){
            width: 100%;
            line-height: 1.6;
          }
          a{
            @include media($middle){
              display: inline-block;
              font-size: 1.2rem;
            };
          }
        }
        &_img{
          width: 250px;
          @include media($middle){
            width: 100%;
          };
          img{
            width: 100%;
          }
        }
      }
    }
  }

  &_office{
      background-color: #efebe2;
      padding: 90px 0 95px;
      @include media($middle){
        padding: 40px 0;
      };
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      }
    }
    &_heading{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 55px;

      @include media($middle){
        flex-direction: column;
        margin-bottom: 40px;
      };
      .heading-ptn-01{
        @include media($middle){
          margin-bottom: 20px;
        };
      }
    }
    &_col-05{
      display: flex;
      justify-content: center;
      @include media($middle){
        flex-wrap: wrap;
      };
      &_item{
          width: 216px;
          background-color: #ffffff;
          border-bottom: 1px solid #dedee0;
        @include media($middle){
          width: 50%;
          margin-bottom: 10px;
        }
        &_contents{
          border-left: 1px solid #dedee0;
          border-right: 1px solid #dedee0;
          min-height: 220px;
          padding: 20px;
          @include media($middle){
            padding: 10px;
            min-height: 135px;
          };
        }
        &_heading{
          font-size: 1.6rem;
          font-weight: bold;
          min-height: 50px;
          line-height: 1.5;
          @include media($middle){
            font-size: 1.3rem;
            margin-bottom: 5px;
              min-height: 35px;
          }
          span{
            letter-spacing: 0;
          }
        }
        &_txt{
          font-size: 1.4rem;
          margin-bottom: 0;
          line-height: 1.6;
          letter-spacing: 0em;
          @include media($middle){
            font-size: 1.1rem;
            line-height: 1.4;
          }
        }
      }
    }
  }
  .top_concept_col-02_right_link{
    @include media($middle){
      text-align: center;
    }
  }
}

.slick-vertical .slick-slide{
  border: 0px;
}

.slick-dotted.slick-slider{
  margin-bottom: 0px;
}


.slider_sample {
  opacity: 0;
  transition: opacity .3s linear;
}
.slider_sample.slick-initialized{
  opacity: 1;
}

.top_concept_col-02_slider{
  .top_concept_col-02_left{
    img{
      position: relative;
      top: 0.5px;
    }
    &.slick-current{
      img{
        //transform: scale(1);
        //transition: transform 1s cubic-bezier(0.8, 0, 0.35, 1);
      }
    }
  }
}
.slick-dots{
  bottom: 15px;
  text-align: right;
  padding-right: 20px;
  li{
    margin: 0;
    &:hover{
      background: none;
    }
    button{
      &:hover{
        background: none;
      }
      &::before{
        color: #ffffff;
        opacity: 0.8;
        transition: 1s;
        font-size: 7px
      }
    }
  }
  .slick-active button{
    &::before{
      color: #ffffff !important;
      opacity: 1 !important;
      transform: scale(1.5);
    }
  }
}

.miele_bnr{
  margin-top: 40px;
  text-align: center;

  a{
    transition: 0.3s;
    &:hover{
      opacity: 0.7;
    }
  }
}
