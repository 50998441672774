.material{

  .contact_form_img{
    text-align: center;
    margin-bottom: 60px;
    @include media($middle){
      margin-bottom: 30px;
    }
  }

  .contact_form{
    padding: 30px 0 100px;
    @include media($middle){
      padding: 20px 0 60px;
    }
  }

  &_head{
    margin-bottom: 45px;
    @include outer-container($content-width);
    @include media($middle){
      margin: 0 5%;
    };
    &_txt{
      margin-bottom: 45px;
      @include media($middle){
        font-size: 1.4rem;
        margin-bottom: 30px;
      };
    }
    &_image{
      text-align: center;
    }
  }

}
