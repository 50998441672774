.sdgs{
  padding-bottom: 0;

  &_head{
    margin-top: 50px;
    margin-bottom: 110px;
    @include media($middle){
      margin-bottom: 60px;
      margin-top: 30px;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
  }

  &_about{
    background-color: #babeac;
    padding-bottom: 80px;
    @include media($middle){
      padding-bottom: 45px;
    }

    .heading-ptn-04{
      width: 485px;
      @include media($middle){
        width: 100%;
      };
    }

    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_white-box{
      text-align:center;
      background: #fff;
      padding: 50px;
      margin-top: -20px;
      margin-bottom: 50px;
      @include media($middle){
        text-align: left;
        padding: 20px;
        margin-top: 0px;
        margin-bottom: 30px;
      }

      &_heading{
        margin-bottom: 20px;
        text-align: center;
        @include media($middle){
          margin-bottom: 15px;
        }
      }
      &_txt{
        color: #000;
        margin-bottom: 0;
        font-size: 1.4rem;
        @include media($middle){
        font-size: 1.2rem;
        }
      }
      &_thumb-01{
        margin-bottom: 25px;
        @include media($middle){
          margin-bottom: 15px;
          text-align: center;
        }
      }
      &_thumb-02{
        margin-bottom: 50px;
        @include media($middle){
          margin-bottom: 15px;
          text-align: center;
        }
      }
    }
    &_txt{
      text-align: center;
      margin-bottom: 20px;
      @include media($middle){
        margin-bottom: 20px;
        text-align: left;
        font-size: 1.2rem;
      }
    }
    &_link{
      text-align: center;
      font-size: 1.4rem;
      margin-bottom: 10px;
      @include media($middle){
        font-size: 1.2rem;
        display: flex;
      };
      dt{
        width: 132px;
        margin-right: -4px;
        height: 42px;
        padding: 8px 30px 0 0px;
        display: inline-block;
        vertical-align: middle;
        color: #ffffff;
        background-color: #333333;
        position: relative;
        @include media($middle){
          margin: 0 0 5px 0;
          padding: 14px 10px 0 0px;
          height: 50px;
        }
        &::after{
          content:"";
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 30px 42px 0;
          border-color: transparent #ffffff transparent transparent;
          position: absolute;
          top: 0;
          right: 0;
          @include media($middle){
            border-width: 0 20px 50px 0;
          };
        }
      }
      dd{
        position: relative;
        right: 1px;
        width: 358px;
        height: 42px;
        padding-top: 6px;
        background-color: #ffffff;
        display: inline-block;
        vertical-align: middle;
        @include media($middle){
          line-height: 1.4;
          height: 50px;
          display: block;
          padding-top: 8px;
        }
      }
    }
  }

  &_initiative{
    background: #efebe2;
    padding: 75px 0 130px;
    @include media($middle){
      padding: 40px 15px;
    }
    &_num{
      margin: -10px 5px 0;
      @include media($middle){
        width: 30px;
        margin: -5px 5px 0;
      };
    }
    &_container{
      @include outer-container($content-width);
    }
    &_box{
      padding-top: 40px;
      margin-bottom: 100px;
      @include media($middle){
        padding-top: 30px;
        margin-bottom: 30px;
      }
    }
    &_item{
      background: #fff;
      position: relative;
      padding: 50px;
      color: #000000;
      margin-bottom: 50px;
      @include media($middle){
        padding: 45px 15px 30px;
        margin-bottom: 20px;
      }
      &_num{
        width: 150px;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        color: #ffffff;
        background-color: #ec6941;
        border-radius: 50px;
        line-height: 1.8;
        margin: 0 auto -20px;
        position: relative;
        z-index: 1;
        padding: 9px;
        @include media($middle){
          line-height: 1.6;
          font-size: 1.3rem;
        }
        span{
          display: inline-block;
          margin-left: 5px;
          img{
            vertical-align: middle;
            height: 20px;
            width: auto;
            position: relative;
            bottom: 3px;
            @include media($middle){
              height: 18px;
              bottom: 2px;
            }
          }
        }
      }
      &_col-02{
        width: 100%;
        display: flex; /* 子要素をflexboxで揃える */
        justify-content: center; /* 子要素をflexboxにより中央に配置する */

        @include media($middle){
          display: block;
        }
        &_icon{
          width: 180px;
          @include media($middle){
            display: flex;
            justify-content: center;
            width: 120px;
            margin: 0 auto 20px;
          }
          img{
            margin-bottom: 10px;
            @include media($middle){
              margin-bottom: 0;
            };
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
        &_contents{
          width: 800px;
          padding-left: 40px;
          @include media($middle){
            width: auto;
            padding: 0;
          }
          &_col-02{
            @include clearfix;
          }
        }
        &_heading{
          font-size: 2.2rem;
          font-weight: 500;
          font-family: $font-noto;
          margin-bottom: 40px;
          @include media($middle){
            font-size: 1.6rem;
            margin-bottom: 10px;
          }
        }
        &_txt{
          font-size: 1.4rem;
          width: 460px;
          float: left;
          margin-bottom: 0;
          letter-spacing: 0.05em;
          @include media($middle){
            font-size: 1.2rem;
            width: auto;
            float: none;
            margin-bottom: 15px;
          }
        }
        &_thumb{
          width: 270px;
          float: right;
          @include media($middle){
            width: auto;
            float: none;
            text-align: center;
          }
        }
      }
    }
    &_bottom{
      text-align: center;
      font-size: 2rem;
      font-family: $font-noto;
      margin-bottom: 0;
      background-color: #ffffff;
      border: 4px solid #babeac;
      padding: 45px 0;
      line-height: 2;
      @include media($middle){
        font-size: 1.4rem;
        padding: 20px 15px;
        line-height: 1.8;
      }
    }
  }

}
