.specialbuilding{

  &_navi{
    display: flex;
    justify-content: center;
    margin-top: -145px;
    margin-bottom: 100px;
    @include media($middle){
      display: block;
      @include clearfix;
      padding: 0 15px;
      margin-top: -75px;
      margin-bottom: 30px;
    }
    &_item{
      width: 365px;
      margin: 0 15px;
      background: #fff;
      text-align: center;
      @include media($middle){
        margin-left: 0;
        @include span-columns(6);
        @include omega(2n);
      }
      a{
      }
      &_thumb{
      }
      &_txt{
        position: relative;
        margin: 0;
        font-size: 1.8rem;
        font-weight: bold;
        padding: 15px 10px;
        border: 1px solid #dedee0;
        border-top: none;
        @include media($middle){
          font-size: 1.2rem;
          padding: 10px 10px;
        }
        &:after{
          content: "";
          width: 14px;
          height: 8px;
          display: inline-block;
          background: url(./assets/img/icon_arrow_green.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          top: 0;
          right: 30px;
          bottom: 0;
          margin: auto;
          @include media($middle){
            right: 10px;
            width: 10px;
            height: 6px;
          }
        }
      }
    }
  }

  &_sec{
    &_col-02{
      margin-bottom: 80px;
    }
    &_head{
      display: flex;
      background: #caccbe;
      justify-content:space-between;
      align-items: center;
      margin-bottom: 90px;
      @include media($middle){
        display: block;
        margin-bottom: 30px;
      }
      &_thumb{
        width: 45vw;
        @include media($middle){
          width: 100%;
          text-align: center;
        }
      }
      &_contents{
        width: 55vw;
        padding-left: 5vw;
        @include media($middle){
          width: 100%;
          padding: 25px 15px 30px;
        }
      }
      &_num{
        color: #fff;
        font-family: $font-helvetica;
        font-size: 2.4rem;
        font-weight: 500;
        margin-bottom: 20px;
        font-weight: 500;
        @include media($middle){
          font-size: 1.8rem;
        }
        &:before{
          content: "";
          display: inline-block;
          width: 30px;
          height: 3px;
          background: #fff;
          vertical-align: middle;
          margin-right: 15px;
          position: relative;
          bottom: 2px;
          @include media($middle){
            width: 20px;
            height: 1px;
          }
        }
      }
      &_heading-area{
        position: relative;
        background: #969b7d;
        color: #fff;
        padding: 15px 30px;
        margin-bottom: 20px;
        @include media($middle){
          padding: 15px 5px;
          margin-bottom: 15px;
        }
        &_txt{
          font-size: 3rem;
          font-weight: bold;
          margin: 0;
          @include media($middle){
            font-size: 2.2rem;
            text-align: center;
            margin-bottom: 15px;
          }
        }
        &_icons{
          font-size: 0;
          position: absolute;
          @include media($middle){
            position: static;
            text-align: center;
          }
          &--01{
            left: 275px;
            top: -25px;
          }
          &--02{
            left: 210px;
            top: -25px;
          }
          &_item{
            display: inline-block;
            font-size: 1rem;
            margin-right: 5px;
            &:last-child{
              margin-right: 0;
            }
            img{
              @include media($middle){
                height: 52px;
                width: auto;
              }
            }
          }
        }
      }
      &_catch{
        font-size: 4.8rem;
        font-weight: bold;
        line-height: 1.4;
        margin-bottom: 20px;
        @include media($middle){
          font-size: 2.2rem;
          margin-bottom: 15px;
          text-align: center;
        }
        &_small{
          font-size: 2.4rem;
          @include media($middle){
            font-size: 1.4rem;
          }
        }
      }
      &_tags{
        margin-bottom: 10px;
        @include media($middle){
          margin-bottom: 5px;
        }
        &_item{
          display: inline-block;
          background: #fff;
          border-radius: 10px;
          border: 3px solid #333333;
          padding: 10px 20px;
          font-size: 2.1rem;
          font-weight: bold;
          line-height: 1.4;
          margin-right: 10px;
          @include media($middle){
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 1.4rem;
            margin: 2px;
          }
          &:last-child{
            margin-right: 0;
          }
          &:before{
            content: "●";
            margin-right: 10px;
            @include media($middle){
              margin-right: 5px;
            }
          }
          span{
            font-size: 1.4rem;
            font-weight: 500;
            @include media($middle){
              font-size: 1.2rem;
            }
          }
        }
      }
      &_note{
        margin: 0;
        font-size: 1.4rem;
      }
      &_pl{
        padding-left: 30px;
        @include media($middle){
          padding-left: 0;
        }
      }
    }
    &_container{
      @include outer-container($content-width);
    }
    &_heading{
      position: relative;
      text-align: center;
      font-size: 3.4rem;
      font-weight: bold;
      margin-bottom: 45px;
      letter-spacing: 0.05em;
      @include media($middle){
        font-size: 1.8rem;
        line-height: 1.5;
        padding: 0 30px;
        margin-bottom: 30px;
      }
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #babeac;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      span{
        position: relative;
        display: inline-block;
        background: #efebe2;
        z-index: 1;
        padding: 0 15px;
      }
    }
    &_col-02{
      @include media($middle){
        margin-bottom: 45px;
      }
      &_item{
        display: flex;
        justify-content: space-between;
        padding: 50px;
        background: #fff;
        box-shadow: 7px 7px 0 #babeac;
        margin-bottom: 40px;
        @include media($middle){
          display: block;
          padding: 15px;
          margin: 0 15px;
          box-shadow: 4px 4px 0 #babeac;
          margin-bottom: 15px;
        }
        &--reverse{
          flex-direction: row-reverse;
        }
        &_thumb{
          width: 490px;
          @include media($middle){
            width: auto;
            text-align: center;
            margin-bottom: 20px;
          }
        }
        &_contents{
          width: 450px;
          @include media($middle){
            width: auto;
            text-align: center;
          }
        }
        &_head{
          display: flex;
          @include media($middle){
            display: block;
            text-align: center;
          }
          .specialbuilding_num{
            width: 90px;
            @include media($middle){
              margin: 0 auto 10px;
            }
          }
          &_contents{
            padding-left: 15px;
            @include media($large){
              width: 80%;
            }
            @include media($middle){
              padding: 0;
            }
            &_catch{
              background: #969b7d;
              color: #fff;
              font-weight: bold;
              font-size: 2.2rem;
              text-align: center;
              border-radius: 5px;
              margin-bottom: 10px;
              line-height: 2;
              @include media($middle){
                font-size: 1.6rem;
                padding: 2px;
                line-height: 1.8;
              }
            }
            &_heading{
              font-size: 3.4rem;
              font-weight: bold;
              @include media($middle){
                font-size: 2.4rem;
              }
              span{
                display: inline-block;
                background: #333333;
                color: #fff;
                font-size: 1.6rem;
                vertical-align: middle;
                padding: 5px 20px;
                border-radius: 15px;
                position: relative;
                bottom: 2px;
                margin-left: 15px;
                @include media($middle){
                  font-size: 1.2rem;
                  padding: 5px 10px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
        &_txt{
          font-size: 1.4rem;
          margin-bottom: 20px;
        }
        &_info{
          border: 1px solid #c0c0c0;
          display: flex;
          font-size: 1.4rem;
          letter-spacing: 0;
          line-height: 1.8;
          margin-bottom: 15px;
          @include media($middle){
            font-size: 1.2rem;
            display: block;
          }
          &_left{
            border-right: 1px solid #c0c0c0;
            width: 170px;
            padding: 15px;
            @include media($middle){
              width: auto;
              padding: 10px;
              border-right: none;
              border-bottom: 1px solid #c0c0c0;
            }
          }
          &_right{
            width: 285px;
            padding: 15px;
            @include media($middle){
              width: auto;
              padding: 10px;
            }
          }
          &_item{
            dt{
              display: inline-block;
              vertical-align: middle;
              span{
                font-weight: 500;
              }
            }
            dd{
              display: inline-block;
              vertical-align: middle;
            }
            &_small{
              font-size: 1.2rem;
              @include media($middle){
                font-size: 1rem;
              }
            }
          }
        }
        &_note{
          font-size:1.2rem;
          line-height: 1.5;
          padding-left:2.8em;
          text-indent:-2.8em;
        }
      }
    }
  }

  &_sec01{
    padding-bottom: 90px;
    @include media($middle){
      padding-bottom: 45px;
    }
    .heading-ptn-02{
      img{
        @include media($middle){
          width: 24px;
          margin: 0 10px;
        }
      }
    }
    &_head-thumb{
      text-align: center;
      margin-bottom: 90px;
      @include media($middle){
        margin-bottom: 20px;
        padding: 0 15px;
      }
    }
    &_col-03{
      @include clearfix;
      @include outer-container(1110px);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 75px;
      @include media($middle){
        padding: 0 15px;
        margin-bottom: 45px;
      }
      &_item{
        position: relative;
        width: 340px;
        background: #fff;
        margin: 0 15px 30px;
        padding: 30px 0 0 0;
        @include media($middle){
          float: none;
          width: 100%;
          margin: 0 0 10px 0;
          padding-top: 0;
        }
        &_head{
          @include media($middle){
            display: flex; /* 子要素をflexboxで揃える */
            justify-content: space-around;; /* 子要素をflexboxにより中央に配置する */
            align-items: center;  /* 子要素をflexboxにより中央に配置する */
            padding: 15px 10px 10px 10px;
          }
        }
        &--arrow{
          &:after{
            position: absolute;
            left: 0;
            right: 0;
            bottom: -70px;
            margin: auto;
            content: "";
            display: inline-block;
            width: 10px;
            height: 58px;
            background: url(./assets/img/performance_self_col-03_item--arrow.png) no-repeat center center;
            background-size: cover;
            @include media($middle){
              display: none;
            }
          }
        }
        &_thumb{
          margin-bottom: 20px;
          @include media($middle){
            width: 130px;
            text-align: right;
            margin-bottom: 0;
          }
        }
        &_num{
          display: block;
          text-align: center;
          font-family: $font-helvetica;
          color: #babeac;
          font-size: 2.4rem;
          line-height: 1;
          letter-spacing: 0.05em;
          font-weight: 500;
          @include media($middle){
            font-size: 1.8rem;
          }
          span{
            display: block;
            font-size: 7rem;
            @include media($middle){
              font-size: 3.6rem;
            }
          }
        }
        &_heading{
          text-align: center;
          font-size: 2.5rem;
          font-weight: 500;
          margin: 0;
          padding: 15px 0 35px;
          @include media($middle){
            font-size: 1.6rem;
            padding: 10px 15px 15px;
            width: 150px;
          }
          &--line-02{
            padding: 5px 0 10px;
            @include media($middle){
              padding: 10px 0 15px;
            }
          }
        }
        &_txt{
          color: #000;
          margin: 0;
          font-size: 1.4rem;
          padding: 20px 30px 40px;
          @include media($middle){
            font-size: 1.2rem;
            padding: 0 15px 25px;
          }
        }
        &_sp-btn{
          font-size: 1.3rem;
          text-align: center;
          padding-bottom: 30px;
          a{
            display: inline-block;
            background: #4e4743;
            color: #fff;
            padding: 10px 30px;
            &:after{
              font-family: FontAwesome;
              content: "\f107";
              font-size: 1.4rem;
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }
  }

  &_num{
    text-align: center;
    font-family: $font-helvetica;
    color: #babeac;
    font-size: 2.4rem;
    line-height: 1;
    margin: 0;
    font-weight: 500;
    letter-spacing: 0;
    @include media($middle){
      font-size: 1.4rem;
    }
    span{
      display: block;
      font-size: 7rem;
      letter-spacing: 0.1em;
      position: relative;
      left: 5px;
      @include media($middle){
        font-size: 3.6rem;
        letter-spacing: 0;
        position: static;
      }
    }
  }

  &_decos{
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 90px 50px;
    box-shadow: 7px 7px 0 #babeac;
    margin-bottom: 45px;
    @include media($large){
      flex-direction: row-reverse;
    }
    @include media($middle){
      display: block;
      padding: 20px 15px 30px;
      box-shadow: 4px 4px 0 #babeac;
      margin-bottom: 30px;
    }
    &_thumb{
      width: 390px;
      text-align: center;
      @include media($middle){
        width: auto;
        margin-bottom: 15px;
      }
    }
    &_contents{
      width: 545px;
      @include media($middle){
        width: auto;
      }
    }
    &_txt{
      font-size: 1.4rem;
      text-align: center;
      margin-bottom: 30px;
      @include media($middle){
        margin-bottom: 20px;
      }
    }
    &_figure{
      text-align: center;
      margin-bottom: 45px;
      @include media($middle){
        margin-bottom: 30px;
      }
    }
  }

  &_btn{
    @include outer-container(980px);
    background: #969b7d;
    text-align: center;
    display: block;
    color: #fff;
    border: 4px solid #babeac;
    padding: 12px 10px;
    @include media($middle){
      margin: 0 15px;
    }
    &_large{
      font-size: 3rem;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      @include media($middle){
        font-size: 1.6rem;
      }
    }
    &_small{
      font-size: 2rem;
      display: inline-block;
      vertical-align: middle;
      @include media($middle){
        font-size: 1.2rem;
      }
    }
    &:after{
      content: "";
      display: inline-block;
      width: 31px;
      height: 12px;
      background: url("./assets/img/btn_right.png") no-repeat center;
      background-size: cover;
      transition: 0.3s;
      z-index: 1;
      vertical-align: middle;
      margin-left: 15px;
      position: relative;
      bottom: 2px;
      @include media($middle){
        margin-left: 10px;
      }
    }
  }

  &_sec02{
    &_figure{
      text-align: center;
      margin-bottom: 40px;
      @include media($middle){
        padding: 0 15px;
        margin-bottom: 20px;
        img{
          width: 290px;
        }
      }
    }
    &_txt{
      font-size: 1.8rem;
      text-align: center;
      margin-bottom: 50px;
      @include media($middle){
        padding: 0 15px;
        font-size: 1.4rem;
        margin-bottom: 20px;
      }
    }
    &_col-02{
      position: relative;
      padding-left: 90px;
      margin-bottom: 90px;
      @include media($middle){
        padding: 0 15px;
        margin-bottom: 30px;
      }
    }
    &_abs{
      font-family: $font-helvetica;
      font-weight: 500;
      transform-origin: top left;
      transform: rotate(-90deg) translateX(-100%);
      font-size: 4rem;
      letter-spacing: 0.2em;
      color: #969b7d;
      margin: 0;
      line-height: 1;
      position: absolute;
      left: 0;
      top: 130px;
      @include media($middle){
        position: static;
        transform: rotate(0deg) translateX(0%);
        font-size: 2.4rem;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 15px;
      }
    }
    &_flow{
      width: 940px;
      @include media($middle){
        width: auto;
      }
      &_item{
        display: flex;
        align-items: center;
        background: #fff;
        margin-bottom: 40px;
        @include media($middle){
          display: block;
          position: relative;
        }
        &:last-child{
          margin-bottom: 0;
          .specialbuilding_sec02_flow_item_left{
            &:after{
              display: none;
            }
          }
        }
        &_left{
          position: relative;
          background: #969b7d;
          width: 300px;
          padding: 30px 0;
          text-align: center;
          font-size: 2.6rem;
          font-weight: bold;
          color: #fff;
          margin: 0;
          letter-spacing: 0;
          @include media($middle){
            position: static;
            width: auto;
            font-size: 2rem;
            padding: 15px 10px;
          }
          &:after{
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 13.5px 0 13.5px;
            border-color: #000000 transparent transparent transparent;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -30px;
            margin: auto;
          }
        }
        &_right{
          width: 640px;
          padding: 15px 60px;
          font-size: 1.4rem;
          margin: 0;
          line-height: 1.9;
          @include media($middle){
            width: auto;
            padding: 20px 15px;
          }
        }
      }
    }
  }

}
