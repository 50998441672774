.concept{
  padding-bottom: 0;

  &_head{
    margin-bottom: 60px;
    @include media($middle){
      margin-bottom: 45px;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_txt{
      margin-bottom: 0;
    }
    p{
      @include media($middle){
        font-size: 1.3rem;
      };
    }
  }

  &_main{
    background-color: #babeac;
    padding-top: 115px;
    padding-bottom: 100px;
    @include media($middle){
      margin-bottom: 0px;
      padding: 40px 10px;
    }
    .heading-ptn-02{
      @include outer-container($content-width);
      margin-bottom: 120px;
      position: relative;
      left: 25px;
      @include media($middle){
        text-align: center;
        margin-bottom: 30px;
        left: 0;
      }
      img{
        @include media($middle){
          width: 25px;
        }
      }
      /*
      img{
        margin-top: -20px;
        margin-left: 10px;
        margin-right: 10px;
        @include media($middle){
          width: 25px;
          margin-top: -10px;
          margin-left: 5px;
          margin-right: 5px;
        };
      }
      */
    }
  }

  .concept_main_sec{
    margin-bottom: 110px;
    position: relative;
    @include media($middle){
      margin-bottom: 15px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &_box{
      position: relative;
      background-color: #ffffff;
      @include outer-container($content-width);
      padding: 95px 100px;
      box-shadow: 8px 8px 0px 0px #aeb1a1;
      @include media($middle){
        margin: 0 0 15px;
        padding: 15px;
        box-shadow: 4px 4px 0px 0px #aeb1a1;
      };
    }
    &_name{
      display: flex;
      line-height: 1;
      align-items: flex-end;
      margin-bottom: 35px;
      @include media($middle){
        margin-bottom: 10px;
        margin-left: 7px;
      };
      &-right{
        justify-content: flex-end;
        margin-right: 130px;
        @include media($middle){
          justify-content: flex-start;
          margin-bottom: 10px;
          margin-right: 0;
          margin-left: 7px;
        };
      }
      &_num{
        font-size: 7rem;
        font-family: $font-helvetica;
        letter-spacing: 0.05em;
        margin-right: 10px;
        position: relative;
        z-index: 1;
        font-weight: 500;
        @include media($middle){
          font-size: 5rem;
          margin-right: 5px;
        };
        &::before{
          content:"";
          display: inline-block;
          width: 36px;
          height: 36px;
          background-color: #f1ece2;
          position: absolute;
          z-index: -1;
          bottom: -5px;
          left: -10px;
          @include media($middle){
            width: 25px;
            height: 25px;
            bottom: 0px;
            left: -5px;
          };
        }
      }
      &_point{
        display: inline-block;
        font-size: 4rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        border: 5px solid #babeac;
        line-height: 1;
        padding: 4px 12px 6px;
        @include media($middle){
          font-size: 2.5rem;
          border: 3px solid #babeac;
        };
      }
    }
    &_txt{
      font-size: 1.4rem;
      letter-spacing: 0.05em;
      line-height: 2.2;
      width: 410px;
      @include media($middle){
        width: 100%;
        font-size: 1.2rem;
        line-height: 1.8;
      };
      &-right{
        margin-left: auto;
      }

    }
    &_img{
      position: absolute;
      top: -50px;
      right: -100px;
      width: 600px;
      @include media($middle){
        position: static;
        width: 100%;
        margin-bottom: 10px;
      };
      &-right{
        position: absolute;
        top: -50px;
        left: -100px;
        width: 600px;
      }
    }
  }


  &_more{
    background: #f1ece2;
    padding: 100px 0 90px;
    @include media($middle){
      padding: 45px 0px;
    }
    &_container{
      @include outer-container(980px);
      @include media($middle){
        margin: 0 5%;
      };
    }
    .heading-ptn-02{
      position: relative;
      left: 23px;
      @include media($middle){
        left: 0;
        text-align: center;
      }
    }
    &_col-03{
      @include clearfix;
      margin-bottom: 50px;
      @include media($middle){
        margin-bottom: 30px;
      }
      &_item{
        background: #ffffff;
        @include span-columns(4);
        @include omega(3n);
        @include media($middle){
        }
        &_contents{
          padding: 20px 20px;
          @include media($middle){
            padding: 15px 10px;
            min-height: 110px;
          }
        }
        &_heading{
          font-size: 2.2rem;
          font-weight: 500;
          margin-left: 23px;
          margin-bottom: 10px;
          position: relative;
          @include media($middle){
            font-size: 1.2rem;
            margin-left: 0px;
            margin-bottom: 5px;
          }
          &:after{
            content: "";
            display: block;
            width: 11px;
            height: 18px;
            background: url('./assets/img/concept_more_right.png') no-repeat center;
            background-size: cover;
            position: absolute;
            top: 5px;
            left: -23px;
            @include media($middle){
              display: none;
            }
          }
        }
        &_txt{
          margin: 0;
          font-size: 1.4rem;
          line-height: 1.7;
          @include media($middle){
            font-size: 1rem;
          }
        }
      }
    }
    &_list{
      display: flex;
      padding-left: 140px;
      @include media($middle){
        flex-direction: column;
        align-items: center;
        padding-left: 0;
      };
      &_item{
        margin-bottom: 10px;
        &:nth-of-type(2){
          margin-left: 100px;
          @include media($middle){
            margin-left: 0;
          };
        }
        .btn-ptn-01{
          @include media($middle){
          font-size: 1.2rem;
          };
        }
      }
    }
  }


  &_area{
    padding: 90px 0;
    border-bottom: 1px solid #333333;
    @include media($middle){
      padding: 45px 15px;
    }
    &_container{
      @include outer-container(980px);
    }

    .heading-ptn-02{
      position: relative;
      left: 25px;
      @include media($middle){
        left: 0;
        text-align: center;
      }
    }
    &_header{
      font-size: 2.6rem;
      letter-spacing: 0.05em;
      margin-bottom: 20px;
      font-weight: bold;
      @include media($middle){
        font-size: 1.6rem;
        margin-bottom: 10px;
      };
    }

    &_txt{
      font-size: 1.4rem;
      margin-bottom: 40px;
      @include media($middle){
        margin-bottom: 30px;
        font-size: 1.3rem;
      }
    }

    &_col-03{
      display: flex;
      justify-content: space-between;
      @include media($middle){
        display: block;
      }
      &_item{
        width: 300px;
        @include media($middle){
          width: auto;
          margin-bottom: 20px;
        }
        &_thumb{
          text-align: center;
          margin-bottom: 25px;
          @include media($middle){
            margin-bottom: 15px;
          }
        }
        &_heading{
          font-size: 2.2rem;
          font-weight: bold;
          margin-bottom: 20px;
          margin-left: 20px;
          position: relative;
          &::before{
            content:"";
            display: inline-block;
            width: 11px;
            height: 11px;
            background-color: #babeac;
            border-radius: 50px;
            position: absolute;
            top: 8px;
            left: -20px;
            @include media($middle){
              top: 6px;
            };
          }
          @include media($middle){
            font-size: 1.8rem;
            margin-bottom: 10px;
          }
        }
        &_txt{
          margin: 0;
          font-size: 1.4rem;
          line-height: 1.7;
          @include media($middle){
            font-size: 1.3rem;
          };
        }
      }
    }

  }

  &_bottom-bnr{
    padding-bottom: 90px;
    @include media($middle){
      padding-bottom: 45px;
    }
    &_container{
      @include outer-container(780px);
      @include media($middle){
        margin: 0 5%;
      };
    }
    &_box{
      display: flex;
      @include media($middle){
        display: block;
      };
      &_right{
        background-color: #babdab;
        border-top: 3px solid #333333;
        width: 50%;
        padding: 50px 38px;
        @include media($middle){
          display: flex;
          align-items: flex-end;
          width: 100%;
          padding: 15px;
          border: 0px solid #333333;
        };
        &_name{
          font-size: 6.5rem;
          font-family: $font-helvetica;
          letter-spacing: 0.05em;
          line-height: 0.5;
          margin-bottom: 20px;
          font-weight: 500;
          @include media($middle){
            font-size: 3.5rem;
            line-height: 0.6;
            margin-top: 5px;
            margin-bottom: 0px;
            margin-right: 20px;
          };
          span{
            font-size: 2rem;
            font-weight: bold;
            line-height: 1;
            letter-spacing: 0em;
            @include media($middle){
              font-size: 1.4rem;
            };
          }
        }
        .btn-ptn-01--long{
          @include media($middle){
            width: 120px;
            font-size: 1.2rem;
          };
        }
      }
    }
  }

}
