.contact{



  &_taiken{

    &_head{
      @include outer-container($content-width);
      margin-bottom: 50px;
      @include media($middle){
        padding: 0 5%;
        margin-bottom: 20px;
      };
    }
    &_col-02{
      display: flex;
      @include media($middle){
        flex-direction: column;
      };
      &_thumb{
        width: 45%;
        @include media($middle){
          width: 100%;
        };
      }
      &_contents{
        width: 55%;
        background: #caccbe;
        padding-left: 40px;
        display: flex;
        align-items: center;
        @include media($middle){
          width: 100%;
          padding: 5%;
        };
        &_container{
          padding-right: 40px;
          @include media($middle){
            padding: 0;
          }

          .btn-ptn-01{
            text-align: center;
          }
        }
        &_heading{
          font-weight: 500;
          font-size: 3rem;
          line-height: 2;
          padding-bottom: 20px;
          margin-bottom: 30px;
          @include media($middle){
            font-size: 2rem;
            padding-bottom: 0px;
            margin-bottom: 20px;
          }
          &_small{
            display: block;
            font-size: 2rem;
            margin-bottom: 10px;
            @include media($middle){
              font-size: 1.4rem;
            }
          }
          &_large{
            margin-left: 45px;
            span::before{
              top: -5px;
              left: -48px;
            }
            span::after{
              bottom: -7px;
              right: -57px;
            }
          }
          &_icon{
            color: #ffffff;
            background: #ec6941;
            display: inline-block;
            font-size: 1.8rem;
            line-height: 1;
            margin-bottom: 25px;
            padding: 10px 15px;
            font-weight: bold;
            letter-spacing: 0.05em;
            @include media($middle){
              font-size: 1.2rem;
              padding: 5px 10px;
              margin-bottom: 15px;
            }
          }
        }
        &_txt{
          font-size: 1.4rem;
          margin-bottom: 10px;
        }
        &_note{
          font-size: 1.4rem;
          display: inline-block;
          background: #d9dbd0;
          padding: 5px 15px;
          margin-bottom: 25px;
          @include media($middle){
            font-size: 1.2rem;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  &_tel{
    background-color: #f1ece2;
    padding-top: 100px;
    @include media($middle){
      padding-top: 45px;

    }
    &_container{
      padding-bottom: 100px;
      @include outer-container($content-width);
      border-bottom: 1px solid #333333;
      @include media($middle){
        margin: 0 5%;
          padding-bottom: 45px;
      }
    }
    &_header{
      font-size: 6.6rem;
      text-align: center;
      line-height: 0.6;
      margin-bottom: 47px;
      font-family: $font-helvetica;
      letter-spacing: 0.1em;
      font-weight: 500;
      @include media($middle){
          line-height: 0.8;
        font-size: 4rem;
        margin-bottom: 30px;
      };
      &-ja{
        font-size: 1.8rem;
        letter-spacing: 0.05em;
        @include media($middle){
          font-size: 1.4rem;
        };
      }
    }
    &_box{
      display: block;
      @include outer-container(800px);
      background-color: #ffffff;
      border: 5px solid #333333;
      padding: 30px;
      text-align: center;
      @include media($middle){
        padding: 20px;
      }
      &_heading{
        line-height: 1.2;
        display: inline-block;
        padding-bottom: 15px;
        margin-bottom: 25px;
        border-bottom: 3px solid #333333;
        @include media($middle){
          font-size: 1.2rem;
          line-height: 1.4;
          padding-bottom: 10px;
          margin-bottom: 15px;
        }
      }
      &_small{
        margin-bottom: 0;
        line-height: 1.2;
        @include media($middle){
          font-size: 1.2rem;
        };
      }
      &_btn{
        a{
          display: block;
        }
      }
    }
  }

  &_form{
    background-color: #f1ece2;
    padding: 100px 0;
    @include media($middle){
      padding: 70px 0 55px;
    };
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_txt{
      @include outer-container(800px);
      font-size: 1.4rem;
      margin-bottom: 40px;
      @include media($middle){
        font-size: 1.2rem;
        margin-bottom: 20px;
      }
    }
  }

}
