.recruit{
  padding-bottom: 0;

  &_container{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
  }

  &_head{
    margin-bottom: 60px;
    @include media($middle){
      margin-bottom: 40px;
    };
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_col-02{
      @include outer-container(870px);
      margin-bottom: 90px;
      @include media($middle){
        margin: 0 5%;
        margin-bottom: 50px;
      };
      &_item{
        margin: 0 auto;
        @include omega(2n);
        background: #fff;
        @include media($middle){
          width: auto;
          margin: 0 0 10px 0;
        }
        &_heading{
          color: #ffffff;
          background: #969b7d;
          text-align: center;
          font-size: 2rem;
          letter-spacing: 0.05em;
          padding: 20px 10px;
          margin: 0;
          @include media($middle){
            font-size: 1.6rem;
            padding: 10px 0;
          }
        }
        &_contents{
          color: #000000;
          padding: 25px 40px 30px;
          @include media($middle){
            padding: 20px 15px;
          }
        }
        &_txt{
          text-align: center;
          margin-bottom: 20px;
          @include media($middle){
            margin-bottom: 15px;
          }
        }
        &_list{
          display: flex;
          justify-content: space-around;
          font-size: 2rem;
          font-family: $font-noto;
          line-height: 1.7;
          @include media($middle){
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-size: 1.6rem;
          }
          a{
            color: #000000;
          }
          &_item{
            &:before{
              font-family: FontAwesome;
              content: "\f107";
              font-size: 2.4rem;
              margin: 0 10px 0 0;
              color: #969b7d;
              @include media($middle){
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }

  &_requirements{
    background-color: #efebe2;
    padding-top: 50px;
    &--01{
      padding-bottom: 100px;
      @include media($middle){
        padding-bottom: 40px;
      }
    }
    &--02{
      background: #58514e;
      padding: 90px 0 100px;
      @include media($middle){
        padding: 45px 15px;
      }
    }
    &--03{
      padding: 60px 0 100px;
      @include media($middle){
        padding: 0px 0px 45px;
      }
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };

      .heading-ptn-02{
        span{
          &::before{
            left: -73px;
          }
          &::after{
            right: -82px;
          }
        }
      }
    }
    &_data{
      @include outer-container($content-width);
      &_heading{
        background-color: #333333;
        color: #ffffff;
        text-align: center;
        font-size: 2.4rem;
        font-weight: 400;
        padding: 30px 0;
        margin-bottom: 0;
        @include media($middle){
          font-size: 1.6rem;
          padding: 20px 0;
        }
      }
      &_table{
        margin-top: 0;
        width: 100%;
        background: #fff;
        font-size: 1.4rem;
        padding: 60px 50px;
        display: block;
        @include media($middle){
          width: 100%;
          display: block;
          font-size: 1.4rem;
          padding: 30px 15px;
        }
        tr{
          @include media($middle){
            width: 100%;
            display: block;
            margin-bottom: 10px;
          }
          th{
            width: 230px;
            padding: 12px 10px 12px 30px;
            @include media($middle){
              width: 100%;
              display: block;
              padding: 3px;
            }
          }
          td{
            width: 780px;
            padding: 12px 0px 10px 30px;
            line-height: 1.8;
            @include media($middle){
              width: 100%;
              display: block;
              padding: 5px;
              font-size: 1.2rem;
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }

  &_contact{
    background: #ffffff;
    padding: 60px 0 100px;
    @include media($middle){
      padding: 45px 15px;
    }
    &_container{
      @include outer-container(980px);
    }
    .heading-ptn-02{
      font-size: 3rem;
      @include media($middle){
        font-size: 1.6rem;
      };
    }
    &_heading{
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 40px;
      @include media($middle){
        font-size: 1.8rem;
        margin-bottom: 20px;
      }
    }
    &_box{
      background-color: #efebe2;
      padding: 25px 0 40px;
      border: 1px solid #fff;
      text-align: center;
      @include media($middle){
        padding: 20px 10px;
      }
      &_txt{
        font-family: $font-noto;
        margin-bottom: 20px;
        line-height: 1.9;
        @include media($middle){
          margin-bottom: 10px;
          line-height: 1.7;
        }
        span{
          font-weight: bold;
        }
      }
      &_btns{
        &_item{
          display: inline-block;
          position: relative;
          margin: 0 5px;
          @include media($middle){
            margin: 5px;
            img{
              width: 250px;
            }
          }
          &::after{
            content: "";
            display: inline-block;
            width: 31px;
            height: 12px;
            background: url('./assets/img/btn_right.png') no-repeat center;
            background-size: cover;
            position: absolute;
            top: 28px;
            right: 40px;
            transition: 0.3s;
          }
          &:hover{
            &::after{
              transform: translateX(10px);
            }
          }
        }
      }
    }
  }

}
