.archive-staff{

  &_container{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
  }

  /*
  &_catch{
    font-size: 3rem;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 90px;
    @include media($middle){
      font-size: 1.8rem;
      margin: 0 0 30px;
      text-align: center;
      line-height: 1.3;
    }
    span{
      position: relative;
      padding: 0 60px;
      display: inline-block;
      @include media($middle){
        padding: 0 20px;
      }
      &:before{
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url(./assets/img/archive-staff_catch_before.png) no-repeat center center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: -20px;
        @include media($middle){
          width: 14px;
          height: 14px;
          top: -15px;
        }
      }
      &:after{
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url(./assets/img/archive-staff_catch_after.png) no-repeat center center;
        background-size: cover;
        position: absolute;
        right: 0;
        bottom: -20px;
        @include media($middle){
          width: 14px;
          height: 14px;
          bottom: -15px;
        }
      }
    }
  }
  */

}

.staff-list{
  @include clearfix;
  margin-top: -100px;
  @include media($middle){
    margin-top: -60px;
  }
  &_item{
    @include media($large){
      width: 200px;
      float: left;
      margin-right: 20px;
      @include omega(5n);
      margin-bottom: 50px;
    }
    @include media($middle){
      @include span-columns(6);
      @include omega(2n);
      margin-bottom: 2.5%;
    }
    &_thumb{
      text-align: center;
    }
    &_contents{
      position: relative;
      z-index: 1;
      background: #fff;
      text-align: center;
      padding: 20px 25px;
      transition: all 150ms ease-in-out;
      @include media($middle){
        padding: 20px 15px 20px;
      }
    }
    &_position{
      transition: all 150ms ease-in-out;
      line-height: 1.3;
      font-size: 1.2rem;
      padding: 4px;
      background: #ae976a;
      color: #fff;
      margin-bottom: 15px;
      @include media($middle){
        font-size: 1.0rem;
      }
    }
    &_position-02{
      transition: all 150ms ease-in-out;
      line-height: 1.3;
      font-size: 1.1rem;
      margin-bottom: 0;
      min-height: 30px;
      @include media($middle){
        font-size: 1.1rem;
      }
    }
    &_name{
      margin-bottom: 10px;
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.3;
      @include media($middle){
        font-size: 1.6rem;
      }
      &_en{
        display: block;
        font-weight: 400;
        font-size: 1.2rem;
        margin-top: 12px;
        color: #b0b0b0;
        @include media($middle){
          font-size: 1rem;
          margin-top: 10px;
        }
      }
    }
    a{
      display: block;
    }
  }
}

.staff-single{
  &_container{
    @include outer-container(980px);
    @include media($middle){
      padding: 0 15px;
      margin-bottom: 30px;
    }
  }
  &_head{
    @include outer-container(800px);
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -100px;
    margin-bottom: 45px;
    @include media($middle){
      display: block;
      margin-top: -60px;
      margin-bottom: 15px;
    }
    &_thumb{
      width: 360px;
      box-shadow: 10px 10px 0 0 rgba(0,0,0,0.1);
      @include media($middle){
        width: 240px;
        position: static;
        text-align: center;
        margin: 0 auto;
        box-shadow: 5px 5px 0 0 rgba(0,0,0,0.1);
      }
    }
    &_data{
      width: 520px;
      float: right;
      padding-top: 30px;
      text-align: center;
      @include media($middle){
        width: auto;
        float: none;
        padding: 30px 0;
      }
      &_position-01{
        font-size: 1.4rem;
        display: inline-block;
        padding: 0 15px;
        margin-bottom: 10px;
        background: #ae976a;
        color: #fff;
        @include media($middle){
          font-size: 1.2rem;
        }
      }
      &_position-02{
        margin-bottom: 10px;
        line-height: 1.6;
        @include media($middle){
          font-size: 1.2rem;
        }
      }
      &_name{
        font-size: 4.8rem;
        font-weight: 500;
        margin: 0;
        @include media($middle){
          font-size: 2.8rem;
        }
        &_en{
          font-size: 1.2rem;
          font-weight: 300;
          display: block;
          margin-top: 15px;
          margin-bottom: 0;
          color: #b0b0b0;
          @include media($middle){
            font-size: 1.1rem;
            margin-top: 10px;
          }
        }
      }
    }
  }
  &_contents{
    background: #fff;
    padding: 75px 90px 30px;
    font-size: 1.6rem;
    color: #000000;
    margin-bottom: 45px;
    @include media($middle){
      padding: 20px 15px;
      font-size: 1.4rem;
    }
    &_message{
      margin-bottom: 45px;
      @include media($middle){
        margin-bottom: 30px;
      }
    }
    &_bottom{
      padding: 40px;
      border: 1px solid #3f3936;
      margin-bottom: 60px;
      @include media($middle){
        font-size: 1.4rem;
        padding: 20px 15px;
      }
      &_dl{
        margin-bottom: 30px;
        @include media($middle){
          margin-bottom: 15px;
        }
        &:last-child{
          margin-bottom: 0;
        }
        dt{
          background: #ae976a;
          font-weight: bold;
          color: #fff;
          padding: 5px 30px;
          margin-bottom: 15px;
          @include media($middle){
            padding: 5px 15px;
            margin-bottom: 10px;
          }
        }
        dd{
          padding: 0 30px;
          @include media($middle){
            padding: 0 15px;
          }
        }
      }
    }
  }
  &_faq{
    @include outer-container(880px);
    @include clearfix;
    font-size: 1.6rem;
    margin-bottom: 60px;
    @include media($middle){
      font-size: 1.4rem;
      margin-bottom: 30px;
      padding: 0 15px;
    }
    &_item{
      width: 390px;
      float: left;
      text-align: left;
      @include omega(2n);
      margin-right: 20px;
      margin-bottom: 40px;
      @include media($middle){
        width: 100%;
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
      }
      dt{
        background: #ae976a;
        font-weight: bold;
        color: #fff;
        padding: 5px;
        margin-bottom: 15px;
        text-align: center;
        @include media($middle){
          margin-bottom: 10px;
        }
      }
      dd{
        text-align: center;
      }
    }
  }

  &_works{
    margin-bottom: 30px;

    &_name{
      background: #ae976a;
      font-weight: bold;
      color: #fff;
      padding: 5px;
      margin-bottom: 15px;
      text-align: center;
    }

    .top_works_slider_item{
      @include media($middle){
        width: 100%;
        margin-bottom: 10px;
      };
      &:last-of-type{
        margin-right: 0;
      }
    }

      .slick-arrow:before{
      content:""!important;
      width: 100%!important;
      height: 100%!important;
      position: absolute;
      top: 0;
      left: 0;
    }

    .slick-next:before{
      background: url('./assets/img/top_works_next.png') no-repeat center !important;
      background-size: contain!important;
    }

    .slick-prev:before{
      background: url('./assets/img/top_works_prev.png') no-repeat center !important;
      background-size: contain!important;
    }

    .slick-arrow{
      z-index:2!important;
      width:60px!important;
      height:60px!important;
    }

    .slick-next{
      right:-70px!important;

    }

    .slick-prev{
      left:-70px!important;
    }
  }
  &_blog{
    .top_blog_archive_item{
      margin: 0 11px;
      @include media($middle){
        width: 100%;
        margin: 0;
      };

      a{
        display: inline-block;
      }
    }

    .slick-arrow:before{
    content:""!important;
    width: 100%!important;
    height: 100%!important;
    position: absolute;
    top: 0;
    left: 0;
  }

  .slick-next:before{
    background: url('./assets/img/top_works_next.png') no-repeat center !important;
    background-size: contain!important;
  }

  .slick-prev:before{
    background: url('./assets/img/top_works_prev.png') no-repeat center !important;
    background-size: contain!important;
  }

  .slick-arrow{
    z-index:2!important;
    width:60px!important;
    height:60px!important;
  }

  .slick-next{
    right:-70px!important;

  }

  .slick-prev{
    left:-70px!important;
  }
  }
}
