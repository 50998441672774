.after{
  padding-bottom: 0;

  &_head{
    margin-bottom: 60px;
    @include media($middle){
      margin-bottom: 45px;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_txt{
      margin-bottom: 50px;
      @include media($middle){
        margin-bottom: 30px;
      }
    }
  }

  .heading-ptn-02{
    text-align: center;
    font-size: 4rem;
    margin-bottom: 45px;
    @include media($middle){
      font-size: 1.6rem;
      margin-bottom: 30px;
    }
  }

  &_self{
    background: #efebe2;
    padding: 100px 0;
    @include media($middle){
      padding: 60px 0;
    }
    &_container{
      @include outer-container($content-width);
    }
    .heading-ptn-02{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_col-03{
      @include clearfix;
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
      &_item{
        position: relative;
        width: 340px;
        float: left;
        margin-right: 30px;
        background: #fff;
        padding-top: 35px;
        @include media($middle){
          float: none;
          width: 100%;
          margin: 0 0 20px 0;
        }
        &--arrow{
          &:after{
            position: absolute;
            left: 0;
            right: 0;
            bottom: -70px;
            margin: auto;
            content: "";
            display: inline-block;
            width: 10px;
            height: 58px;
            background: url(./assets/img/performance_self_col-03_item--arrow.png) no-repeat center center;
            background-size: cover;
            @include media($middle){
              display: none;
            }
          }
        }
        &:last-child{
          margin-right: 0;
          @include media($middle){
            margin-bottom: 0;
          }
        }
        &_num{
          font-size: 7rem;
          font-family: $font-helvetica;
          text-align: center;
          line-height: 0.8;
          margin-bottom: 0px;
          letter-spacing: 0.1em;
          color: #babeac;
          font-weight: 500;
          @include media($middle){
            font-size: 3.6rem;
            letter-spacing: 0;
          };
          img{
            width: auto;
            height: 48px;
            @include media($middle){
              height: 32px;
            }
          }
        }
        &_head{
          text-align: center;
          @include media($middle){
            display: flex; /* 子要素をflexboxで揃える */
            justify-content: space-between; /* 子要素をflexboxにより中央に配置する */
            align-items: center;  /* 子要素をflexboxにより中央に配置する */
            padding: 15px 10px 10px 10px;
          }
          &_heading{
            text-align: center;
            font-weight: 500;
            margin: 0;
            font-size: 2.5rem;
            padding: 20px 0;
            line-height: 1.4;
            @include media($middle){
              height: auto;
              padding: 0 5px;
              font-size: 1.5rem;
              img{
                height: 44px;
                width: auto;
              }
            }
            &--ruby{
              padding-top: 15px;
              @include media($middle){
                padding-top: 0;
                img{
                  height: 76px;
                  width: auto;
                }
              }
            }
          }
          &_thumb{
            @include media($middle){
              width: 130px;
              text-align: right;
            }
          }
        }
        &_contents{
          padding: 20px 30px 45px;
          @include media($middle){
            padding: 15px 15px 30px;
          }
        }
        &_schedule-heading{
          margin-bottom: 10px;
          text-align: center;
          color: #fff;
          background: #939a7b;
          font-size: 1.4rem;
          padding: 10px 0;
        }
        &_schedule-txt{
          color: #000;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1.7;
          @include media($middle){
            font-size: 1.4rem;
          }
        }
        &_tel{
          text-align: center;
          margin-top: 15px;
          @include media($middle){
            margin-top: 10px;
          }
        }
        &_txt{
          color: #000;
          margin: 0;
          font-size: 1.4rem;
          line-height: 2.4;
          margin-bottom: 10px;
          @include media($middle){
            font-size: 1.2rem;
            padding: 0;
          }
          &--center{
            text-align: center;
            margin-top: 30px;
            @include media($middle){
              margin-top: 15px;
            }
          }
          &--small{
            font-size: 1.2rem;
            line-height: 2;
          }
        }
        &_sp-btn{
          font-size: 1.3rem;
          text-align: center;
          padding-bottom: 30px;
          a{
            display: inline-block;
            background: #4e4743;
            color: #fff;
            padding: 10px 30px;
            &:after{
              font-family: FontAwesome;
              content: "\f107";
              font-size: 1.4rem;
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }
  }

  &_owner{
    @include media($middle){
      padding: 0;
    }
    &_image{
      margin-bottom: 60px;
      @include media($middle){
        margin-bottom: 45px;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    &_container{
      @include outer-container($content-width);
      position: relative;
      &:before{
        content: "";
        display: inline-block;
        width: 414px;
        height: 157px;
        background: url(./assets/img/after_ownewsclub_en.png) no-repeat center center;
        background-size: cover;
        position: absolute;
        left: -75px;
        top: -85px;
        @include media($middle){
          width: 207px;
          height: 78px;
          left: 10px;
          top: -80px;
        }
      }
    }
    &_catch{
      text-align: center;
      margin-bottom: 30px;
      font-size: 2rem;
      font-weight: bold;
      @include media($middle){
        margin-bottom: 20px;
        font-size: 1.4rem;
        img{
          width: 270px;
        }
      }
    }
    &_heading{
      text-align: center;
      margin-bottom: 40px;
      @include media($middle){
        margin-bottom: 30px;
        img{
          width: 290px;
        }
      }
    }
    &_col-02{
      @include clearfix;
      @include outer-container($content-width);
      margin-bottom: 30px;
      @include media($middle){
        margin-bottom: 0;
        padding: 0 15px;
      }
      &_thumb{
        width: 543px;
        float: right;
        @include media($middle){
          width: auto;
          float: none;
          text-align: center;
          margin-bottom: 20px;
          img{
            width: 290px;
          }
        }
      }
      &_txt{
        position: relative;
        color: #000;
        width: 515px;
        float: left;
        line-height: 1.9;
        padding: 30px 30px;
        margin: 0;
        @include media($middle){
          float: none;
          width: auto;
          position: static;
          padding: 0;
          line-height: 1.6;
        }
      }
    }
    &_gallery{
      position: relative;
      text-align: center;
      margin-bottom: 130px;
      background: url(./assets/img/after_event_bg.png) no-repeat center top 100px;
      background-size: contain;
      padding: 100px 0 0;
      @include media($middle){
        background: url(./assets/img/after_event_bg.png) no-repeat center top 30px;
        background-size: contain;
        padding: 30px 0 0;
        margin-bottom: 30px;
      }
      img{
        position: relative;
        z-index: 2;
      }
    }
    &_discount{
      text-align: center;
      @include media($middle){
        padding: 0 15px;
      }
    }
  }

  &_owner{
    margin-bottom: 30px;
    @include media($middle){
      margin-bottom: 15px;
    }
  }

}
