.orderhouse{
  padding-bottom: 0;

  &_concept-house{
    background: #efebe2;
    padding: 90px 0 330px;
    @include media($middle){
      margin-bottom: 45px;
      padding: 45px 15px 30px;
    }
    &_container{
      @include outer-container($content-width);
    }
    &_list{
      @include clearfix;
      &_item{
        width: 530px;
        float: left;
        margin: 0 20px 20px 0;
        @include omega(2n);
        @include media($middle){
          @include span-columns(12);
          margin-bottom: 2%;
        }
      }
    }
  }

  &_reference{
    margin-bottom: 130px;
    background: #fff;
    @include media($middle){
      margin-bottom: 0;
      padding: 0 15px;
    }
    &_container{
      @include outer-container($content-width);
      position: relative;
      top: -250px;
      margin-bottom: -300px;
      @include media($middle){
        top: 0;
        margin-bottom: 0;
      }
    }
    &_col-02{
      @include clearfix;
      display: flex;
      justify-content: space-between;
      @include media($middle){
        display: block;
      }
      .btn-ptn-01{
        @include media($large){
          position: relative;
          right: 10px;
        }
      }
      &_item{
        width: 538px;
        @include media($middle){
          width: auto;
          float: none;
          margin: 0 0 20px;
        }
        &_thumb{
          text-align: center;
          @include media($middle){
            margin-bottom: 15px;
          }
        }
        &_contents{
          position: relative;
          z-index: 2;
          text-align: center;
          padding: 25px 25px 15px 25px;
          line-height: 1.8;
          @include media($middle){
            width: auto;
            float: none;
            margin: -20px 20px 0 20px;
            padding: 20px 10px;
            height: auto !important;
          }
          &_txt{
            font-size: 1.4rem;
            margin-bottom: 20px;
            @include media($middle){
              margin-bottom: 15px;
              font-size: 1.2rem;
              br{
                display: none;
              }
            }
            &--line-01{
              padding-top: 15px;
              margin-bottom: 25px;
              @include media($middle){
                padding: 0;
                margin-bottom: 15px;
              }
            }
          }
          &_link{
            margin-bottom: 0;
            font-size: 1.8rem;
            @include media($middle){
              font-size: 1.6rem;
            }
            a{
              position: relative;
              display: inline-block;
              padding: 10px 30px;
              @include media($middle){
                padding: 5px 20px;
              }
              &:after{
                content: "";
                display: inline-block;
                background: url(./assets/img/icon_arrow_white_right.png) no-repeat center center;
                background-size: cover;
                width: 8px;
                height: 14px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }

  &_sec{
    padding: 80px 0 120px;
    background: #e1dbcd;
    @include media($middle){
      padding: 45px 0;
    }
    &--brown{
      background: #efebe2;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_head{
      text-align: center;
      margin-bottom: 30px;
      @include media($middle){
        margin-bottom: 20px;
      }
      &_catch{
        font-family: $font-noto;
        font-weight: 500;
        font-size: 2rem;
        margin-bottom: 25px;
        @include media($middle){
          margin-bottom: 15px;
          font-size: 1.4rem;
        }
      }
      &_heading{
        margin-bottom: 25px;
        @include media($middle){
          margin-bottom: 15px;
          img{
            padding: 0 15px;
          }
        }
      }
      &_list{
        text-align: center;
        line-height: 1.8;
        font-size: 0;
        &_item{
          font-weight: bold;
          display: inline-block;
          margin-bottom: 0;
          background: #333333;
          color: #fff;
          padding: 0 10px;
          margin: 5px;
          font-size: 1.6rem;
          @include media($middle){
            font-size: 1.1rem;
            margin: 2px;
          }
        }
      }
    }
    &_main-image{
      text-align: center;
    }
    &_txt{
      margin: 30px 0;
      @include outer-container(980px);
      @include media($middle){
        margin: 20px 0;
        font-size: 1.3rem;
      }
    }
    &_sub-image{
      text-align: center;
      margin-bottom: 50px;
      @include media($middle){
        margin-bottom: 20px;
      }
    }
    &_data{
      @include outer-container(980px);
      background: #fff;
      color: #3f3936;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 3px solid #939a7b;
      @include media($middle){
        display: block;
      }
      &_heading{
        position: relative;
        background: #939a7b;
        color: #fff;
        font-family: $font-helvetica;
        height: 100%;
        margin: 0;
        font-size: 3rem;
        width: 150px;
        text-align: center;
        padding: 20px 10px;
        letter-spacing: 0.05em;
        font-weight: 500;
        @include media($middle){
          font-size: 2rem;
          width: 100%;
          padding: 10px;
        }
        &:after{
          content: "";
          display: inline-block;
          width: 15px;
          height: 20px;
          border-style: solid;
          border-width: 10px 0 10px 15px;
          border-color: transparent transparent transparent #939a7b;
          position: absolute;
          top: 0;
          right: -15px;
          bottom: 0;
          margin: auto;
          @include media($middle){
            border-style: solid;
            border-width: 10px 7.5px 0 7.5px;
            border-color: #939a7b transparent transparent transparent;
            width: 15px;
            height: 10px;
            left: 0;
            top: inherit;
            right: 0;
            bottom: -10px;
          }
        }
      }
      &_col-02{
        display: flex;
        @include media($middle){
          display: block;
          padding: 20px 10px 10px 10px;
        }
        &_item{
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include media($middle){
            justify-content: flex-start;
          }
          &:first-child{
            margin-right: 40px;
            @include media($middle){
              margin: 0 0 5px 0;
            }
          }
          dt{
            width: 116px;
            font-weight: normal;
            padding: 0;
            margin-right: 15px;
            background: #939a7b;
            color: #fff;
            text-align: center;
            @include media($middle){
              width: 100px;
              display: block;
              font-size: 1.2rem;
              padding: 2px 10px;
              text-align: center;
            }
          }
          dd{
            font-weight: bold;
            font-size: 1.8rem;
            @include media($middle){
              display: block;
              font-size: 1.2rem;
              text-align: center;
              letter-spacing: 0;
            }
          }
        }
      }
    }
    &_craft{
      margin-top: 60px;
      text-align: center;
      @include media($middle){
        margin-top: 30px;
      }
      &_catch{
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 30px;
        @include media($middle){
          font-size: 1.4rem;
          margin-bottom: 20px;
        }
      }
    }
  }

}
