.guarantee{
  padding-bottom: 0;

  .lower-head::before{
    width: 33%;
  }

  .lower-head_container{
    @include media($middle){
      margin: 0 5%;
    };
  }

  &_head{
    @include media($middle){
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
  }

  &_self{
    background: #efebe2;
    padding: 5px;
    @include media($middle){
    };
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      }
    }

    &_header{
      width: 780px;
      background-color: #969b7d;
      color: #ffffff;
      text-align: center;
      font-size: 3rem;
      font-weight: bold;
      letter-spacing: 0.05em;
      padding: 35px 0;
      margin: 100px auto 90px;
      position: relative;
      @include media($middle){
        margin: 50px 0 40px;
        padding: 15px 0;
        width: 100%;
        line-height: 1.3;
        font-size: 1.8rem;
      };
      &::before{
        content: "";
        display: inline-block;
        width: 28px;
        height: 25px;
        background: url('./assets/img/performance_header_under.png') no-repeat center;
        background-size: cover;
        position: absolute;
        bottom: -20px;
        left: 48%;
        @include media($middle){
          bottom: -20px;
          left: 45%;
        };
      }
      span{
        font-size: 4rem;
        @include media($middle){
          font-size: 2.6rem;
        };
      }
    }

    .heading-ptn-02{
      text-align: center;
      font-size: 3.4rem;
      margin-bottom: 50px;
      @include media($middle){
        font-size: 2rem;
        line-height: 1.8;
        margin-bottom: 30px;
      }
      img{
        @include media($middle){
          width: 25px;
        };
      }
      span::before{
      }
    }

    &_col-03{
      @include clearfix;
      @include outer-container($content-width);
      margin-top: 50px;
      margin-bottom: 35px;
      @include media($middle){
        margin-top: 30px;
        margin-bottom: 35px;
      }
      &_item{
        position: relative;
        width: 340px;
        float: left;
        margin-right: 30px;
        background: #fff;
        padding: 40px 0 0;
        @include media($middle){
          float: none;
          width: 100%;
          margin-right: 0px;
          margin-bottom: 20px;
          padding: 20px 0 0;
        }
        &--arrow{
          &:after{
            z-index: 1;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -70px;
            margin: auto;
            content: "";
            display: inline-block;
            width: 10px;
            height: 58px;
            background: url(./assets/img/performance_self_col-03_item--arrow.png) no-repeat center center;
            background-size: cover;
            @include media($middle){
              bottom: -50px;
            }
          }
        }
        &:last-child{
          margin-right: 0;
        }
        &_head{
          @include media($middle){
            display: flex; /* 子要素をflexboxで揃える */
            justify-content: space-between; /* 子要素をflexboxにより中央に配置する */
            align-items: center;  /* 子要素をflexboxにより中央に配置する */
            padding: 15px 10px 10px 10px;
          }
          &_num{
            font-size: 7rem;
            font-family: $font-helvetica;
            text-align: center;
            line-height: 0.8;
            margin-bottom: 0px;
            letter-spacing: 0.1em;
            color: #babeac;
            font-weight: 500;
            @include media($middle){
              font-size: 3.6rem;
              letter-spacing: 0;
            };
          }
          &_heading{
            text-align: center;
            font-weight: 500;
            margin: 0;
            font-size: 2rem;
            height: 115px;
            padding-top: 34px;
            @include media($middle){
              height: auto;
              padding: 0 5px;
              img{
                height: 38px;
                width: auto;
              }
            }
            &--ruby{
              padding-top: 15px;
              @include media($middle){
                padding-top: 0;
                img{
                  height: 76px;
                  width: auto;
                }
              }
            }
          }
          &_thumb{
            @include media($middle){
              width: 130px;
              text-align: right;
            }
          }
        }
        &_txt{
          color: #000;
          margin: 0;
          font-size: 1.4rem;
          padding: 20px 30px 40px;
          line-height: 2.2;
          @include media($middle){
            font-size: 1.2rem;
            padding: 0 20px 25px;
          }
        }
        &_sp-btn{
          font-size: 1.3rem;
          text-align: center;
          padding-bottom: 30px;
          a{
            display: inline-block;
            background: #4e4743;
            color: #fff;
            padding: 10px 30px;
            &:after{
              font-family: FontAwesome;
              content: "\f107";
              font-size: 1.4rem;
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }
  }

  &_recommend{
    color: #000000;
    margin-bottom: 90px;
    @include media($middle){
      margin-bottom: 45px;
    }
    &_container{
      position: relative;
      background: #fff;
      @include outer-container($content-width);
      padding: 65px 50px;
      @include media($middle){
        padding: 30px 15px 15px;
      }
    }
    &_osusume{
      text-align: center;
      margin-bottom: 25px;
      @include media($middle){
        width: 70%;
        margin: 0 auto 10px;
      };
    }
    .heading-ptn-02 span{
      &::before{
        top: -8px;
        @include media($middle){
          left: -27px;
        };
      }
      &::after{
        bottom: -7px;
        right: -33px;
      }
    }
    &_left{
      width: 640px;
      float: left;
      text-align: center;
      @include media($middle){
        width: auto;
        float: none;
        margin-bottom: 20px;
      }
    }
    &_right{
      width: 260px;
      float: right;
      @include media($middle){
        width: auto;
        float: none;
        text-align: center;
      }
    }
    &_heading{
      font-size: 3.5rem;
      font-weight: 500;
      line-height: 1;
      margin: 0 0 30px;
      line-height: 1.4;
      @include media($middle){
        font-size: 1.8rem;
        margin: 20px 0 30px;
        text-align: center;
        line-height: 1.3;
      }
      span{
        position: relative;
        padding: 0 30px;
        display: inline-block;
        @include media($middle){
          padding: 0 15px;
        }
        &:before{
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url(./assets/img/icon_kakko_left_black.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          left: 0;
          top: -5px;
          @include media($middle){
            width: 10px;
            height: 10px;
            top: -10px;
          }
        }
        &:after{
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url(./assets/img/icon_kakko_right_black.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          right: 0;
          bottom: -5px;
          @include media($middle){
            width: 10px;
            height: 10px;
            bottom: -10px;
          }
        }
      }
    }
    &_txt{
      font-size: 1.3rem;
      margin-bottom: 0;
      text-align: left;
      @include media($middle){
        line-height: 1.8;
        br{
          display: none;
        }
      }
    }
    &_col-04{
      margin-bottom: 0;
      &_heading{
        margin-bottom: 20px;
      }
      &_list{
        font-family: $font-noto;
        margin-bottom: 35px;
        @include media($middle){
          margin-bottom: 20px;
        }
        &_item{
          background: #ec6941;
          color: #fff;
          display: inline-block;
          padding: 5px 18px;
          border-radius: 5px;
          @include media($middle){
            padding: 1px 10px;
            margin: 2px 0;
            font-size: 1.3rem;
          }
        }
      }
    }
    &_thumb{
      text-align: center;
      @include media($middle){
        text-align: center;
        margin: 0 auto;
        width: 100%;
      }
      figcaption{
        display: block;
        text-align: right;
        font-size: 1.4rem;
        color: #000;
        @include media($middle){
          font-size: 1.1rem;
          text-align: center;
        }
      }
    }
  }

  &_support{
    padding: 100px 0;
    @include media($middle){
      padding: 45px 15px 30px;
    }
    .heading-ptn-02{
      span{
        &::before{
          @include media($middle){
            top: -6px;
            left: -46px;
          };
        }
        &::after{
          @include media($middle){
            bottom: -9px;
            right: -28px;
          };
        }
      }
    }
    &_col-02{
      display: flex;
      align-items: center;
      position: relative;
      &_left{
        width: 67.18%;
        /*
        position: absolute;
        left: 0;
        top: 0;
        */
        @include media($middle){
          position: static;
          width: 100%;
          margin-bottom: 15px;
        }
      }
      &_right{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        z-index: 1;
        margin-left: auto;
        background-color: #efebe2;
        text-align:center;
        width: 50%;
        padding: 55px 0;
        @include media($middle){
          background: #fff;
          transform: translate(0,0);
          position: relative;
          width: 100%;
          padding: 0;
        }
        &_logo{
          margin-bottom: 30px;
          @include media($middle){
            width: 80%;
            margin: 0 auto 10px;
          };
        }
        &_txt{
          font-size: 1.4rem;
          @include media($middle){
            font-size: 1.2rem;
            margin-bottom: 15px;
          };
        }
        .btn-ptn-01{
          text-align: left;
        }
      }
    }
  }

}
