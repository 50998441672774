.exterior{
  padding-bottom: 0;

  &_head{
    @include outer-container($content-width);
    padding-bottom: 90px;
    @include media($middle){
      padding: 0 15px 30px;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_txt{
      margin-bottom: 0;
      line-height: 2.2;
      @include media($middle){
        line-height: 1.8;
      }
    }
  }

  &_sec{
    padding: 330px 0 0 0;
    margin-bottom: 100px;
    @include media($middle){
      padding: 0;
      margin-bottom: 10px;
      background: none !important;
    }
    &--01{
      background: url(./assets/img/exterior_sec--01.jpg) no-repeat center center;
      background-size: cover;
    }
    &--02{
      background: url(./assets/img/exterior_sec--02.jpg) no-repeat center center;
      background-size: cover;
    }
    &--03{
      background: url(./assets/img/exterior_sec--03.jpg) no-repeat center center;
      background-size: cover;
      padding: 330px 0 330px;
      margin-bottom: 0;
      @include media($middle){
        padding: 0;
      }
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 15px;
      }
    }
    &_box{
      background: #fff;
      width: 530px;
      padding: 70px 85px;
      @include media($middle){
        width: auto;
        padding: 30px 15px;
        background: rgba(255,255,255,0.7);
      }
      &--left{
        float: left;
        @include media($middle){
          float: none;
        }
      }
      &--right{
        float: right;
        @include media($middle){
          float: none;
        }
      }
      &--center{
        float: none;
        width: auto;
        @include outer-container(980px);
        padding: 90px 100px;
        @include media($middle){
          padding: 30px 15px;
        }
      }
      &_heading{
        text-align: center;
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 1.6;
        position: relative;
        margin-bottom: 30px;
        @include media($middle){
          font-size: 1.6rem;
          margin-bottom: 20px;
        }
        &:after{
          content: "";
          display: block;
          width: 42px;
          height: 4px;
          background: #b8a57b;
          margin: 30px auto 0;
          @include media($middle){
            margin: 20px auto 0;
          }
        }
      }
      &_txt{
        margin: 0;
        line-height: 2.4;
        @include media($middle){
          line-height: 1.8;
        }
        &--small{
          font-size: 1.4rem;
          @include media($middle){
            font-size: 1.2rem;
          }
        }
        &--mb{
          margin-bottom: 20px;
        }
      }
    }
  }

  &_profile{
    background: #efebe2;
    padding: 100px 0;
    @include media($middle){
      padding: 30px 15px;
    }
    &_container{
      position: relative;
      @include outer-container($content-width);
      @include clearfix;
      background: #fff;
      padding: 50px 50px 40px;
      @include media($middle){
        padding: 20px;
      }
      &:before{
        content: "";
        display: inline-block;
        background: url(./assets/img/hideki_icon.png) no-repeat center center;
        background-size: cover;
        width: 246px;
        height: 95.5px;
        position: absolute;
        right: -15px;
        top: -30px;
        @include media($middle){
          width: 123px;
          height: 47px;
          right: -10px;
          top: -15px;
        }
      }
    }
    &_thumb{
      width: 210px;
      float: left;
      @include media($middle){
        width: 120px;
        float: none;
        margin: 0 auto 15px;
      }
    }
    &_data{
      width: 720px;
      float: right;
      @include media($middle){
        width: auto;
        float: none;
      }
      &_position{
        display: block;
        margin-bottom: 10px;
        border-bottom: 2px solid #dedee0;
        &_txt{
          font-weight: bold;
          font-size: 1.4rem;
          width: 120px;
          display: inline-block;
          background: #b8a57b;
          color: #fff;
          text-align: center;
          position: relative;
          top: 1px;
        }
      }
      &_heading{
        font-size: 2.5rem;
        font-weight: 500;
        margin-bottom: 5px;
        color: #959085;
        @include media($middle){
          font-size: 2rem;
          margin-bottom: 5px;
        }
        &_small{
          font-size: 1.6rem;
          @include media($middle){
            font-size: 1.4rem;
          }
        }
      }
      &_txt{
        font-size: 1.4rem;
        margin-bottom: 0;
        letter-spacing: 0.04em;
        @include media($middle){
          font-size: 1.3rem;
          letter-spacing: 0;
        }
      }
    }
  }

}
