/* ---------- content-width ---------- */
$content-width: 1080px;

/* ---------- breakpoints ---------- */
$mobile: new-breakpoint(max-width 599px 12);
$middle: new-breakpoint(max-width 896px 12);
$large: new-breakpoint(min-width 1080px 12);

/* ---------- gutter ---------- */
//$fg-gutter: 0.875em;
