.performance{
  padding-bottom: 0;

  &_cpn-heading{
    background: #ec6941;
    text-align: center;
    color: #fff;
    font-weight: bold;
    padding: 15px 10px;
    border-radius: 10px;
    margin-bottom: 45px;
    font-size: 2.5rem;
    @include media($middle){
      font-size: 2rem;
      margin-bottom: 20px;
      line-height: 1.2;
    }
    &_small{
      font-size: 1.6rem;
      vertical-align: bottom;
      margin-left: 20px;
      @include media($middle){
        font-size: 1.2rem;
        margin: 0;
      }
    }
    img{
      @include media($large){
        vertical-align: middle;
        position: relative;
        bottom: 2px;
      }
      @include media($middle){
        display: block;
        margin: 0 auto;
        width: 200px;
      }
    }
  }

  &_head{
    @include outer-container($content-width);
    margin-bottom: 60px;
    @include media($middle){
      margin-bottom: 45px;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
  }

  &_self{
    //background: linear-gradient(180deg, #ffffff 0%, #ffffff 7%, #efebe2 7%, #efebe2 100%);
    background: #efebe2;
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    .heading-ptn-02{
      text-align: center;
      font-size: 3.4rem;
      margin-bottom: 50px;
      @include media($middle){
        font-size: 2rem;
        line-height: 1.6;
        margin-bottom: 20px;
      }
      img{
        @include media($middle){
          width: 25px;
        };
      }
    }

    &_header{
      width: 780px;
      background-color: #969b7d;
      color: #ffffff;
      text-align: center;
      font-size: 4rem;
      font-weight: bold;
      letter-spacing: 0.05em;
      padding: 35px 0;
      margin: 100px auto 70px;
      span{
        position: relative;
        &::before{
          content: "";
          display: inline-block;
          width: 28px;
          height: 25px;
          background: url('./assets/img/performance_header_under.png') no-repeat center;
          background-size: cover;
          position: absolute;
          bottom: -50px;
          left: 50%;
        }
      }
    }

    &_col-03{
      @include clearfix;
      @include outer-container($content-width);
      margin-top: 40px;
      padding-bottom: 30px;
      @include media($middle){
        margin-top: 0;
      }
      &_item{
        position: relative;
        width: 340px;
        float: left;
        margin-right: 30px;
        background: #fff;
        padding: 40px 0 0;
        @include media($middle){
          float: none;
          width: 100%;
          margin: 0 0 10px 0;
          padding: 10px 0 0 0;
        }
        &--arrow{
          &:after{
            position: absolute;
            left: 0;
            right: 0;
            bottom: -50px;
            margin: auto;
            content: "";
            display: inline-block;
            width: 10px;
            height: 58px;
            background: url(./assets/img/performance_self_col-03_item--arrow.png) no-repeat center center;
            background-size: cover;
            z-index: 1;
            @include media($middle){
              display: none;
            }
          }
        }
        &:last-child{
          margin-right: 0;
        }
        &_head{
          @include media($middle){
            display: flex; /* 子要素をflexboxで揃える */
            justify-content: space-between; /* 子要素をflexboxにより中央に配置する */
            align-items: center;  /* 子要素をflexboxにより中央に配置する */
            padding: 15px 10px 10px 10px;
          }
          &_num{
            font-size: 7rem;
            font-family: $font-helvetica;
            text-align: center;
            line-height: 0.8;
            margin-bottom: 0px;
            letter-spacing: 0.1em;
            color: #babeac;
            font-weight: 500;
            @include media($middle){
              font-size: 3.6rem;
              letter-spacing: 0;
            }
          }
          &_heading{
            text-align: center;
            font-size: 2.5rem;
            font-weight: 500;
            margin: 0;
            padding: 15px 0 25px;
            @include media($middle){
              font-size: 1.5rem;
              padding: 0 5px;
            }
            &_small{
              display: block;
              font-size: 1.6rem;
              margin-bottom: 5px;
              @include media($middle){
                font-size: 1.1rem;
              }
            }
            &_made{
              font-size: 1.7rem;
              @include media($middle){
                font-size: 1.2rem;
                display: block;
              }
            }
          }
          &_thumb{
            @include media($middle){
              width: 130px;
              text-align: right;
            }
          }
        }
        &_txt{
          color: #000;
          margin: 0;
          font-size: 1.4rem;
          padding: 20px 30px 40px;
          line-height: 2.2;
          @include media($middle){
            font-size: 1.2rem;
            padding: 0 20px 25px;
            line-height: 1.8;
          }
        }
        &_sp-btn{
          font-size: 1.3rem;
          text-align: center;
          padding-bottom: 30px;
          a{
            display: inline-block;
            background: #4e4743;
            color: #fff;
            padding: 10px 30px;
            &:after{
              font-family: FontAwesome;
              content: "\f107";
              font-size: 1.4rem;
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }
  }

  &_recommend{
    position: relative;
    background-color: #efebe2;
    //padding-bottom: 137px;
    padding-bottom: 50px;
    @include media($middle){
      padding-bottom: 20px;
    }
    &:after{
      content: "";
      display: block;
      width: 46.5px;
      height: 48.5px;
      background: url(./assets/img/performance_recommend_after.png) no-repeat center center;
      background-size: cover;
      margin: 50px auto 0;
      @include media($middle){
        width: 30px;
        height: 30px;
        margin: 20px auto 0;
      }
    }
    &_container{
      position: relative;
      background: #fff;
      @include outer-container($content-width);
      padding: 70px 50px;
      @include media($middle){
        padding: 45px 15px 30px;
        margin: 0 15px;
      }
    }
    &_decosu{
    }
    figure{
      text-align: center;
      margin-bottom: 30px;
      @include media($middle){
        margin-bottom: 15px;
      }
    }
    &_left{
      width: 550px;
      float: left;
      text-align: center;
      @include media($middle){
        width: auto;
        float: none;
        margin-bottom: 20px;
      }
    }
    &_right{
      width: 390px;
      float: right;
      @include media($middle){
        width: auto;
        float: none;
        text-align: center;
      }
    }
    &_heading{
      font-size: 3.5rem;
      font-weight: 500;
      line-height: 1;
      margin: 0 0 30px;
      line-height: 1.4;
      @include media($middle){
        font-size: 1.8rem;
        margin: 20px 0 30px;
        text-align: center;
        line-height: 1.3;
      }
      span{
        position: relative;
        padding: 0 30px;
        display: inline-block;
        @include media($middle){
          padding: 0 15px;
        }
        &:before{
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url(./assets/img/icon_kakko_left_black.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          left: 0;
          top: -5px;
          @include media($middle){
            width: 10px;
            height: 10px;
            top: -10px;
          }
        }
        &:after{
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url(./assets/img/icon_kakko_right_black.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          right: 0;
          bottom: -5px;
          @include media($middle){
            width: 10px;
            height: 10px;
            bottom: -10px;
          }
        }
      }
    }
    &_sub-heading{
      text-align: center;
      font-weight: bold;
      margin-bottom: 30px;
      font-size: 1.8rem;
      @include media($middle){
        margin-bottom: 15px;
        font-size: 1.6rem;
        line-height: 1.6;
      }
    }
    &_txt{
      font-size: 1.4rem;
      margin-bottom: 20px;
      @include media($middle){
        br{
          display: none;
        }
      }
    }
    &_col-03{
      margin-bottom: 50px;
      &_heading{
        margin-bottom: 20px;
      }
      &_container{
        @include clearfix;
      }
      &_item{
        background: #ec6941;
        color: #fff;
        @include span-columns(4);
        @include omega(3n);
        padding: 10px 20px 25px;
        border-radius: 10px;
        @include media($middle){
          @include span-columns(12);
          text-align: left;
          border-radius: 5px;
          padding: 5px 10px;
          margin-bottom: 5px;
          &:last-child{
            margin-bottom: 0;
          }
        }
        dt{
          font-size: 2rem;
          font-weight: 500;
          border-bottom: 1px solid #fff;
          padding-bottom: 5px;
          margin-bottom: 5px;
          @include media($middle){
            display: inline-block;
            font-size: 1.3rem;
            text-align: left;
            border-right: 1px solid #fff;
            border-bottom: none;
            vertical-align: middle;
            margin: 0 3px 0 0;
            padding: 0 7px 0 0;
          }
        }
        dd{
          font-size: 1.4rem;
          text-align: left;
          line-height: 1.7;
          @include media($middle){
            display: inline-block;
            font-size: 1.2rem;
            text-align: left;
            vertical-align: middle;
            letter-spacing: -0.01em;
          }
        }
      }
    }
    &_thumb{
      text-align: center;
      @include media($middle){
        width: 200px;
        text-align: center;
        margin: 0 auto;
      }
      figcaption{
        display: block;
        text-align: right;
        font-size: 1.4rem;
        color: #000;
        @include media($middle){
          font-size: 1.1rem;
          text-align: center;
        }
      }
    }
    &_dannetsuseinou{
      clear: both;
      padding-top: 90px;
      @include media($middle){
        padding-top: 45px;
      }
      &_col-02{
        display: flex;
        justify-content: space-between;
        @include media($middle){
          display: block;
        }
        &_left{
          width: 450px;
          @include media($middle){
            width: auto;
          }
        }
        &_right{
          width: 450px;
          @include media($middle){
            width: auto;
            text-align: center;
          }
        }
        &_txt{
          font-size: 1.4rem;
        }
        &_thumb{
          text-align: center;
        }
      }
    }
  }

  &_relief{
    background: #efebe2;
    padding-bottom: 120px;
    @include media($middle){
      padding-bottom: 60px;
    }
    &_container{
      background-color: #ffffff;
      @include outer-container($content-width);
      padding: 70px 50px 80px;
      @include media($middle){
        padding: 45px 15px 45px;
        margin: 0 15px;
      }
    }
    &_heading{
      text-align: center;
      font-size: 3.4rem;
      font-weight: bold;
      margin-bottom: 40px;
      @include media($middle){
        font-size: 2rem;
        margin-bottom: 20px;
      }
    }
    &_sub-heading{
      text-align: center;
      font-weight: bold;
      font-size: 1.8rem;
      margin-bottom: 30px;
      @include media($middle){
        font-size: 1.6rem;
        margin-bottom: 20px;
      }
    }
    &_col-02{
      display: flex;
      justify-content: space-between;
      @include media($middle){
        display: block;
      }
      &_left{
        width: 490px;
        text-align: center;
        @include media($middle){
          width: auto;
          text-align: center;
          margin-bottom: 15px;
        }
      }
      &_right{
        width: 450px;
        @include media($middle){
          width: auto;
        }
      }
      &_thunb{}
      &_txt{
        margin-bottom: 0;
        font-size: 1.4rem;
        line-height: 2.4;
        @include media($middle){
          line-height: 1.8;
        }
      }
    }
  }

  &_passive{
    background: #fff;
    @include media($middle){
    }
    &_container{
      position: relative;
      @include outer-container($content-width);
      padding: 140px 0 100px;
      @include media($middle){
        padding: 45px 15px 30px;
      }
    }
    &_col-02{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      @include media($middle){
        margin-bottom: 20px;
        display: block;
      }
      &_left{
        width: 580px;
        @include media($middle){
          width: auto;
          float: none;
          text-align: center;
          padding-top: 0;
          img{
            width: 240px;
          }
        }
      }
      &_right{
        width: 435px;
        float: right;
        margin-top: -45px;
        @include media($middle){
          width: auto;
          float: none;
          padding: 20px;
          text-align: center;
          margin-top: 0;
        }
      }
    }
    &_head{
      text-align: center;
      margin-bottom: 20px;
    }
    &_heading{
      margin-bottom: 30px;
      text-align: center;
    }
    &_txt{
      font-size: 1.4rem;
      margin-bottom: 50px;
      text-align: center;
      @include media($middle){
        font-size: 1.3rem;
        margin-bottom: 20px;
        br{
          display: none;
        }
      }
    }
    &_merit{
      text-align: center;
      &_heading{
        margin-bottom: 25px;
      }
      &_list{
        &_item{
          margin-bottom: 6px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &_meikoukai{
    background: #efebe2;
    @include media($middle){
      padding: 45px 15px;
    }
    &_bg{
      position: relative;
      background: url(./assets/img/performance_meikoukai_head.jpg) no-repeat center top;
      background-size: contain;
      width: 100%;
      padding-top: 43.67%;
    }
    &_head{
      @include outer-container(980px);
      background: #969b7d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 5px solid #8e9378;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 980px;
      height: 136px;
      @include media($middle){
        position: static;
        display: block;
        width: auto;
        height: auto;
        padding: 10px;
      }
      &_txt{
        width: 620px;
        text-align: center;
        font-size: 2.2rem;
        color: #fff;
        line-height: 1.6;
        margin: 0;
        @include media($middle){
          width: auto;
          font-size: 1.4rem;
        }
        &_large{
          font-size: 3.5rem;
          font-weight: bold;
          display: block;
          @include media($middle){
            font-size: 2rem;
          }
        }
      }
      &_heading{
        position: relative;
        width: 333px;
        background: #fff;
        height: 100%;
        font-size: 5.2rem;
        text-align: center;
        font-weight: bold;
        margin: 0 0 0 15px;
        padding: 35px 10px;
        line-height: 1;
        @include media($middle){
          width: 260px;
          margin: 0 auto;
          font-size: 2.4rem;
          padding: 15px 10px;
          height: auto;
          position: relative;
          top: 10px;
        }
        &:before{
          content: "";
          display: inline-block;
          width: 17px;
          height: 17px;
          border-style: solid;
          border-width: 8.5px 17px 8.5px 0;
          border-color: transparent #ffffff transparent transparent;
          position: absolute;
          left: -17px;
          top: 0;
          bottom: 0;
          margin: auto;
          @include media($middle){
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5px 10px 5px;
            border-color: transparent transparent #ffffff transparent;
            left: 0;
            top: -10px;
            right: 0;
            bottom: inherit;
          }
        }
      }
    }
    &_container{
      @include outer-container($content-width);
    }
    &_txt{
      text-align: center;
      padding: 50px 0;
      margin-bottom: 0;
      line-height: 2.1;
      @include media($middle){
        padding: 20px 0 0;
        line-height: 1.8;
      }
    }
  }

  &_icon-desc{
    text-align: center;
    margin-top: 20px;
    font-size: 1.3rem;
    &_color{
      display: inline-block;
      width: 14px;
      height: 14px;
      background: #df6640;
      vertical-align: middle;
      margin-right: 3px;
      position: relative;
      bottom: 2px;
    }
  }

}
