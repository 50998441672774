.realestate{
}

.realestate-archive{
  padding-bottom: 0;

  &_container{
    @include outer-container($content-width);
  }
  &_catch{
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 90px;
    @include media($middle){
      font-size: 2.2rem;
      margin: 0 0 30px;
      text-align: center;
      line-height: 1.3;
    }
  }

  &_about{
    background-color: #efebe2;
    padding: 75px 0;
    @include media($middle){
      padding: 40px 0;

    }
    &_container{
      @include outer-container(980px);
      @include media($middle){
        margin: 0 5%;
      };
    }

    /*
    .heading-ptn-02{
      text-align: center;
      font-size: 3.4rem;
      margin-bottom: 50px;
      @include media($middle){
        font-size: 1.8rem;
        line-height: 1.8;
        margin-bottom: 20px;
      }
    }
    */

    &_sub-heading{
      width: 780px;
      background-color: #969b7d;
      color: #ffffff;
      text-align: center;
      font-size: 3rem;
      font-weight: bold;
      letter-spacing: 0.05em;
      padding: 25px 0;
      margin: 40px auto 50px;
      @include media($middle){
        margin: 30px 0;
        padding: 15px 0;
        width: 100%;
        line-height: 1.3;
        font-size: 1.7rem;
      };
      span{
        font-size: 4rem;
        position: relative;
        @include media($middle){
          font-size: 2.4rem;
        };
        &::before{
          content: "";
          display: inline-block;
          width: 28px;
          height: 25px;
          background: url('./assets/img/performance_header_under.png') no-repeat center;
          background-size: cover;
          position: absolute;
          bottom: -41px;
          left: 70%;
          @include media($middle){
            bottom: -55px;
            left: 44%;
          };
        }
      }
    }
    &_col-02{
      @include clearfix;
      &_item{
        position: relative;
        width: 480px;
        float: left;
        margin-right: 20px;
        background: #fff;
        padding-top: 40px;
        @include omega(2n);
        @include media($middle){
          margin-right: 0px;
          width: 100%;
          padding-top: 20px;
          margin: 0 0 20px;
        }
        &_num{
          font-size: 7rem;
          font-family: $font-helvetica;
          text-align: center;
          line-height: 0.8;
          margin-bottom: 0px;
          letter-spacing: 0.1em;
          color: #babeac;
          font-weight: 500;
          @include media($middle){
            font-size: 5.5rem;
            letter-spacing: 0.05em;
          };
        }
        &_heading{
          font-size: 1.6rem;
          text-align: center;
          margin-bottom: 0;
          padding: 10px 0 30px;
          line-height: 1.5;
          @include media($middle){
            padding: 10px 15px;
            font-size: 1.3rem;
          }
          &_bold{
            font-size: 2.5rem;
            display: block;
            font-weight: bold;
            @include media($middle){
              font-size: 2rem;
            }
          }
        }
        &_thumb{
          text-align: center;
        }
        &_txt{
          font-size: 1.4rem;
          padding: 30px 45px 45px;
          letter-spacing: 0;
          margin-bottom: 0;
          line-height: 2.2;
          @include media($middle){
            padding: 20px 20px 30px;
          }
          &_bold{
            font-weight: bold;
          }
        }
      }
    }
    &_bnr{
      width: 100%;
      @include outer-container(980px);
      @include media($middle){
        position: relative;
        text-align: center;
      }
      &:before{
        content: "";
        display: block;
        margin: 20px auto;
        width: 47px;
        height: 47px;
        background: url('./assets/img/realestate_plus.png') no-repeat center;
        background-size: cover;
        @include media($middle){
          margin: 0px auto 20px;
          width: 20px;
          height: 20px;
        }
      }
      a{
        display: block;
        @include clearfix;
        background: #fff;
      }
      &_thumb{
        width: 260px;
        float: left;
        @include media($middle){
          float: none;
          width: auto;
        }
      }
      &_txt{
        background-color: #ffffff;
        position: relative;
        font-size: 2.6rem;
        font-weight: bold;
        margin-bottom: 0;
        text-align: center;
        padding: 15px 0;
        border: 5px solid #babdab;
        @include media($middle){
          float: none;
          width: auto;
          line-height: 1.5;
          font-size: 1.6rem;
          padding: 10px 10px;
        }

      }
    }
  }

  &_suggestion{
    padding: 100px 0;
    @include media($middle){
      padding: 45px 15px;
    }
    &_container{
      @include outer-container($content-width);
    }

    &_box{
      border: 4px solid #babdab;
      padding: 50px;
      @include media($middle){
        border: 3px solid #babdab;
        padding: 20px;
      };
    }

    &_catch{
      font-weight: bold;
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 30px;
      @include media($middle){
        font-size: 1.6rem;
        margin-bottom: 15px;
      }
    }
    &_txt{
      font-size: 1.4rem;
      margin: 0;
      @include media($middle){
        font-size: 1.3rem;
        line-height: 1.8;
      };
    }
  }

  &_info{
    background-color: #efebe2;
    padding: 75px 0 100px;
    @include media($middle){
      padding: 30px 0;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };
    }

    &_en{
      font-size: 14rem;
      font-family: $font-helvetica;
      letter-spacing: 0.05em;
      color: #ffffff;
      text-align: center;
      line-height: 0.45;
      transform: translateY(-63px);
      margin-bottom: 0px;
      font-weight: 500;
      @include media($middle){
        font-size: 4rem;
        transform: translateY(0px);
        margin-bottom: 30px;
      };
    }
  }

}

.realestate-archive_list{
  @include media($large){
  @include outer-container(980px);
  };
  color: #000;
  a{
    color: #000;

  }
  &_item{
    background: #fff;
    padding: 25px;
    @include clearfix;
    margin-bottom: 20px;
    @include media($middle){
      padding: 5px;
      @include span-columns(6);
      @include omega(2n);
    }
    &:last-child{
      margin-bottom: 0;
    }
    &_left{
      width: 370px;
      float: left;
      @include media($middle){
        width: auto;
        float: none;
        margin-bottom: 10px;
      }
    }
    &_right{
      width: 510px;
      float: right;
      @include media($middle){
        width: auto;
        float: none;
      }
    }
    &_head{
      @include clearfix;
      margin-bottom: 15px;
      @include media($middle){
        margin-bottom: 5px;
      }
      &_category{
        width: 85px;
        float: left;
        text-align: center;
        display: block;
        background: #7e9abb;
        color: #fff;
        font-size: 1.4rem;
        font-weight: bold;
        padding: 5px;
        line-height: 1.6;
        margin-right: 10px;
        @include media($middle){
          width: 60px;
          font-size: 1rem;
          padding: 2px;
          display: block;
          margin: 0 auto 3px;
          float: none;
        }
        &.realestate_tm_land{
          background: #96a96d;
        }
        &.realestate_tm_usedhouse{
          background: #a3805b;
        }
        &.realestate_tm_rent{
          background: #aa6565;
        }
      }
      &_heading{
        width: 410px;
        float: right;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.6;
        margin-bottom: 0;
        @include media($middle){
          font-size: 1.3rem;
          width: auto;
          float: none;
        }
      }
    }
    &_txt{
      font-size: 1.4rem;
      line-height: 1.7;
      margin-bottom: 5px;
      @include media($middle){
        font-size: 0.9rem;
        line-height: 1.5;
        letter-spacing: 0;
        br{
          display: none;
        }
      }
    }
    &_price{
      color: #ff0000;
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 0;
      border-bottom: 1px solid #dedee0;
      padding-bottom: 5px;
      margin-bottom: 20px;
      @include media($middle){
        font-size: 1.3rem;
        line-height: 1.4;
        margin-bottom: 10px;
      }
    }
    &_data{
      &_item{
        display: table;
        width: 100%;
        margin-bottom: 5px;
        @include media($middle){
          margin-bottom: 5px;
          display: block;
        }
        &:last-child{
          margin-bottom: 0;
        }
        dt{
          display: table-cell;
          width: 85px;
          font-size: 1.2rem;
          font-weight: normal;
          text-align: center;
          vertical-align: middle;
          background-color: #efebe2;
          @include media($middle){
            font-size: 1.1rem;
            display: block;
            width: auto;
          }
        }
        dd{
          display: table-cell;
          font-size: 1.3rem;
          padding-left: 10px;
          @include media($middle){
            font-size: 1.1rem;
            display: block;
            width: auto;
            padding-left: 0;
          }
        }
      }
    }
  }
}

.realestate-single{
  padding-bottom: 0;
  .realestate-archive_list_item_head_category{
    padding: 5px 5px 6px;
    @include media($middle){
    margin: 0 0 5px;
    padding: 3px 5px;
    }
  }
  .single_head_heading{
    border-bottom: 2px solid #dedee0;
    padding-bottom: 20px;
    font-size: 2rem;
    @include media($middle){
      font-size: 1.8rem;
      padding-bottom: 15px;
    }
  }
  &_box{
    background-color: #efebe2;
    padding: 55px 0;
    @include media($middle){
      padding: 40px 0;
    };
  }
  &_container{
    position: relative;
    @include outer-container($content-width);
    background: #fff;
    padding: 100px 100px 50px;
    color: #000;
    margin-bottom: 90px;

    a{
      color: #000;
      text-decoration: underline;
    }
    @include media($middle){
      padding: 20px 15px 30px;
      margin: 0 15px;
      margin-bottom: 30px;
    }
  }
  &_time{
    position: absolute;
    right: 100px;
    top: 45px;
    font-size: 1.2rem;
    @include media($middle){
      position: static;
      font-size: 1rem;
      text-align: right;
      display: block;
      margin-bottom: 10px;
    }
  }
  &_col-02{
    @include clearfix;
    margin-bottom: 40px;
    @include media($middle){
      margin-bottom: 20px;
    }
    &_left{
      width: 430px;
      float: left;
      @include media($middle){
        width: auto;
        float: none;
        text-align: center;
        margin-bottom: 15px;
      }
    }
    &_right{
      width: 380px;
      float: left;
      margin-left: 30px;
      @include media($middle){
        width: auto;
        float: none;
        margin-left: 0;
      }
    }
    &_recommend{
      font-size: 1.4rem;
      margin-bottom: 15px;
      line-height: 1.6;
      @include media($middle){
        font-size: 1.2rem;
        margin-bottom: 5px;
      }
    }
    &_price{
      font-size: 2.4rem;
      font-weight: bold;
      color: #ff0000;
      padding-bottom: 5px;
      margin-bottom: 30px;
      line-height: 1.7;
      @include media($middle){
        font-size: 2rem;
        margin-bottom: 15px;
      }
    }
    &_data{
      &_item{
        display: table;
        width: 100%;
        margin-bottom: 5px;
        @include media($middle){
          margin-bottom: 3px;
        }
        &:last-child{
          margin-bottom: 0;
        }
        dt{
          display: table-cell;
          width: 85px;
          font-size: 1.2rem;
          font-weight: normal;
          text-align: center;
          background-color: #efebe2;
          vertical-align: middle;
          @include media($middle){
            font-size: 1.1rem;
          }
        }
        dd{
          display: table-cell;
          font-size: 1.3rem;
          padding-left: 10px;
          @include media($middle){
            font-size: 1.1rem;
            width: auto;
            padding-left: 10px;
          }
        }
      }
    }
  }
  &_contents{
    margin-bottom: 45px;
    @include media($middle){
      margin-bottom: 30px;
    }
  }
  &_detail{
    @include clearfix;
    margin-bottom: 50px;
    @include media($middle){
      margin-bottom: 30px;
    }
    &_table{
      width: 100%;
      font-size: 1.4rem;
      color: #333333;
      width: 100%;
      @include media($middle){
        font-size: 1.2rem;
        display: block;
        border-bottom: 1px solid #dcdcdc;
      }
      tbody{
        @include media($middle){
          display: block;
          width: 100%;
        }
      }
      tr{
        @include media($middle){
          display: block;
        }
      }
      th{
        width: 140px;
        padding: 0;
        text-align: center;
        font-weight: normal;
        background: #efebe2;
        padding: 5px;
        border: 1px solid #babeac;
        @include media($middle){
          display: block;
          width: 100%;
          border-bottom: none;
        }
      }
      td{
        width: 300px;
        padding: 5px 10px;
        border: 1px solid #babeac;
        @include media($middle){
          display: block;
          width: 100%;
          border-bottom: none;
        }
      }
    }
    .color-red{
      color: #ff0000;
      font-weight: bold;
    }
  }
  &_map{
    margin-bottom: 0px;
    @include media($middle){
      margin-bottom: 30px;
    }
    iframe{
      width: 100%;
      height: 345px;
      @include media($middle){
        height: 300px;
      }
    }
  }
  &_btn-list{
    text-align: center;
    margin-bottom: 30px;
    @include media($middle){
      margin-bottom: 20px;
    };
    &_item{
      display: inline-block;
      margin: 0 5px;
      transition: 0.3s;
      @include media($middle){
        margin-bottom: 10px;
        width: 80%;
      };
      &:hover{
        opacity: 0.8;
      }
    }
  }
  &_btn{
    text-align: center;
    a{
      text-align: left;
    }
  }
}

.realestate-single{
  .t-wrap-01{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
  }
  .slider, .slider1{
    width: 100%;
    margin-bottom: 10px;
  }
  .slider-item{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }  
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: auto !important;
  }
  .thumbnail-list, .thumbnail-list1{
    width: 100%;
    display: flex;
    // justify-content: space-between;
    flex-wrap:wrap;
  }
  .thumbnail-item, .thumbnail-item1{
    flex: 0 1 24.5%;
    margin-bottom: 0.6666%;
    margin-right: 0.6666%;
    &:nth-of-type(4n){
      margin-right: 0;
    }
    box-sizing: border-box;
    position:relative;
    &:after{
      content:'';
      background-color: rgba(0,0,0,0.5);
      position:absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: .3s opacity linear;
    }
    &.thumbnail-current:after{
      opacity: 0;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    } 
  }
  
  .t-wrap-heading{
    margin-bottom: 40px;
    margin-left: 27px;
  
    @include media($middle){
      margin-left: 15px;
    }
  }
  
  .t-wrap-02{
    margin-bottom: 50px;
  }
  
  .t-wrap-03{
    margin-bottom: 50px;
    &_recommend{
      font-size: 1.4rem;
      margin-bottom: 15px;
      line-height: 1.6;
    }
    &_price{
      font-size: 2.4rem;
      font-weight: bold;
      color: #ff0000;
      padding-bottom: 5px;
      margin-bottom: 30px;
      line-height: 1.7;
    }
  
    &_container{
      display: flex;
      align-items: center;
  
      @include media($mobile){
        align-items: baseline;
        flex-direction: column;
      }
    }
    &_left{
      width: 50%; 
  
      @include media($mobile){
        width: 100%;
      }
    }
    &_data{
      width: 50%;
      padding-left: 50px;
  
      @include media($mobile){
        padding-left: 0;
        width: 100%;
      }
  
      &_item{
        display: table;
        width: 100%;
        margin-bottom: 5px;
        dt{
          display: table-cell;
          width: 85px;
          font-size: 1.2rem;
          font-weight: normal;
          text-align: center;
          background-color: #efebe2;
          vertical-align: middle;
        }
        dd{
          display: table-cell;
          font-size: 1.3rem;
          padding-left: 10px;
        }
  
      }
    }
  
  }
}