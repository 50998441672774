.company{
  padding-bottom: 0;

  &_philosophy{
    margin-top: 50px;
    padding-top: 60px;
    padding-bottom: 110px;
    background-color: #efebe2;
    @include media($middle){
      margin-top: 30px;
      padding-top: 40px;
      padding-bottom: 50px;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };
    }
    &_item{
      background-color: #ffffff;
      box-shadow: 8px 8px 0px 0px #dcd5c5;
      display: flex;
      align-items: center;
      padding: 45px 80px;
      margin-bottom: 30px;
      @include media($middle){
        flex-direction: column;
        margin-bottom: 30px;
        padding: 20px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &_heading{
        width: 320px;
        height: 120px;
        margin-bottom: 0;
        margin-right: 60px;
        font-size: 4.8rem;
        font-family:  $font-helvetica;
        letter-spacing: 0.05em;
        text-align: center;
        line-height: 0.9;
        border-right: 2px solid #dcd5c5;
        padding: 20px 40px 20px 0;
        font-weight: 500;
        @include media($middle){
          width: auto;
          height: auto;
          margin-right: 0px;
          border-right: 0px solid #dcd5c5;
          font-size: 3rem;
          letter-spacing: 0.1em;
          padding: 0px 0px 10px 0;
        }
        span{
          font-size: 1.8rem;
          font-weight: bold;
          color: #ab9258;
          line-height: 1.6;
          display: inline-block;
          @include media($middle){
            font-size: 1.4rem;
          };
        }
        &--2{
          padding: 10px 40px 10px 0;
          @include media($middle){
            padding: 10px 0px;
          };
          span{
            margin-top: 10px;
            padding: 10px 40px 10px 0;
            @include media($middle){
              padding: 0px;
            };
          }
        }
      }
      &_right{
        width: 730px;
        @include media($middle){
          width: 100%;
        }
      }

      &_catch{
        font-size: 1.8rem;
        font-family: $font-noto;
        margin-bottom: 10px;
        @include media($middle){
          font-size: 1.6rem;
        }
      }
      &_quality{
        font-family: $font-helvetica;
        color: #969b7d;
        font-size: 3.6rem;
        letter-spacing: 0.05em;
        margin-bottom: 5px;
        line-height: 1.6;
        font-weight: 500;
        @include media($middle){
          font-size: 2rem;
          text-align: center;
          letter-spacing: 0.1em;
        }
      }
      &_txt{
        font-family: $font-noto;
        margin-bottom: 0;
        @include media($middle){
          font-size: 1.2rem;
        }
      }
    }
  }

  &_sdgs{
    background: #babeac;
    padding: 50px 0;
    @include media($middle){
      padding: 45px 15px;
    }
    &_container{
      @include outer-container(780px);
    }
    &_txt{
      font-family: $font-noto;
      font-size: 2rem;
      text-align: center;
      font-weight: 500;
      margin-bottom: 30px;
      @include media($middle){
        font-size: 1.4rem;
        text-align: center;
        line-height: 1.5;
      }
    }
  }

  &_img{
    display: flex;
    &_item{
      width: 50%;
    }
  }

  &_info{
    padding: 100px 0;
    background-color: #efebe2;
    @include media($middle){
      padding: 45px 0px;
    }
    &_container{
      text-align: center;
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };
    }
    &_table{
      display: inline-block;
      padding: 70px 50px;
      background: #fff;
      color: #000000;
      font-size: 1.4rem;
      font-family: $font-noto;
      @include media($middle){
        font-size: 1.2rem;
        padding: 20px;
      }
      a{
        color: #000;
        text-decoration: underline;
      }
      tr{
        @include media($middle){
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
        };
        th{
          border-bottom: 1px solid #333333;
          color: #333333;
          width: 21%;
          padding: 15px 10px 15px 20px;
          @include media($middle){
            width: 100%;
            padding: 10px 0 5px 10px;
          }
        }
        td{
          text-align: left;
          width: 79%;
          border-bottom: 1px solid #d4d4d4;
          padding: 15px 0px 15px 70px;
          @include media($middle){
            padding: 5px 0 10px 10px;;
            width: 100%;
            border-bottom: 0px solid #d4d4d4;
          }
        }
      }
    }
    .heading-ptn-02{
      span{
        &::before{
          left: -110px;
        }
        &::after{
          right: -110px;
        }
      }
    }
  }

  &_history{
    background: #f8f7f3;
    padding: 100px 0;
    @include media($middle){
      padding: 45px 0px;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };
    }
    &_box{
      background-color: #ffffff;
      padding: 70px 50px;
      @include media($middle){
        padding: 20px;
      };
    }
    &_item{
      @include clearfix;
      margin-bottom: 17px;
      padding-bottom: 17px;
      border-bottom: 1px solid #d4d4d4;
      &:last-child{
        margin-bottom: 0;
      }
      dt{
        width: 120px;
        float: left;
        position: relative;
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        @include media($middle){
          font-size: 1.2rem;
          width: 18%;
        }
        &:before{
          content: "";
          display: block;
          width: 72px;
          height: 1px;
          background: #99928f;
          position: absolute;
          top: 0;
          right: -13px;
          bottom: 0;
          margin: auto;
          z-index: 1;
          @include media($middle){
            display: none;
          };
        }
        span{
          position: relative;
          z-index: 2;
          display: inline-block;

          padding-right: 10px;
          @include media($middle){
            padding-right: 5px;
          }
        }
      }
      dd{
        width: 820px;
        float: right;
        font-family: $font-noto;
        font-size: 1.4rem;
        @include media($middle){
          float: right;
          width: 82%;
          padding-left: 5px;
          font-size: 1.2rem;
        }
      }
    }
    .heading-ptn-02{
      span{
        &::before{
          left: -110px;
        }
        &::after{
          right: -110px;
        }
      }
    }
  }

}
