.header{
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0px 0px 0 30px;
  z-index: 1111;
  transition: all 300ms ease-in-out;
  @include media($middle){
    padding-left: 15px;
  }
  &-navi-open{
    background: rgba(100, 91, 87, 0);
  }
  &_container{
    @include clearfix;
    position: relative;
    z-index: 9999;
  }

  &_logo{
    float: left;
    line-height: 1;
    padding-top: 25px;
    @include media($middle){
      width: 100px;
      padding-top: 20px;
    }
  }

  &.header-navi-open{

  }

  &_btns{
    font-weight: 500;
    line-height: 1;
    display: flex;
    position: absolute;
    right: 110px;
    top: 20px;
    @include media($middle){
      display: none;
    }
    &_item{
      display: inline-block;
      font-weight: bold;
      &:last-child{
        margin-right: 0;
      }
      a{
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        position: relative;
        text-align: center;
        color: #fff;
        display: block;
        padding: 8px 50px 6px;
        border: 1px solid #ffffff;
        overflow: hidden;
        // &:before{
        //   content: "";
        //   width: 12px;
        //   height: 12px;
        //   display: inline-block;
        //   background: url(./assets/img/icon_btn_line.png) no-repeat center center;
        //   background-size: cover;
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        //   transition: all 150ms ease-in-out;
        // }
        // &:after{
        //   content: "";
        //   width: 12px;
        //   height: 12px;
        //   display: inline-block;
        //   background: url(./assets/img/icon_btn_line.png) no-repeat center center;
        //   background-size: cover;
        //   position: absolute;
        //   right: 0;
        //   bottom: 0;
        //   transition: all 150ms ease-in-out;
        // }
        &:hover{
          opacity: 1;
          // &:before{
          //   left: -12px;
          //   top: -12px;
          //   transition: all 200ms ease-in-out;
          // }
          // &:after{
          //   right: -12px;
          //   bottom: -12px;
          //   transition: all 200ms ease-in-out;
          // }
        }
        span{
          font-size: 1rem;
          margin: 0 auto;
          font-family: $font-helvetica;
          font-weight: 500;
        }
      }
    }
  }

  &_modal{
    display: none;
    @include outer-container($content-width);
    padding-bottom: 30px;
    &_list{
      display: flex;
      justify-content: space-around;
      @include media($middle){
        flex-direction: column;
      };
    }
    &_col{
      line-height: 2;
      letter-spacing: 0;
      @include media($middle){
        width: auto;
        margin-bottom: 5px;
      }
      &_heading{
        color: #fff;
        font-size: 1.4rem;
        margin-bottom: 5px;
        font-weight: bold;
      }
      &_list{
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 30px;
        @include media($middle){
          font-size: 1.2rem;
          margin-bottom: 15px;
        }
        &_item{
          margin-bottom: 5px;
          &--blank{
            a{
              color: #fff;
              &:after{
                content: "";
                display: inline-block;
                background: url(./assets/img/icon_blank.png) no-repeat center center;
                background-size: cover;
                width: 15px;
                height: 15px;
                margin-left: 10px;
              }
            }
          }
          a{
            color: #fff;
            display: flex;
            align-items: center;
          }
          &_right{
            font-size: 1rem;
            display: inline-block;
            margin-right: 7px;
          }
        }
      }
    }
    &_sns{
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      &_item{
        margin: 0 10px;
      }
    }
    &_sub-navi{
      @include outer-container($content-width);
      text-align: center;
      font-size: 1.4rem;
      margin-bottom: 50px;
      @include media($middle){
        font-size: 1.2rem;
        line-height: 2.2;
        margin-bottom: 40px;
      }
      &_item{
        display: inline-block;
        margin: 0 10px;
        a{
          color:#fff;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.modaal-inner-wrapper{
  @include media($middle) {
  }
}

.hamburger-bg{
  width: 80px;
  height: 80px;
  background-color: #333333;
  margin-left: auto;
  @include media($middle){
    width: 60px;
    height: 60px;
  };

}

$bar-width: 38px;
$bar-height: 3px;
$bar-spacing: 13px;

.menu-wrapper {
  position: fixed;
  /*
  right: 19px;
  top: 25px;
  width: $bar-width;
  height: $bar-height + $bar-spacing*2;
  */
  right: 0;
  top: 0;
  width: 80px;
  height: 80px;
  cursor: pointer;
  outline: none;
  text-align: center;
  line-height: 43px;
  @include media($middle){
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    line-height: 28px;
  }
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
  height: 2px;
  z-index: 99999;
  display: inline-block;
  position: relative;
  @include media($middle){
    width: 30px;
    height: 1px;
  }
}

.hamburger-menu {
  position: relative;
  transform: translateY($bar-spacing);
  background: rgba(255, 255, 255, 1);
  transition: all 0ms 300ms;
  &.hide{
    display: none;
  }

  &.animate {
    background: rgba(255, 255, 255, 0);
  }
}

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: rgba(255, 255, 255, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: rgba(255, 255, 255, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.modal-navi{
  display: none
}
.modaal-wrapper{
  z-index: 1111;
  outline: none;
}
.modaal-overlay{
  z-index: 110;
}
.modaal-close{
  position: fixed;
  letter-spacing: 0;
  right: 15px;
  top: 10px;
  z-index: 9999;
  border-radius: 0 !important;
  width: 48px;
  height: 60px;
  @include media($middle){
    right: 5px;
    top: 0;
  }
  &:before{
    border-radius: 0 !important;
    height: 34px;
    width: 2px;
  }
  &:after{
    border-radius: 0 !important;
    height: 34px;
    width: 2px;
  }
}
.modaal-close:focus, .modaal-close:hover{
  background: none !important;
  color: #fff;
  &:before{
    background: #fff;
  }
  &:after{
    background: #fff;
  }
}
.modaal-container{
  max-width: 1080px;
  background: none;
  padding: 0;
  color: #fff;
}
.modaal-content-container{
  padding: 0;
}
.header_modal_container{
  @include media($middle){
    padding-top: 60px;
  }
}
.modaal-inner-wrapper{
  @include media($middle){
    padding-top: 0;
  }
}
