.sitemap{

  &_container{
    margin-top: 50px;
    margin-bottom: 70px;
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
      margin-bottom: 40px;
    }
  }

  &_navi-home{
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 30px;
    @include media($middle){
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    a{
      &:before{
        font-family: FontAwesome;
        content: "\f0da";
        font-size: 1.4rem;
        margin: 0 10px;
      }
    }
  }

  &_navi{
    @include outer-container($content-width);
    @include clearfix;
    margin-bottom: 70px;
    @include media($middle){
      margin-bottom: 20px;
    }
    &_col{
      width: 247px;
      float: left;
      line-height: 2;
      letter-spacing: 0;
      margin-right: 30px;
      &:last-child{
        margin-right: 0;
      }
      @include media($middle){
        margin-bottom: 15px;
        margin-right: 0px;
        width: 100%;
      }
      &_heading{
        font-size: 1.6rem;
        color: #ffffff;
        margin-bottom: 10px;
        background: #333333;
        padding: 6px 15px 4px;
        @include media($middle){
          font-size: 1.6rem;
        }
      }
      &_list{
        font-size: 1.6rem;
        font-weight: 400;
        margin-bottom: 20px;
        @include media($middle){
          font-size: 1.4rem;
        }
        &_item{
          margin-bottom: 10px;
          padding-left: 20px;
          &--blank{
            a{
              position: relative;
              &:after{
                content: "";
                display: inline-block;
                background: url(./assets/img/sitemap_icon_blank.png) no-repeat center center;
                background-size: cover;
                width: 15px;
                height: 15px;
                margin-left: 10px;
                vertical-align: middle;
                position: relative;
                bottom: 2px;
              }
            }
          }
          a{
            position: relative;
            &::before{
              content:"";
              display: inline-block;
              width: 8.7px;
              height: 9px;
              background: url('./assets/img/sitemap_right_1.png') no-repeat center;
              background-size: cover;
              position: absolute;
              top: 0;
              left: -18px;
              bottom: 0;
              margin: auto;
              @include media($middle){
                top: 2px;
                left: -16px;
              };
            }
          }
        }
      }
    }
  }

  &_sub-navi{
    font-size: 1.6rem;
    text-align: center;
    @include media($middle){
      font-size: 1.4rem;
    }
    &_item{
      display: inline-block;
      margin-right: 40px;
      @include media($middle){
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
      }
      &:last-child{
        margin-right: 0;
      }
      a{
        position: relative;
        &::before{
          content:"";
          display: inline-block;
          width: 8.7px;
          height: 9px;
          background: url('./assets/img/sitemap_right_1.png') no-repeat center;
          background-size: cover;
          position: absolute;
          top: 4px;
          bottom: 0;
          left: -18px;
          margin: auto;
        }
      }
    }
  }

}
