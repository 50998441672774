
.calendar_heading{
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 30px;
  &_en{
    font-family: $font-helvetica;
    display: block;
    text-align: center;
    font-size: 1.2rem;
    margin-top: 10px;
    letter-spacing: 0.1em;
    font-weight: 500;
  }
}
.calendar_sub-heading{
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  @include media($middle){
    font-size: 1.4rem;
    margin-top: 20px;
  }
  a{
  }
}
.container-calendar {
  @include clearfix;
  background: #efebe2;
  padding: 15px 0 100px;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  color: #000;
  @include media($middle){
    padding: 20px 15px 40px;

  }
  &_left{
    width: 39%;
    float: left;
    @include clearfix;
    @include media($middle){
      width: 100%;
      float: none;
    }
    .heading-ptn-02{
      text-align: center;
      margin-bottom: 10px;
      span{
        width: 88%;
        display: inline-block;
        &::before{
          top: -5px;
        }
        &::after{
          bottom: -5px;
        }
      }
    }
    &_txt{
      text-align: center;
      font-weight: bold;
      margin-bottom: 0;
      @include media($middle){
        font-size: 1.2rem;
        margin-bottom: 10px;
      };
    }
  }
  &_right{
    width: 57%;
    float: right;
    @include clearfix;
    @include media($middle){
      width: 100%;
      float: none;
    }
    &_container{
      background-color: #ffffff;
      padding: 25px 50px 30px;
      @include media($middle){
        padding: 25px 10px;
      };
    }
    &_date{
      font-size: 1.8rem;
      font-weight: bold;
    }
  }
  &_container{
    @include outer-container($content-width);
  }
}
.button-container-calendar button {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #fff;
  color: #333333;
  padding: 12px 4px 4px;
  border: 1px solid #fff;
  vertical-align: middle;
}
.table-calendar{
  margin-top: 0;
  border-collapse: collapse;
  width: 100%;
  background: #fff;
  line-height: 1.8;
  @include media($middle){
    line-height: 1.7;
  }
}
.table-calendar th{
  padding: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: top;

  @include media($middle){
    padding: 6px 4px;
    vertical-align: middle;
  }
}
.table-calendar td{
  padding: 12px 5px;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: top;
  @include media($middle){
    padding: 6px 4px;
    vertical-align: middle;
  }
}
#thead-month tr th{
  background: #333333;
  color: #fff;
  font-weight: normal;
  &:first-of-type{
    background: #a21900;
  }
  &:last-of-type{
    background: #066589;
  }
}
.date-picker.selected {
  font-weight: bold;
  color: #fff;
  background: #dcdcdc;
}
.date-picker.selected span {
  //border-bottom: 2px solid currentColor;
}
/* 日曜 */
.date-picker:nth-child(1) {
color: red;
}
/* 土曜 */
.date-picker:nth-child(7) {
color: blue;
}
#monthAndYear {
  background-color: #ffffff;
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 5px 10px;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0.1em;
}
.button-container-calendar {
  position: relative;
  margin-bottom: 1em;
  overflow: hidden;
  clear: both;
}
#previous {
  float: left;
}
#next {
  float: right;
}
.footer-container-calendar {
  display: none;
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}
.footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #ffffff;
  color: #454545;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}


.date-picker.has-event{
  span{
    cursor: pointer;
    border-radius: 50%;
    background: #eb9000;
    color: #fff;
    letter-spacing: 0;
    width: 30px;
    height: 30px;
    display: inline-block;
    @include media($middle){
      width: 25px;
      height: 25px;
      padding-top: 2px;
      font-size: 1.2rem;
    }
  }
}
.date-picker.color-strong{
  span{
    background: #eb4800;
  }
}
.event-ajax{
  @include clearfix;
  color: #4e4743;
  padding: 20px;
  border-bottom: 3px solid #dedee0;
  background: #fff;
  &:first-of-type{
    border-top: 3px solid #dedee0;
  }
  a{
    color: #4e4743;
    display: block;
    @include clearfix;
    @include media($middle){
    }
  }
  &_left{
    width: 35%;
    float: left;
    @include media($middle){
      img{
      }
    }
  }
  &_right{
    width: 65%;
    float: right;
    padding-left: 15px;
    @include media($middle){
      padding-left: 10px;
    }
  }
  h3{
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 5px;
    line-height: 1.3;
    @include media($middle){
      font-size: 1.2rem;
    }
  }
  &_time{
    display: block;
    font-size: 1.2rem;
    line-height: 1.5;
    margin: 0;
    font-weight: 500;
    @include media($middle){
      font-size: 1rem;
    }
  }
  &_txt{
    font-size: 1.1rem;
    line-height: 1.5;
    margin: 0;
  }
  p{
    font-size: 1.1rem;
    line-height: 1.5;
    margin: 0;
    @include media($middle){
      display: none;
    }
  }
}
