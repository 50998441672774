.oohouse{
    &_flow{
        background-color: #babeac;
        padding-top: 115px;
        padding-bottom: 100px;
        @include media($middle){
            margin-bottom: 0px;
            padding: 40px 0;
        }
        .heading-ptn-02{
            margin-bottom: 120px;
            position: relative;
            left: 25px;
            @include media($middle){
                text-align: center;
                margin-bottom: 30px;
                left: 0;
            }
        }
        &_container{
            @include outer-container(1080px);
            @include media($middle){
                margin: 0 5%;
            }
        }
        &_content{
            display: flex;
            @media screen and (max-width: 900px) {
                max-width: 600px;
                margin: 0 auto;
                width: 100%;
                display: block;
            }
            &_item{
                text-align: center;
                flex: 1;
                margin-right: 50px;
                position: relative;
                &:last-of-type{
                    margin-right: 0;
                }
                @media screen and (max-width: 900px) {
                    width: 100%;
                    margin-bottom: 50px;
                    margin-right: 0;
                }
                &_wrap{
                    display: flex;
                    flex-direction: column;
                    background-color: #fff;
                    padding: 30px 20px;
                }
                &:not(&:last-of-type){                   
                    &::after{
                        content: "";
                        display: block;
                        width: 27px;
                        height: 20px;
                        background: url(./assets/img/flow_step-sec_flow-list_item_arrow.png) no-repeat center center;
                        background-size: cover;
                        position: absolute;
                        top: 50%;
                        right: -40px;
                        transform: translateY(-50%) rotate(-90deg);
                        @media screen and (max-width: 900px) {
                            top: auto;
                            bottom: -35px;
                            right: auto;
                            left: 50%;
                            transform: translate(-50%,0);
                        }


                    }
                }


                &_num{
                    width: 70px;
                    color: #babeac;
                    position: relative;
                    font-family: $font-helvetica;
                    letter-spacing: 0.05em;
                    line-height: 1;
                    font-size: 7rem;
                    margin: 0 auto 40px;
                    display: block;
                    text-align: center;
                    @include media($middle){
                        font-size: 5rem;
                    }
                    @include media($mobile){
                        margin-bottom: 20px;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    span{
                        border-bottom: 5px solid #babeac;
                        @include media($middle){
                            border-bottom: 2px solid #babeac;
                        }
                    }
                }
                &_heading{
                    font-size: 2.6rem;
                    font-weight: bold;
                    line-height: calc(38/26);
                    flex-grow: 1;
                    @include media($mobile){
                        font-size: 2rem;
                    }
                }
                &_txt{
                    font-size: 1.4rem;
                    line-height: calc(36/14);
                    text-align: left;
                    margin-bottom: 0;
                    flex-grow: 1;
                    @media screen and (max-width: 900px) {
                        text-align: center;
                    }
                    @include media($mobile){
                        font-size: 1.2rem;
                    }
                }
            }
            figure{
                margin-bottom: 20px;
                flex-grow: 1;
                height: 85px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    margin-left: 15px;
                    @include media($middle){
                        margin-left: 0px;
                    }
                }
            }
        }
    }
    &_archive{
        background: #efebe2;
        padding-top: 90px;
        padding-bottom: 90px;
        &_container{
            @include outer-container(1080px);
            @include media($middle){
                margin: 0 5%;
            }
        }
        .kc-m-clearfix__link{
            position: relative;
            display: block;
        }
        .kc-m-event__tag{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            color: #333;
            font-size: 1.4rem;
            font-weight: bold;
            position: absolute;
            top: 0;
            left: 0;
            span{
                display: flex;
                padding-right: 1rem;
            }
            &::before{
                content: "";
                display: block;
                width: 2rem;
                height: 3rem;
                background-color: #cbb277;
                margin-right: 1rem;
            }
        }
        .kc-m-txt{
            font-size: 1.4rem;
            font-weight: 500;
            line-height: calc(38/14);
            letter-spacing: 0.05em;
            margin-bottom: 35px;
            @include media($mobile){
                margin-bottom: 20px;
                font-size: 1.2rem;

            }
        }
        .kc-m-taglist{
            width: 100%;
            line-height: 1;
            padding-bottom: 25px;
            border-bottom: 1px dashed #333;
            margin-bottom: 25px;
            @include media($mobile){
                margin-bottom: 15px;
                padding-bottom: 15px;
            }
            &_item{
                display: inline-block;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 1;
                letter-spacing: 0.05em;
                background-color: #BABEAC;
                padding: 5px 10px;
                margin-right: 10px;
                @include media($mobile){
                    font-size: 1.2rem;
    
                }
            }
        }
        
    }
}