.archive-staffblog{

  &_container{
    @include outer-container(860px);
    margin-bottom: 50px;
    @include media($middle){
      padding: 0 15px;
      margin-bottom: 30px;
    }
  }

}

.archive-staffblog-list{
  @include media($middle){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &_item{
    @include clearfix;
    padding: 40px 30px;
    margin-bottom: 20px;
    background: #fff;
    @include media($middle){
      padding: 10px;
      width: 49%;
      margin-bottom: 2.5%;
      padding-bottom: 10px;
    }
    a{
      color: #000;
    }
    &_left{
      width: 190px;
      float: left;
      @include media($middle){
        width: auto;
        float: none;
        text-align: center;
        margin-bottom: 10px;
      }
    }
    &_right{
      width: 560px;
      float: right;
      @include media($middle){
        width: auto;
        float: none;
        text-align: center;
      }
    }
    &_info{
      margin-bottom: 10px;
      @include media($middle){
        margin-bottom: 5px;
        text-align: left;
      }
      &_category{
        background: #708b60;
        color: #fff;
        font-size: 1.4rem;
        font-weight: bold;
        padding: 3px 15px;
        margin-right: 10px;
        @include media($middle){
          font-size: 1.1rem;
          padding: 2px 8px;
          margin-right: 5px;
        }
      }
      &_time{
        font-size: 1.4rem;
        margin-right: 10px;
        @include media($middle){
          font-size: 1.1rem;
          margin-right: 3px;
          display: block;
        }
      }
      &_staff{
        font-size: 1.3rem;
        font-weight: bold;
        background-color: #333333;
        color: #fff;
        display: inline-block;
        padding: 0 10px;
        margin-right: 10px;
      }
      &_staffname{
        font-size: 1.3rem;
        padding-bottom: 2px;
        text-decoration: underline;
      }
    }
    &_heading{
      font-size: 2rem;
      margin-bottom: 10px;
      font-weight: bold;
      @include media($middle){
        font-size: 1.2rem;
        font-weight: bold;
        text-align: left;
        line-height: 1.5;
      }
    }
    &_txt{
      margin-bottom: 0;
      font-size: 1.4rem;
      @include media($middle){
        display: none;
      }
    }
  }
}
