/*highneed takamori 20210330追加 LP*/
$font_en: 'Roboto', sans-serif;
$thouse-content: 1000px;

$t-mobile: new-breakpoint(max-width 767px 12);
$t-middle: new-breakpoint(max-width 1079px 12);
$t-large: new-breakpoint(min-width 1080px 12);

.header-thouse{
  img{
    border: none;
  }
  &-wrap{
    position: relative;
    z-index: 1;
    &-title{
      position: absolute;
      left: calc(35 * (100vw / 1200));
      top: calc(35 * (100vw / 1200));
      @include media($t-mobile){
        left: 10px;
        top: 15px;
      }
      h1{
        margin-bottom: 10px;
        @include media($t-mobile){
          margin-bottom: 5px;
        }
        @include media($mobile){
          margin-bottom: 0px;
        }
        img{
          width: 80%;
          max-width: 366px;
          @include media($mobile){
            width: 155px;
          }
        }
      }
      p{
        font-size: 1.8rem;
        color: #000;
        font-weight: bold;
        margin: 0px;
        line-height: 1.66;
        @include media($t-mobile){
          font-size: 1.4rem;
          line-height: 1.4;
        }
        @include media($mobile){
          display: none;
        }
      }
      .sp-content{
        display: none;
        @include media($mobile){
          display: block;
        }
        img{
          width: 180px;
        }
      }
    }
    &-img{
      img{
        width: 100%;
      }
    }
    &-tate{
      position: absolute;
      right: 10%;
      top: 0px;
      background-color: #fff;
      @include media($t-mobile){
        right: 30px;
      }
      h2{
        position: relative;
        padding: 58px 24px 60px;
        margin: 0px;
        width: 87px;
        text-align: center;
        @include media($t-middle){
          padding: 40px 26px 40px;
        }
        @include media($t-mobile){
          max-width: 50px;
          width: calc(35 * (100vw / 375));
          padding: 12px 10px;
        }
      }
    }
  }
}
.thouse-content{
  max-width: $thouse-content;
  width: 95%;
  margin: 0 auto;
  @include media($mobile){
    width: 90%;
  }
}
.thouse{
  .pc-br{
    @include media($mobile){
      display: none;
    }
  }

  img{
    border: none;
  }
  h1,h2,h3,h4{
    font-family: $font-gothic;
  }
  background-color: #ffffff;
  overflow: visible;
  padding-bottom: 0px;

  .sec01{
    background-color: #ffffff;
    &-wrap{
      margin-bottom: 35px;
      position: relative;
      background-color: #3e3a39;
      z-index: 100;
      top: -20px;
      padding: 30px 50px;
      display: flex;
      align-items: center;
      @include media($middle){
        padding: 30px;
      }
      @include media($mobile){
        flex-wrap: wrap;
        padding: 20px 15px 15px;
        top: -5px;
        width: 90%;
        margin: 0 auto 35px;
      }
      &:before{
        content: '';
        display: block;
        background-image: url('./assets/img/_thouse/sec01-before.png');
        width: 174.5px;
        height: 55.5px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -10px;
        top: -33px;
        @include media($mobile){
          width: 108px;
          height: 35px;
          top: -15px;
        }
      }
      &-title{
        width: 250px;
        @include media($mobile){
          width: 50%;
        }
        h2{
          margin: 0px;
          line-height: 1.6;
          font-size: 1.75rem;
          font-weight: bold;
          @include media($t-middle){
            font-size: calc(17.5 * (100vw / 1200));
          }
          @include media($mobile){
            font-size: calc(10 * (100vw / 375));
          }
          span{
            font-size: 2.3rem;
            @include media($t-middle){
              font-size: calc(23 * (100vw / 1200));
            }
            @include media($mobile){
              font-size: calc(13 * (100vw / 375));
            }
          }
        }
      }
      &-logo{
        width: 314px;
        @include media($mobile){
          width: 50%;
        }
      }
      &-desc{
        width: 336px;
        padding-left: 25px;
        @include media($mobile){
          width: 100%;
          padding-left: 0px;
          margin-top: 8px;
        }
        &-wrap{
          background-color: #ff0000;
          padding: 6px;
          h3{
            color: #fff;
            text-align: center;
            font-size: 19px;
            font-weight: bold;
            margin-bottom: 7px;
            @include media($t-middle){
              font-size: calc(19 * (100vw / 1200));
            }
            @include media($t-mobile){
              font-size: 1.1rem;
            }
            @include media($mobile){
              font-size: 1.4rem;
            }
          }
          p{
            margin: 0px;
            background-color: #fff;
            font-size: 2.0rem;
            font-weight: bold;
            font-family: $font_en;
            color: #000;
            text-align: center;
            line-height: 1;
            padding: 5px 0px;
            @include media($t-middle){
              font-size: calc(20 * (100vw / 1200));
            }
            @include media($t-mobile){
              font-size: 1.2rem;
            }
            @include media($mobile){
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    &-content{
      margin-bottom: 60px;
      h2{
        text-align: center;
        margin-bottom: 38px;
        img{
          width: 80%;
          max-width: 702px;
        }
      }
      p{
        color: #000;
        text-align: center;
        line-height: 1.875;
        font-weight: bold;
        margin: 0px;
      }
    }
    &-img{
      width: 100%;
      max-width: 2000px;
      margin: 0 auto;
      display: flex;
      &-col{
        width: 50%;
      }
    }
  }

  .sec02{
    background-color: #eee9e2;
    padding: 50px 0px;
    &-title{
      text-align: center;
      margin-bottom: 55px;
      h2{
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        @include media($mobile){
          display: block;
        }
        &:before{
          @include media($mobile){
            z-index: -1;
            position: absolute;
            height: 15px;
            background-color: #fff100;
            content: '';
            width: 100%;
            max-width: 259px;
            left: 50%;
            top: 38px;
            transform: translateX(-50%);
          }
        }
        &:after{
          z-index: -1;
          position: absolute;
          width: 688px;
          height: 15px;
          background-color: #fff100;
          content: '';
          bottom: -5px;
          left: calc(50% - 344px);
          @include media($t-middle){
            width: 648px;
            left: calc(50% - 324px);
          }
          @include media($t-mobile){
            width: 100%;
            max-width: 361px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .logo{
          width: 279px;
          display: inline-block;
          height: auto;
          @include media($t-middle){
            width: 259px;
          }
          @include media($mobile){
            width: 80%;
            max-width: 259px;
            display: block;
            margin: 0 auto 20px;
          }
        }
        .title{
          width: 381px;
          display: inline-block;
          margin-left: 18px;
          @include media($t-middle){
            width: 361px;
          }
          @include media($mobile){
            width: 100%;
            max-width: 361px;
            display: block;
            margin-left: 0px;
          }
        }
      }
    }
    &-wrap{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 125px;
      @include media($mobile){
        margin-bottom: 100px;
      }
      &-col{
        width: 313px;
        margin-right: 30px;
        @include media($t-middle){
          width: 32%;
          margin-right: 2%;
        }
        @include media($mobile){
          width: 90%;
          max-width: 400px;
          margin: 0 auto 30px;
        }
        &:last-child{
          margin-right: 0px;
          @include media($mobile){
            margin: 0 auto;
          }
        }
        &-title{
          background-color: #94c2d7;
          padding: 5px;
          position: relative;
          &:before{
            position: absolute;
            content: '';
            background-repeat: no-repeat;
            background-size: contain;
          }
          &__01{
            &:before{
              left: -10px;
              top: -7px;
              background-image: url('./assets/img/_thouse/sec02-title-before-01.png');
              width: 57.5px;
              height: 30px;
              @include media($t-middle){
                top: -10px;
              }
            }
          }
          &__02{
            &:before{
              top: -15px;
              left: -14px;
              background-image: url('./assets/img/_thouse/sec02-title-before-02.png');
              width: 108.5px;
              height: 57.5px;
              @include media($t-middle){
                top: -18px;
              }
            }
          }
          &__03{
            &:before{
              left: -10px;
              top: -12px;
              background-image: url('./assets/img/_thouse/sec02-title-before-03.png');
              width: 84px;
              height: 40px;
              @include media($t-middle){
                top: -15px;
              }
            }
          }
          h3{
            margin: 0px;
            color: #000;
            text-align: center;
            font-size: 2.5rem;
            font-weight: bold;
            border: 1px solid #000;
            padding: 12px 0px;
            line-height: 1.4;
            @include media($t-middle){
              font-size: 2.1rem;
            }
          }
        }
        &-img{
          margin-bottom: 20px;
        }
        p{
          font-size: 16px;
          line-height: 1.875;
          margin: 0px;
          color: #000;
        }
      }
    }

    &-plan{
      background-color: #fff;
      padding: 80px 50px;
      position: relative;
      @include media($t-middle){
        padding: 80px 35px;
      }
      @include media($mobile){
        padding: 80px 15px;
      }
      &-title{
        position: absolute;
        top: -50px;
        left: calc(50% - 116px);
        h3{
          background-color: #94c2d7;
          width: 232px;
          border: 5px solid #000;
          text-align: center;
          color: #000;
          padding: 15px;
          font-size: 1.6rem;
          font-weight: bold;
          @include media($mobile){
            border: 3px solid #000;
          }
          span{
            font-family: $font_en;
            font-size: 4.0rem;
            font-weight: bold;
            display: block;
            text-align: center;
          }
        }
      }
      &-desc{
        margin-bottom: 45px;
        @include media($middle){
          margin-bottom: 20px;
        }
        p{
          text-align: center;
          color: #000;
          font-size: 16px;
          line-height: 1.875;
          font-weight: bold;
          margin: 0px;
        }
      }

      &-wrap{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        &-border{
          border-bottom: 1px solid #000;
          margin-bottom: 35px;
        }
        &-img{
          width: 446px;
          @include media($t-middle){
            width: 48%;
          }
          @include media($mobile){
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            position: sticky;
            top: 0px;
          }
          &_01{
            img{
              width: 100%;
              max-width: 426px;
            }
          }
          &_02{
            img{
              width: 100%;
              max-width: 388px;
            }
          }
        }
        &-txt{
          width: 454px;
          @include media($t-middle){
            width: 52%;
            padding-left: 2%;
          }
          @include media($mobile){
            width: 100%;
            padding-left: 0px;
          }
          &-row{
            display: flex;
            flex-wrap: wrap;
            &-col{
              width: 217px;
              margin-right: 20px;
              margin-bottom: 10px;
              @include media($t-middle){
                width: 48%;
                margin-right: 2%;
              }
              &:nth-child(2n){
                margin-right: 0px;
              }
              h4{
                background-color: #313131;
                padding: 6px;
                font-size: 1.3rem;
                letter-spacing: 0;
                font-weight: bold;
                display: flex;
                align-items: center;
                margin-bottom: 9px;
                @include media($t-middle){
                  font-size: 1.3rem;
                }
                span{
                  display: inline-block;
                  font-family: $font_en;
                  font-size: 2.0rem;
                  font-weight: bold;
                  margin-right: 10px;
                  @include media($t-middle){
                    margin-right: 8px;
                  }
                }
              }
              p{
                color: #000;
                line-height: 1.42;
                font-size: 1.4rem;
                margin: 0px;
                @include media($t-middle){
                  font-size: 1.3rem;
                }

              }
            }
          }
        }
      }
      &-matome{
        width: 100%;
        max-width: 708px;
        background-color: #94c2d7;
        border: 5px solid #000;
        padding: 20px 0px;
        margin: 0 auto;
        @include media($mobile){
          padding: 20px;
          border: 3px solid #000;
        }
        h3{
          margin: 0px;
          font-size: 2.5rem;
          text-align: center;
          font-weight: bold;
          color: #000;
          @include media($mobile){
            font-size: 1.6rem;
          }
        }
      }
      &_sec{
        &_container{}
        &_head{
          display: table;
          width: 100%;
          margin-bottom: 30px;
          @include media($middle){
            margin-bottom: 20px;
            display: block;
          }
          &_heading{
            font-family: "Roboto", sans-serif;
            color: #000000;
            font-size: 4rem;
            font-weight: 900;
            width: 115px;
            margin: 0;
            display: table-cell;
            vertical-align: middle;
            @include media($middle){
              display: block;
              width: auto;
              font-size: 2.4rem;
              padding: 5px;
            }
            &--blue{
              background: #94c2d7;
              text-align: center;
            }
            &--brown{
              background: #e8c589;
              text-align: center;
            }
          }
          &_txt{
            color: #000000;
            line-height: 1.6;
            background: #eee9e2;
            margin: 0;
            padding: 10px 20px;
            display: table-cell;
            vertical-align: middle;
            @include media($middle){
              display: block;
              padding: 10px 15px;
            }
          }
        }
        &_madori{
          text-align: center;
          margin-bottom: 45px;
          @include media($middle){
            margin-bottom: 20px;
          }
        }
        &_detail{
          color: #000000;
          @include clearfix;
          margin-bottom: 30px;
          &_item{
            @include media($large){
              width: 286px;
              float: left;
              @include omega(3n);
              margin-right: 21px;
              margin-bottom: 30px;
            }
            @include media($middle){
              @include span-columns(6);
              @include omega(2n);
              margin-bottom: 25px;
            }
            @include media($mobile){
              width: 100%;
              margin-bottom: 20px;
            }
            &_heading{
              font-size: 1.4rem;
              font-weight: bold;
              margin: 0;
              line-height: 1.7;
              span{
                font-family: "Roboto", sans-serif;
                color: #000000;
                font-size: 2rem;
                font-weight: 900;
                position: relative;
                bottom: 2px;
                margin-right: 10px;
                position: relative;
                top: 1.5px;
                line-height: 1;
              }
              &--blue{
                background: #94c2d7;
                padding: 5px 10px;
              }
              &--brown{
                background: #e8c589;
                padding: 5px 10px;
              }
            }
            &_thumb{
              text-align: center;
              margin-bottom: 15px;
            }
            &_txt{
              font-size: 1.4rem;
              margin: 0;
              line-height: 1.6;
            }
          }
        }
      }
    }
  }

  .sec03{
    background-color: #eee9e2;
    padding-bottom: 50px;
    &-wrap{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      &-price{
        width: 324px;
        margin-right: 53px;
        @include media($t-middle){
          width: 32%;
          margin-right: 4%;
        }
        @include media($t-mobile){
          width: 100%;
          max-width: 324px;
          margin: 0 auto 20px;
        }
        h2{
          margin-bottom: 38px;
          background-color: #c30d23;
          color: #fff;
          font-size: 3.0rem;
          text-align: center;
          font-weight: bold;
          padding: 20px 0px;
          position: relative;
          @include media($t-mobile){
            font-size: 2.2rem;
          }
          &:after{
            content: '';
            display: block;
            background-image: url('./assets/img/_thouse/icon-arrow.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 38px;
            height: 31px;
            position: absolute;
            left: calc(50% - 19px);
            bottom: -25px;
          }
        }
        p{
          text-align: center;
          img{
            max-width: 312px;
            width: 75%;
          }
        }
      }
      &-sim{
        width: 613px;
        background-color: #fff;
        @include media($t-middle){
          width: 64%;
        }
        @include media($t-mobile){
          width: 100%;
          max-width: 613px;
          margin: 0 auto;
        }
        h3{
          margin-bottom: 15px;
          background-color: #c30d23;
          text-align: center;
          font-size: 3.0rem;
          padding: 20px 0px;
          font-weight: bold;
          @include media($t-mobile){
            font-size: 2.2rem;
          }
        }
        &-desc{
          color: #000;
          font-size: 1.6rem;
          text-align: center;
          font-weight: bold;
          @include media($t-mobile){
            font-size: 1.2rem;
          }
        }
        &-row{
          display: flex;
          flex-wrap: wrap;
          padding: 0px 28px;
          align-items: center;
          @include media($t-middle){
            padding: 0px 15px;
            margin-bottom: 15px;
          }
          &-title{
            width: 106px;
            margin-right: 8px;
            @include media($t-middle){
              width: 20%;
              margin-right: 0px;
            }
            p{
              font-size: 1.8rem;
              font-weight: bold;
              color: #000;
              letter-spacing: 0.06em;
              margin-bottom: 0px;
              line-height: 1.4;
              @include media($t-middle){
                font-size: 1.6rem;
                text-align: center;
              }
              @include media($mobile){
                font-size: 1.1rem;
              }
            }
            span{
              display: block;
              background-image: url('./assets/img/_thouse/sec03-bg.png');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              text-align: center;
              padding: 0px 0px;
              font-size: 2.2rem;
              font-weight: bold;
              @include media($t-middle){
                font-size: 1.5rem;
              }

            }
          }
          &-price{
            width: 293px;
            margin-right: 10px;
            @include media($t-middle){
              width: 58%;
              padding-left: 8px;
              margin-right: 0px;
              margin-right: 2%
            }
            @include media($t-mobile){
              width: 48%;
              margin-right: 2%
            }
            p{
              margin: 0px;
              text-align: center;
            }
            img{
              width: 100%;
              max-width: 293px;
            }
          }
          &-bonus{
            width: 140px;
            @include media($t-middle){
              width: 20%;
            }
            @include media($t-mobile){
              width: 30%;
            }
            p{
              border: 2px solid #000;
              color: #000;
              font-weight: bold;
              text-align: center;
              padding: 5px 0px;
              margin: 0px;
              line-height: 1.5;
              font-size: 1.8rem;
              @include media($t-middle){
                font-size: 1.3rem;
              }
            }
          }
        }
      }
    }
    &-bikou{
      background-color: #fff;
      padding: 24px 30px;
      h3{
        font-size: 1.6rem;
        color: #000;
        font-weight: bold;
        position: relative;
        &:before{
          content: '';
          width: 852px;
          height: 2px;
          background-color: #000;
          display: block;
          position: absolute;
          right: 0px;
          top: 8px;
          @include media($t-middle){
            width: calc(100% - 80px);
          }
        }
      }
      p{
        font-size: 1.2rem;
        color: #000;
        margin: 0px;
      }
    }
  }
  .sec04{
    padding-top: 63px;
    padding-bottom: 80px;
    background-color: #fff;
    &-wrap{
      display: flex;
      flex-wrap: wrap;
      &-col{
        width: 334px;
        @include media($t-middle){
          width: 34%;
        }
        @include media($mobile){
          width: 100%;
          max-width: 344px;
          margin: 0 auto 30px;
        }
        &-title{
          background-color: #94c2d7;
          padding: 5px;
          h2{
            border: 3px solid #000;
            text-align: center;
            margin: 0px;
            padding: 10px 0px;
            color: #000;
            font-size: 2.0rem;
            font-weight: bold;
            @include media($t-middle){
              font-size: 1.8rem;
            }
          }
        }
        &-adress{
          font-size: 3.0rem;
          text-align: center;
          color: #000;
          font-weight: bold;
          line-height: 1;
          margin-top: 20px;
          margin-bottom: 20px;
          @include media($t-middle){
            font-size: 2.7rem;
          }
        }
        ul{
          margin-top: 6px;
          margin-bottom: 10px;
          line-height: 1.6;
          li{
            color: #000;
            font-size: 1.8rem;
            font-weight: bold;
            @include media($t-middle){
              font-size: 1.6rem;
            }
            &:before{
              content: '・';

            }
          }
        }
        a{
          position: relative;
          display: inline-block;
          background-color: #626262;
          border-radius: 10px;
          padding: 2px 40px 2px 12px;
          font-size: 1.4rem;
          font-weight: bold;
          &:after{
            position: absolute;
            display: block;
            content: '';
            width: 19px;
            height: 19px;
            background-image: url('./assets/img/_thouse/icon-map.png');
            background-repeat: no-repeat;
            background-size: contain;
            right: 12px;
            top: 5px;
          }
        }
      }
      &-map{
        width: 613px;
        margin-left: 45px;
        @include media($t-middle){
          width: 62%;
          margin-left: 4%;
        }
        @include media($mobile){
          width: 100%;
          margin-left: 0px;
        }
        &-google{
          height: 357px;
          width: 100%;
          @include media($mobile){
            height: 250px;
          }
        }
      }
    }
  }
  .sec05{
    background-color: #eee9e2;
    padding: 80px 0px 70px;
    margin: 0px;
    @include media($t-middle){
      padding: 35px 0px;
    }
    &-containar{
      background-color: #fff;
      padding: 60px 100px;
      @include media($t-middle){
        padding: 50px 40px;
      }
      @include media($mobile){
        padding: 50px 20px;
      }
    }
    &-title{
      margin-bottom: 35px;
      h2{
        text-align: center;
        color: #000;
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 20px;
        span{
          font-size: 4.0rem;
          font-family: $font_en;
          display: block;
        }
      }
      p{
        font-size: 1.6rem;
        text-align: center;
        color: #000;
        margin: 0px;
      }
    }
    &-tel{
      background-color: #c30d23;
      padding: 5px;
      width: 100%;
      max-width: 800px;
      margin: 0 auto 70px;
      @include media($mobile){
        margin-bottom: 50px;
      }
      &-wrap{
        border: 3px solid #fff;
        padding: 25px 0px;
        p{
          text-align: center;
          background-color: #fff;
          color: #c30d23;
          font-weight: bold;
          width: 415px;
          margin: 0 auto 15px;
          @include media($mobile){
            width: 90%;
            max-width: 415px;
          }
        }
        a{
          display: block;
          position: relative;
          font-size: 5rem;
          text-align: center;
          font-family: $font_en;
          font-weight: bold;
          width: 415px;
          margin: 0 auto;
          letter-spacing: 0.05em;
          padding-left: 50px;
          line-height: 1;
          @include media($mobile){
            width: 100%;
            padding: 0px 10px;
            font-size: 2.3rem;
          }
          &:before{
            content: '';
            background-image: url('./assets/img/_thouse/contact-tel.png');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            left: 10px;
            top:8px;
            width: 35px;
            height: 35px;
            @include media($mobile){
              display: inline-block;
              position: relative;
              width: 20px;
              height: 20px;
              left: -5px;
              top:3px;
            }
          }
        }
      }
    }
    &-contact{
      &-title{
        margin-bottom: 40px;
        h3{
          color: #000;
          text-align: center;
          font-size: 3rem;
          font-weight: bold;
          margin-bottom: 15px;
          line-height: 1;
          @include media($mobile){
            font-size: 2rem;
          }
        }
        p{
          text-align: center;
          color: #000;
          font-size: 1.6rem;
          margin: 0px;
          @include media($mobile){
            font-size: 1.3rem;
          }
        }
      }
      &-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 35px;

        dt{
          width: 207px;
          color: #000;
          font-weight: normal;
          border-top: 1px solid #dcdcdc;
          padding: 10px 0px;
          @include media($t-middle){
            width: 25%;
          }
          @include media($mobile){
            width: 100%;
          }
          span{
            background-color: #c30d23;
            color: #fff;
            font-size: 1.2rem;
            font-weight:bold;
            font-weight: normal;
            padding: 2px 4px;
            display: inline-block;
            line-height: 1;
            margin-left: 5px;
          }
        }
        dd{
          width: 593px;
          padding-left: 30px;
          @include media($t-middle){
            width: 75%;
          }
          @include media($mobile){
            width: 100%;
            padding-left: 0px;
            max-width: 500px;
          }
        }

        input{
          padding: 5px 10px;
          border: 1px solid #a0a0a0;
          border-radius: 5px;
        }

        textarea{
          border: 1px solid #a0a0a0;
        }
      }
      &-button{
        max-width: 280px;
        margin: 0 auto;
        width: 80%;
        .wpcf7-submit{
          padding: 27px 0px;
          width: 100%;
          background-color: #535353;
          color: #fff;
          text-align: center;
          border-radius: 0px;
          box-shadow: 2px 2px 0 0 #535353;
          border-right: 5px solid #fff;
          border-bottom: 5px solid #fff;
          transition: all 200ms ease-in-out;
          @include media($mobile){
            padding: 20px 0px;
          }
          &:hover{
            opacity: 1;
            box-shadow: none;
            transform:translate(5px,5px);
          }
        }
      }
    }
    div.wpcf7-validation-errors, div.wpcf7-acceptance-missing{
      color: #000;
    }
    div.wpcf7-mail-sent-ok{
      color: #000;
    }

  }
}


.thouse-footer{
  img{
    border: none;
  }
  background-color: #3e3a39;
  padding: 30px 0 15px;
  &-wrap{
    position: relative;
    margin-bottom: 10px;
    @include media($t-mobile){
      margin-bottom: 30px;
    }
    &-logo{
      @include media($t-mobile){
        margin-bottom: 20px;
      }
      img{
        width: 147px;
      }
      p{
        color: #fff;
        margin: 0px;
      }
    }
    &-button{
      position: absolute;
      right: 0px;
      top: 0px;
      width: 280px;
      @include media($t-mobile){
        position: inherit;
      }
      a{
        display: flex;
        align-items: center;
        position: relative;
        background-color: #58514e;
        border: 1px solid #fff;
        padding: 18px 0px;
        padding-left: 25px;
        img{
          width: 79px;
          display: inline-block;
          margin-right: 5px;
        }
        &:after{
          content: '';
          display: block;
          background-image: url('./assets/img/_thouse/footer-icon-arrow.png');
          background-size: contain;
          background-repeat: no-repeat;
          width: 10px;
          height: 16px;
          margin-left: 10px;
        }
      }
    }
  }
  .footer-fixed{
    img{
      border: none;
    }
    width: 132px;
    position: fixed;
    right: 0px;
    bottom: 10%;
    background-color: #e0110e;
    z-index: 100;
    @include media($t-middle){
      width: 110px;
    }
    @include media($mobile){
      width: 60px;
    }
    a{
      display: block;
      padding: 18px 0px;
      text-align: center;
      @include media($t-middle){
        padding: 14px 12px;
      }
      img{
        width: 100%;
        max-width: 100px;
      }
    }
  }
}

.thouse_reservation{
  background-color: #eee9e2;
  color: #000;
  padding: 60px 0 0;
  @include media($t-middle){
    padding: 45px 15px 0;
  }
  &_container{
    @include outer-container(1000px);
    background: #fff;
    padding: 100px;
    @include media($t-middle){
      padding: 30px 15px;
    }
  }
  &_heading{
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    @include media($t-middle){
      font-size: 2.2rem;
    }
  }
  &_name{
    text-align: center;
    &.active{
      display: none;
    }
  }
  &_url{
    text-align: center;
  }
  .monthly-calendar{
    caption{
      font-weight: bold;
      font-size: 2rem;
      margin-bottom: 10px;
      @include media($t-middle){
        font-size: 1.6rem;
      }
    }
  }
  a{
    color: #327bff;
    font-weight: bold;
    text-decoration: underline;
  }
  .booking-timelink{
    font-size: 1.8rem;
  }
  .calendar-daylink{
    font-size: 1.8rem;
  }
  .day-calendar{
    h3{
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
    }
    p{
      text-align: center;
    }
  }
  .day-calendar table{
    margin: 10px auto;
  }
}

.thouse_event{
  background-color: #eee9e2;
  color: #000;
  padding: 90px 0 0;
  @include media($t-middle){
    padding: 45px 15px 0;
  }
  &_container{
    @include outer-container(1000px);
  }
  &_heading{
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 45px;
    @include media($t-middle){
      font-size: 2.2rem;
      margin-bottom: 30px;
    }
  }
  .thouse-archive{
    &_item{
      background: #fff;
      color: #000000;
      margin-bottom: 15px;
      padding: 20px;
      @include media($t-middle){
        padding: 15px;
        margin-bottom: 10px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      a{
        display: flex;
        align-content: center;
        color: #000000;
        justify-content: space-between;
        align-items: center;
        @include media($t-middle){
          display: block;
        }
      }
      &_thumb{
        text-align: center;
        line-height: 1;
        margin: 0;
        width: 380px;
        @include media($t-middle){
          width: auto;
          text-align: center;
        }
      }
      &_contents{
        width: 100%;
        padding-left: 20px;
        @include media($t-middle){
          padding: 10px;
        }
      }
      &_info{
        font-family: $font-helvetica;
        margin-bottom: 5px;
        font-weight: 500;
        &_category{
          color: #fff;
          font-weight: bold;
          font-size: 1.2rem;
          background: #ccc;
          padding: 2px 10px;
          margin-right: 5px;
          @include media($middle){
            padding: 2px 5px;
            font-size: 1.1rem;
          }
          &--cat_news{
            background: #00479d;
          }
          &--cat_event{
            background: #eb6100;
          }
        }
        &_time{
          font-size: 1.4rem;
          font-weight: bold;
          @include media($middle){
            font-size: 1.2rem;
          }
        }
      }
      &_heading{
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 10px;
        @include media($middle){
          font-size: 1.3rem;
          line-height: 1.3;
        }
      }
      &_content{
        font-size: 1.4rem;
        color: #9a9390;
        line-height: 1.8;
        margin-bottom: 0;
        @include media($middle){
          font-size: 1.1rem;
          line-height: 1.5;
        }
      }
      &_event-info{
        width: 100%;
        color: #9a9390;
        font-size: 1.2rem;
        @include media($middle){
          font-size: 1.1rem;
        }
        &_item{
          display: table;
          margin-bottom: 5px;
          width: 100%;
          @include media($middle){
            margin-bottom: 2px;
          }
          &:last-child{
            margin-bottom: 0;
          }
          dt{
            display: table-cell;
            width: 60px;
            font-weight: normal;
            text-align: center;
            border: 1px solid #9a9390;
            line-height: 1;
            vertical-align: middle;
            @include media($middle){
              width: 40px;
            }
          }
          dd{
            display: table-cell;
            padding-left: 10px;
            width: auto;
            word-break:break-all;
            @include media($middle){
              padding-left: 5px;
            }
          }
        }
      }
      &_detail{
        text-align: center;
        margin: 10px 0 0 0;
        font-size: 1.4rem;
        @include media($middle){
          margin: 5px 0 0 0;
          font-size: 1.2rem;
        }
        &:after{
          font-family: FontAwesome;
          content: "\f105";
          font-size: 1.8rem;
          margin-left: 10px;
        }
      }
    }
  }
}
