.archive{

  &_wrap{
    background: #efebe2;
    padding-top: 50px;
    padding-bottom: 90px;
    @include media($middle){
      padding-top: 30px;
      padding-bottom: 45px;
    }
  }

  &_container{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
  }

}

.post-archive{
  @include clearfix;
  margin-bottom: 45px;
  @include media($middle){
    margin-bottom: 30px;
  }
  &_item{
    position: relative;
    background: #fff;
    color: #000000;
    margin-bottom: 2%;
    @include media($large){
      @include omega(3n);
      @include span-columns(4);
    }
    @include media($middle){
      @include omega(2n);
      @include span-columns(6);
    }
    .top_event_archive_item_tag{
      position: absolute;
      left: 0;
      top: 0;
    }
    a{
      display: block;
      color: #000000;
    }
    &_thumb{
      text-align: center;
      line-height: 1;
      margin: 0;
    }
    &_contents{
      max-height: auto;
      padding: 20px 30px;
      @include media($middle){
        padding: 10px;
      }
    }
    &_category{
      margin-top: -17px;
      margin-bottom: 10px;
      &_item{
        display: inline-block;
        color: #fff;
        font-weight: bold;
        font-size: 1.2rem;
        background: #666;
        padding: 0 15px;
        margin-right: 0;
        @include media($middle){
          padding: 0 5px;
          font-size: 1.1rem;
        }
        &--cat_news{
          background: #7195d1;
        }
        &--cat_event{
          background: #e6886b;
        }
      }
    }
    &_heading{
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 0;
      @include media($middle){
        font-size: 1.3rem;
        line-height: 1.3;
      }
    }
    &_time{
      font-size: 1.2rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0;
      margin-top: 10px;
      @include media($middle){
        font-size: 1.2rem;
      }
    }
    &_content{
      font-size: 1.4rem;
      color: #333;
      line-height: 1.8;
      margin-bottom: 0;
      margin-top: 15px;
      @include media($middle){
        font-size: 1.1rem;
        line-height: 1.5;
        margin-top: 5px;
      }
    }
    &_event-info{
      width: 100%;
      font-size: 1.2rem;
      margin-top: 15px;
      @include media($middle){
        font-size: 1.1rem;
        margin-top: 10px;
      }
      &_item{
        display: table;
        margin-bottom: 5px;
        width: 100%;
        @include media($middle){
          margin-bottom: 2px;
        }
        &:last-child{
          margin-bottom: 0;
        }
        dt{
          display: table-cell;
          width: 60px;
          font-weight: normal;
          text-align: center;
          border: 1px solid #969696;
          line-height: 1;
          vertical-align: middle;
          @include media($middle){
            width: 34px;
          }
        }
        dd{
          display: table-cell;
          padding-left: 10px;
          width: auto;
          word-break:break-all;
          @include media($middle){
            padding-left: 5px;
            line-height: 1.5;
          }
        }
      }
    }
    &_detail{
      text-align: center;
      margin: 10px 0 0 0;
      font-size: 1.4rem;
      @include media($middle){
        margin: 5px 0 0 0;
        font-size: 1.2rem;
      }
      &:after{
        font-family: FontAwesome;
        content: "\f105";
        font-size: 1.8rem;
        margin-left: 10px;
      }
    }
  }
}
