//font-family
$font-gothic: YuGothic,'Yu Gothic','Yu Gothic UI','メイリオ', Meiryo,'Meiryo UI','ＭＳ Ｐゴシック','MS PGothic',sans-serif;
$font-noto: 'Noto Sans JP',sans-serif;
$font-mincho: 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN','ヒラギノ明朝 ProN','Hiragino Mincho ProN','ＭＳ Ｐ明朝','MS PMincho',sans-serif;
$font-helvetica: futura-pt, sans-serif;
$font-mix: futura-pt,'Noto Sans JP',YuGothic,'Yu Gothic','Yu Gothic UI','メイリオ', Meiryo,'Meiryo UI','ＭＳ Ｐゴシック','MS PGothic',sans-serif;

// Breakpoints
$medium-screen: 600px;
$large-screen: 900px;

// Typography
$base-font-family: $font-gothic;
$heading-font-family: $font-noto;

// Line height
$base-line-height: 1.6;
$heading-line-height: 1.4;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #0d97e2;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

$site-color: #4e4743;

// Font Colors
$base-font-color: #333333;
$action-color: #333333;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Focus
$focus-outline-color: transparentize($action-color, 0.4);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;
// Animations
$base-duration: 150ms;
$base-timing: ease;
