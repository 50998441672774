.office-detail{
  padding-bottom: 0;

  &_top{
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 15px;
    background-color: #f1ece2;
    @include media($middle){
      padding-top: 30px;
      margin-top: 30px;
    }
  }

  &_menu{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
    &_item{
      background: #ffffff;
      @include media($large){
        width: 216px;
        float: left;
      }
      @include media($middle){
        @include span-columns(6);
        @include omega(2n);
        margin-bottom: 2%;
      }
      &_thumb{
        text-align: center;
      }
      &_name{
        position: relative;
        padding: 25px 20px;
        font-family: $font-noto;
        font-weight: 600;
        font-size: 1.4rem;
        border-left: 1px solid #dedee0;
        border-bottom: 1px solid #dedee0;
        margin-bottom: 0;
        line-height: 1.5;
        @include media($middle){
          padding: 20px 10px;
          font-size: 1.2rem;
        }
        &--line-02{
          padding: 14.5px 20px;
          @include media($middle){
            padding: 11px 10px;
          }
        }
        &:after{
          content: "";
          display: inline-block;
          width: 16px;
          height: 9px;
          background: url(./assets/img/office_under.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          margin: auto;
          @include media($middle){
            width: 12.5px;
            height: 7px;
            right: 10px;
          }
        }
      }
      &:nth-of-type(5) .office-detail_menu_item_name{
        border-right: 1px solid #dedee0;
        position: relative;
        &::before{
          content:"";
          display: inline-block;
          width: 16px;
          height: 15.5px;
          background: url('./assets/img/office_muji_link.png') no-repeat center;
          background-size: cover;
          position: absolute;
          bottom: 16px;
          left: 70px;
          @include media($middle){
            width: 12px;
            height: 11.5px;
            left: 50px;
            bottom: 13px;
          };
        }
      }
    }
  }

  &_sec{
    background-color: #f1ece2;
    padding: 100px 0;
    @include media($middle){
      padding: 45px 0;
    }
    &--light{
      background: #faf5ec;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_heading{
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 50px;
      @include media($middle){
        font-size: 1.8rem;
        margin-bottom: 20px;
        line-height: 1.4;
      }
      &:before{
        content: "";
        display: inline-block;
        width: 11px;
        height: 15px;
        background: url(./assets/img/icon_triangle_right.png) no-repeat center center;
        background-size: cover;
        margin-right: 15px;
        @include media($middle){
          width: 8px;
          height: 13px;
          margin-right: 10px;
        }
      }
    }
    &_main-image{
      text-align: center;
    }
    &_desc{
      padding: 30px 50px;
      margin-bottom: 0;
      @include media($middle){
        padding: 15px 0;
        font-size: 1.2rem;
      }
    }
    &_photo{
      @include clearfix;
      margin-bottom: 20px;
      &_item{
        width: 520px;
        float: left;
        margin-right: 40px;
        @include omega(2n);
        @include media($middle){
          @include span-columns(6);
          @include omega(2n);
        }
        &_thumb{
          text-align: center;
          figcaption{
            display: block;
            padding: 20px;
            text-align: left;
            @include media($middle){
              padding: 5px;
              font-size: 1.1rem;
              line-height: 1.4;
            }
          }
        }
      }
    }
    &_col-02{
      @include clearfix;
      &_left{
        width: 520px;
        float: left;
        @include media($middle){
          width: auto;
          float: none;
        }
      }
      &_right{
        width: 520px;
        float: right;
        @include media($middle){
          width: auto;
          float: none;
        }
      }
    }
    &_table{
      border: 1px solid #333333;
      background: #fff;
      color: #000;
      width: 100%;
      font-size: 1.4rem;
      font-family: $font-noto;
      margin-bottom: 20px;
      margin-top: 0;
      @include media($middle){
        font-size: 1.2rem;
      }
      tr{
        th{
          width: 120px;
          color: #4e4743;
          font-weight: 500;
          padding: 15px 10px 15px 40px;
          border-bottom: 1px solid #4e4743;
          @include media($middle){
            width: 60px;
            padding: 10px;
          }
        }
        td{
          border-bottom: 1px solid #4e4743;
          padding: 15px 10px 15px 10px;
          @include media($middle){
            width: auto;
            padding: 10px 15px;
          }
        }
      }
      p{
        line-height: 1.3;
        margin-bottom: 10px;
        img{
          vertical-align: middle;
        }
      }
    }
    &_btn{
      display: inline-block;
      position: relative;
      @include media($middle){

        margin: 0 auto 20px;
      }
      &::after{
        content: "";
        display: inline-block;
        width: 31px;
        height: 12px;
        background: url('./assets/img/btn_right.png') no-repeat center;
        background-size: cover;
        position: absolute;
        top: 43px;
        right: 40px;
        transition: 0.3s;
        @include media($middle){
          top: 28px;
          right: 30px;
        };
      }
      &:hover{
        &::after{
          transform: translateX(10px);
        }
      }
    }
    &_map{
      iframe{
        width: 100%;
        height: 320px;
        @include media($middle){
          height: 280px;
        }
      }
    }
    &_note{
      font-size: 1.4rem;
      margin-bottom: 20px;
      @include media($middle){
        font-size: 1.2rem;
        line-height: 1.6;
      }
    }
    &_bnr{
      margin-top: 30px;
      @include media($middle){
        margin-top: 20px;
      }
    }
  }

}
