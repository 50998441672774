html{
}
body{
  overflow-x: hidden;
  font-size: 1.6rem;
  line-height: 2.1;
  letter-spacing: 0.05em;
  @include media($middle){
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 1.8;
  }
  &.home{
  }
}
@media all and (-ms-high-contrast:none) {
  /*
	body {
		font-family: "メイリオ",Meiryo,"游ゴシック",YuGothic,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","MS PGothic",sans-serif;
	}
  .inc-contact_col-01 a::after {
    display: none !important;
  }
  .inc-contact_btns_item a::after {
    display: none !important;
  }
  .office_list_item_link a::after{
    display: none !important;
  }
  .orderhouse_reference_col-02_item_contents_link a::after{
    display: none !important;
  }
  .top_works_slider_item_contents::after{
     display: none !important;
  }
  .works-archive-list_item_contents::after {
    display: none !important;
  }
  */
}

/* -------------------- WordPress -------------------- */
.aligncenter{
  display: block;
  margin: 0 auto;
}
.alignright{
  float: right;
}
.alignleft{
  float: left;
}

a{
  &:hover{
    opacity: 1;
  }
}

.pc-none{
	@include media($large){
		display: none;
	}
}
.sp-none{
	@include media($middle){
		display: none !important;
	}
}
.home .main{
  width: 100%;
  background-color: #fff !important;
  /*
  background: url(./assets/img/main_bg.png) no-repeat left top;
  background-size: 100% auto;
  */
  //background: url(./assets/img/top_bg_v2.png) repeat-y center top 800px;
  background-size: 100% auto;
}
.main{
  background-color: #ffffff;
  z-index: 4;
  position: relative;
  overflow-x: hidden;
}

.pagination{
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 45px;
  @include media($middle){
    font-size: 1.6rem;
    margin-bottom: 30px;
  }
  a,span,i{
    display: inline-block;
    margin: 0 5px;
    padding: 5px;
    @include media($middle){
      margin: 0 3px;
    }
  }
  span{
    color: #babeac;
  }
  i{
    display: inline-block;
    background: #babeac;
    padding: 10px 15px;
    color: #fff;
  }
  .screen-reader-text{
    display: none;
  }
  .next,.prev{
    display: inline-block;
  }
  a{
    text-decoration: underline;
    color: #333333;
  }
}

.heading-ptn-01{
  font-size: 5.6rem;
  font-family: $font-helvetica;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  font-weight: 500;
  @include media($middle){
    font-size: 3.4rem;
  };
  span{
    font-size: 3rem;
    letter-spacing: 0.05em;
    display: inline-block;
    vertical-align: top;
    margin-right: 18px;
    @include media($middle){
      font-size: 2rem;
      margin-right: 4px;
    };
  }
}

.heading-ptn-02{
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: 0.05em;
  line-height: 1.6;
  @include media($middle){
    font-size: 1.6rem;
    letter-spacing: 0;
  }
  &--center{
    text-align: center;
  }
  &--large{
    font-size: 3.4rem;
    @include media($middle){
      font-size: 2rem;
    }
  }
  &--mb{
    margin-bottom: 45px;
    @include media($middle){
      margin-bottom: 25px;
    }
  }
  span{
    position: relative;
    display: inline-block;
    &::before{
      content:"";
      display: inline-block;
      width: 28px;
      height: 28px;
      background: url('./assets/img/heading-ptn-02_before.png') no-repeat center;
      background-size: cover;
      position: absolute;
      top: -10px;
      left: -29px;
      @include media($middle){
        width: 20px;
        height: 20px;
        top: -5px;
        left: -15px;
      };
    }
    &::after{
      content:"";
      display: inline-block;
      width: 28px;
      height: 28px;
      background: url('./assets/img/heading-ptn-02_after.png') no-repeat center;
      background-size: cover;
      position: absolute;
      bottom: -10px;
      right: -26px;
      @include media($middle){
        width: 20px;
        height: 20px;
        bottom: -8px;
        right: -15px;
      };
    }
  }
  img{
    display: inline-block;
    margin: 0 15px;
    position: relative;
    bottom: 2px;
    @include media($middle){
      margin: 0 5px;
      bottom: 0;
    }
  }
}

.heading-ptn-03{
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
  line-height: 1.7;
  @include media($middle){
    font-size: 1.6rem;
    margin-bottom: 0;
    line-height: 1.3;
  }
}

.heading-ptn-04{
  @include outer-container(780px);
  background-color: #969b7d;
  color: #ffffff;
  text-align: center;
  font-size: 2.6rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding: 20px 0;
  margin-bottom: 75px;
  position: relative;
  @include media($middle){
    margin: 0 0 30px;
    padding: 15px 0;
    width: 100%;
    line-height: 1.3;
    font-size: 1.8rem;
  }
  &--up{
    position: relative;
    bottom: 60px;
    margin-bottom: 15px;
    @include media($middle){
      bottom: 30px;
      margin-bottom: 0;
    }
  }
  &::before{
    content: "";
    display: inline-block;
    width: 28px;
    height: 25px;
    background: url('./assets/img/performance_header_under.png') no-repeat center;
    background-size: cover;
    position: absolute;
    bottom: -20px;
    left: 48%;
    @include media($middle){
      bottom: -20px;
      left: 45%;
    }
  }
  span{
    font-size: 3.6rem;
    @include media($middle){
      font-size: 2rem;
    }
  }
  &--middle{
    padding: 30px 0;
    @include media($middle){
      padding: 15px 0;
    }
    span{
      font-size: 3.2rem;
      @include media($middle){
        font-size: 2rem;
      }
    }
  }
  &--large{
    span{
      font-size: 4rem;
      @include media($middle){
        font-size: 2.2rem;
      }
    }
  }
}

.btn-ptn-01{
  display: inline-block;
  width: 190px;
  height: 50px;
  font-weight: bold;
  color: #ffffff;
  background-color: #333333;
  padding: 10px 21px;
  position: relative;
  text-align: left;
  line-height: 1.7;
  @include media($middle){
    font-size: 1.2rem;
    width: 130px;
    height: 35px;
    padding: 8px 10px;
  }
  &:hover::before{
    transform: translateX(10px);
    @include media($middle){
      transform: translateX(5px);
    }
  }
  &::before{
    content:"";
    display: inline-block;
    width: 31px;
    height: 12px;
    background: url('./assets/img/btn_right.png') no-repeat center;
    background-size: cover;
    position: absolute;
    right: 0px;
    top: 19px;
    transition: 0.3s;
    z-index: 1;
    @include media($middle){
      width: 21.7px;
      height: 8.4px;
      right: 0;
      top: 14px;
    }
  }
  &::after{
    content:"";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 0 0 50px;
    border-color: transparent transparent transparent #333333;
    position: absolute;
    bottom: 0;
    right: -50px;
    @include media($middle){
      border-width: 35px 0 0 15px;
      right: -15px;
    }
  }
  &--mb{
    margin-bottom: 15px;
  }
  &--long{
    width: 266px;
    display: inline-block;
    height: 50px;
    font-weight: bold;
    color: #ffffff;
    background-color: #333333;
    padding: 10px 21px;
    position: relative;
    @include media($middle){
      width: 180px;
      height: 35px;
      padding: 8px 10px;
    }
  }
  &--w300{
    width: 300px;
    @include media($middle){
      width: 260px;
    }
  }
  &--w370{
    width: 370px;
    @include media($middle){
      width: 260px;
    }
  }
  &--w400{
    width: 400px;
    @include media($middle){
      width: 260px;
    }
  }
  &--w418{
    width: 418px;
    @include media($middle){
      width: 260px;
    }
  }
  &--center{
    display: block;
    margin: 0 auto;
  }
  img{
    vertical-align: middle;
    position: relative;
    bottom: 2px;
    margin-left: 10px;
    @include media($middle){
      bottom: 2px;
      margin-left: 5px;
    }
  }
}

.btn-ptn-02{
  position: relative;
  @include media($middle){
    text-align: center;
  }
  i{
    vertical-align: middle;
    display: inline-block;
    margin-left: 10px;
    line-height: 1;
  }
  &--center{
    text-align: center;
  }
  &--w-360{
    a{
      width: 360px;
      @include media($middle){
        width: auto;
      }
    }
  }
  &--w-220{
    a{
      width: 220px;
      @include media($middle){
        width: auto;
      }
    }
  }
  &--w-285{
    a{
      width: 285px;
      @include media($middle){
        width: auto;
      }
    }
  }
  a{
    transition: all 200ms ease-in-out;
    z-index: 2;
    display: inline-block;
    position: relative;
    background: #4e4743;
    color: #fff;
    padding: 15px;
    border: 1px solid #ffffff;
    overflow: hidden;
    font-weight: 600;
    font-size: 1.6rem;
    box-shadow: 2px 2px 0 0 #4e4743;
    border-right: 5px solid #fff;
    border-bottom: 5px solid #fff;
    border-left: none;
    border-top: none;
    text-align: center;
    @include media($middle){
      font-size: 1.3rem;
      min-width: 270px;
    }
    &:before{
      content: "";
      width: 20px;
      height: 20px;
      display: inline-block;
      background: url(./assets/img/icon_btn_line_white.png) no-repeat center center;
      background-size: cover;
      position: absolute;
      left: -2px;
      top: -2px;
      transition: all 200ms ease-in-out;
    }
    &:after{
      content: "";
      width: 20px;
      height: 20px;
      display: inline-block;
      background: url(./assets/img/icon_btn_line_white.png) no-repeat center center;
      background-size: cover;
      position: absolute;
      right: -2px;
      bottom: -2px;
      transition: all 200ms ease-in-out;
    }
    &:hover{
      opacity: 1;
      box-shadow: none;
      transform:translate(5px,5px);
      &:before{
        left: -20px;
        top: -20px;
        transition: all 200ms ease-in-out;
      }
      &:after{
        right: -20px;
        bottom: -20px;
        transition: all 200ms ease-in-out;
      }
    }
  }
}

.btn-list-01{
  font-size: 0;
  @include media($middle){
    text-align: center;
  }
  &--center{
    text-align: center;
  }
  &--right{
    text-align: right;
    @include media($middle){
      text-align: center;
    }
  }
  &_item{
    display: inline-block;
    margin-right: 70px;
    transition: all 200ms ease-in-out;
    vertical-align: top;
    height: 50px;
    line-height: 50px;
    @include media($middle){
      margin: 0 0 10px 0;
      height: 40px;
      line-height: 40px;
      position: relative;
      right: 10px;
    }
    &:last-child{
      margin-right: 0;
      @include media($middle){
        margin: 0;
      }
    }
    &_small{
      display: block;
      font-size: 1.3rem;
      @include media($middle){
        font-size: 1.2rem;
      }
    }
    a{
      min-width: 260px;
      transition: all 200ms ease-in-out;
      z-index: 2;
      display: block;
      position: relative;
      background: #333;
      color: #fff;
      padding: 0 50px 0 15px;
      font-weight: 600;
      font-size: 1.6rem;
      text-align: left;
      @include media($middle){
        min-width: 220px;
        font-size: 1.2rem;
        padding: 0 25px 0 10px;
      }
      &:before{
        content: "";
        display: inline-block;
        width: 31px;
        height: 12px;
        background: url("./assets/img/btn_right.png") no-repeat center;
        background-size: auto;
        background-size: cover;
        position: absolute;
        right: 0px;
        top: 19px;
        transition: 0.3s;
        z-index: 1;
        @include media($middle){
          width: 20px;
          height: 9px;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
      &:after{
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 0 0 50px;
        border-color: transparent transparent transparent #333333;
        position: absolute;
        right: -50px;
        top: 0;
        @include media($middle){
          border-width: 40px 0 0 20px;
          right: -20px;
        }
      }
      &:hover{
        opacity: 1;
        box-shadow: none;
        &:before{
          right: -10px;
          transition: all 200ms ease-in-out;
        }
      }
    }
  }
}

.btn-list-02{
  font-size: 0;
  @include media($middle){
    text-align: center;
  }
  &--center{
    text-align: center;
  }
  &--right{
    text-align: right;
    @include media($middle){
      text-align: center;
    }
  }
  &_item{
    display: inline-block;
    margin-right: 20px;
    transition: all 200ms ease-in-out;
    @include media($middle){
      margin: 0 0 10px 0;
    }
    &:last-child{
      margin-right: 0;
      @include media($middle){
        margin: 0;
      }
    }
    &--w-360{
      a{
        width: 360px;
        @include media($middle){
          width: 270px;
        }
      }
    }
    &--line-2{
      line-height: 1.4;
      letter-spacing: 0;
      a{
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
    }
    &_small{
      display: block;
      font-size: 1.3rem;
      @include media($middle){
        font-size: 1.2rem;
      }
    }
    a{
      width: 225px;
      transition: all 200ms ease-in-out;
      z-index: 2;
      display: inline-block;
      position: relative;
      background: #4e4743;
      color: #fff;
      padding: 15px;
      border: 1px solid #ffffff;
      overflow: hidden;
      font-weight: 600;
      font-size: 1.6rem;
      box-shadow: 2px 2px 0 0 #4e4743;
      border-right: 5px solid #fff;
      border-bottom: 5px solid #fff;
      border-left: none;
      border-top: none;
      text-align: center;
      @include media($middle){
        font-size: 1.3rem;
      }
      &:before{
        content: "";
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url(./assets/img/icon_btn_line_white.png) no-repeat center center;
        background-size: cover;
        position: absolute;
        left: -2px;
        top: -2px;
        transition: all 200ms ease-in-out;
      }
      &:after{
        content: "";
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url(./assets/img/icon_btn_line_white.png) no-repeat center center;
        background-size: cover;
        position: absolute;
        right: -2px;
        bottom: -2px;
        transition: all 200ms ease-in-out;
      }
      &:hover{
        opacity: 1;
        box-shadow: none;
        transform:translate(5px,5px);
        &:before{
          left: -20px;
          top: -20px;
          transition: all 200ms ease-in-out;
        }
        &:after{
          right: -20px;
          bottom: -20px;
          transition: all 200ms ease-in-out;
        }
      }
    }
    &--w-360{
      a{
        width: 360px;
        @include media($middle){
          width: 270px;
          letter-spacing: 0;
        }
      }
    }
  }
}

#breadcrumb{
  font-size: 1.2rem;
  padding: 10px 0;
  line-height: 1.6;
  background: #333333;
  @include media($middle){
    padding: 10px 15px;
  }
  ul{
    @include outer-container(980px);
  }
  span,a{
    display: inline-block;
    color: #fff;
  }
  li{
    display: inline-block;
    color: #fff;
  }
  a{
    &:after{
      font-family: FontAwesome;
      content: "\f105";
      font-size: 1.2rem;
      margin: 0 10px;
      @include media($middle){
        margin: 0 5px;
        font-size: 1.1rem;
      }
    }
  }
}

@media all and (-ms-high-contrast:none) {
  .inc-contact{
    margin-top: 60px;
  }
}
.inc-contact.home{
  background-color: #ffffff;
  .inc-contact_container{
    text-align: center;
    padding: 0px 0 60px;
    @include outer-container(980px);
    @include media($middle){
      padding: 30px 0;
      margin: 0 5%;
    }
  }
  .inc-contact_en{
    font-size: 130px;
    text-align: center;
    font-family: $font-helvetica;
    letter-spacing: 0.05em;
    color: #efebe2;
    line-height: 0.65;
    margin-bottom: 50px;
    font-weight: 500;
    @include media($middle){
      font-size: 40px;
      margin-bottom: 20px;
    };
  }
  .inc-contact_ja{
    margin-bottom: 80px;
    @include media($middle){
      margin-bottom: 50px;
    };
    span{
      font-size: 3.4rem;
      font-weight: bold;
      position: relative;
      @include media($middle){
        font-size: 1.4rem;
      };
      &::before{
        content:"";
        display: inline-block;
        width: 21px;
        height: 28px;
        background: url('./assets/img/inc-contact_border_left.png') no-repeat center;
        background-size: cover;
        position: absolute;
        top: 25px;
        left: -30px;
        @include media($middle){
          width: 10.5px;
          height: 14px;
          top: 8px;
          left: -12px;
        };
      }
      &::after{
        content:"";
        display: inline-block;
        width: 21px;
        height: 28px;
        background: url('./assets/img/inc-contact_border_right.png') no-repeat center;
        background-size: cover;
        position: absolute;
        top: 25px;
        right: -25px;
        @include media($middle){
          width: 10.5px;
          height: 14px;
          top: 7px;
          right: -10px;
        };
      }
    }
  }

  .inc-contact_btns{
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
    @include media($middle){
      flex-direction: column;
      margin-bottom: 0px;
    };
    &_item{
      width: 300px;
      border: 5px solid #333333;
      margin-right: 40px;
      @include media($middle){
        width: 100%;
        margin-right: 0px;
        margin-bottom: 40px;
      };
      &:nth-of-type(2){
        margin-right: 0;
      }
      a{
        display: inline-block;
        height: 100%;
        padding: 0 20px 15px;
      }
      &_icon{
        margin-top: -27px;
        margin-bottom: 45px;
        @include media($middle){
          width: 60px;
          margin: -25px auto 20px;
        };
      }
      &_icon.pamphlet{
        margin-bottom: 20px;
        @include media($middle){
          width: 75px;
          margin: -25px auto 20px;
        };
      }
      &_icon.online{
        margin-bottom: 32px;
        @include media($middle){
          width: 75px;
          margin: -25px auto 20px;
        };
      }
      &_name{
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 1;
        margin-bottom: 5px;
        @include media($middle){
          font-size: 2.2rem;
          margin-bottom: 0px;
        };
      }
      &_en{
        font-size: 1.6rem;
        font-family: $font-helvetica;
        line-height: 1.4;
        margin-bottom: 40px;

        @include media($middle){
          font-size: 1.3rem;
          margin-bottom: 10px;
        };
      }
      &_txt{
        font-size: 1.4rem;
        text-align: left;
        line-height: 1.8;
        min-height: 118px;
        margin-bottom: 0;
        text-align: justify;
        @include media($middle){
          font-size: 1.2rem;
          min-height: 70px;
        };
      }
      &_right{
        transition: 0.2s;
      }
      &:hover{
        .inc-contact_btns_item_right{
          transform: translateX(10px);
        }
      }
    }
  }
  .inc-contact_tel{
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 5px solid #333333;
      padding: 30px 0 35px;
      @include media($middle){
        flex-direction: column;
        padding: 15px 10px;
      };
    }
    &_left{
      margin-right: 35px;
      @include media($middle){
        margin-right: 0;
      };
      figure{
        margin-bottom: 5px;
        @include media($middle){
          width: 50px;
          margin: 0 auto 8px;
        };
      }
      &_name{
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 1;
        margin-bottom: 5px;
        @include media($middle){
          font-size: 2.2rem;
        };
      }
      &_en{
        font-size: 1.6rem;
        font-family: $font-helvetica;
        line-height: 1.4;
        margin-bottom: 0px;
        font-weight: 500;
        @include media($middle){
          font-size: 1.3rem;
          margin-bottom: 10px;
        };
      }
    }
    &_right{
      text-align: center;
      &_time{
        font-size: 1.8rem;
        padding-bottom: 5px;
        letter-spacing: 0.05em;
        border-bottom: 3px solid #333333;
        @include media($middle){
          font-size: 1.4rem;
        };
      }
      &_num{
        font-size: 5rem;
        margin-bottom: 0;
        font-family: $font-helvetica;
        letter-spacing: 0.1em;
        line-height: 1;
        font-weight: 500;
        @include media($middle){
          font-size: 3rem;
        };
      }
    }
  }
}


.inc-contact.another-page{
  background: url('./assets/img/inc-contact_bg_2.jpg') no-repeat center;
  background-size: cover;
  .inc-contact_container{
    text-align: center;
    padding: 0px 0 100px;
    @include outer-container(980px);
    @include media($middle){
      padding: 30px 0;
      margin: 0 5%;
    }
  }
  .inc-contact_en{
    font-size: 100px;
    text-align: center;
    font-family: $font-helvetica;
    letter-spacing: 0.05em;
    color: #ffffff;
    line-height: 0.65;
    margin-bottom: 100px;
    font-weight: 500;
    @include media($middle){
      font-size: 40px;
      margin-bottom: 60px;
    };
  }


  .inc-contact_btns{
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
    @include media($middle){
      flex-direction: column;
      margin-bottom: 0px;
    };
    &_item{
      width: 300px;
      border: 2px solid #ffffff;
      margin-right: 40px;
      @include media($middle){
        width: 100%;
        margin-right: 0px;
        margin-bottom: 40px;
      };
      &:nth-of-type(2){
        margin-right: 0;
      }
      a{
        display: inline-block;
        height: 100%;
        padding: 0 20px 10px;
      }
      &_icon{
        margin-top: -27px;
        margin-bottom: 31px;
        @include media($middle){
          width: 60px;
          margin: -25px auto 20px;
        };
      }
      &_icon.pamphlet{
        margin-bottom: 16px;
        @include media($middle){
          width: 75px;
          margin: -25px auto 20px;
        };
      }
      &_icon.online{
        margin-bottom: 25px;
        @include media($middle){
          width: 75px;
          margin: -25px auto 20px;
        };
      }
      &_name{
        color: #ffffff;
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 1;
        margin-bottom: 5px;
        @include media($middle){
          font-size: 2.2rem;
          margin-bottom: 0px;
        };
      }
      &_en{
        color: #ffffff;
        font-size: 1.6rem;
        font-family: $font-helvetica;
        line-height: 1.4;
        margin-bottom: 17px;
        font-weight: 500;
        @include media($middle){
          font-size: 1.3rem;
          margin-bottom: 10px;
        };
      }
      &_right{
        transition: 0.2s;
      }
      &:hover{
        .inc-contact_btns_item_right{
          transform: translateX(10px);
        }
      }
    }
  }
  .inc-contact_tel{
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #ffffff;
      padding: 28px 0 35px;
      @include media($middle){
        flex-direction: column;
        padding: 15px 10px;
      };
    }
    &_left{
      margin-right: 35px;
      @include media($middle){
        margin-right: 0;
      };
      figure{
        margin-bottom: 5px;
        @include media($middle){
          width: 50px;
          margin: 0 auto 8px;
        };
      }
      &_name{
        color: #ffffff;
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 1;
        margin-bottom: 5px;
        @include media($middle){
          font-size: 2.2rem;
        };
      }
      &_en{
        color: #ffffff;
        font-size: 1.6rem;
        font-family: $font-helvetica;
        line-height: 1.4;
        margin-bottom: 0px;
        font-weight: 500;
        @include media($middle){
          font-size: 1.3rem;
          margin-bottom: 10px;
        };
      }
    }
    &_right{
      text-align: center;
      &_time{
        color: #ffffff;
        font-size: 1.8rem;
        padding-bottom: 5px;
        letter-spacing: 0.05em;
        border-bottom: 3px solid #ffffff;
        @include media($middle){
          font-size: 1.4rem;
        };
      }
      &_num{
        color: #ffffff;
        font-size: 5rem;
        margin-bottom: 0;
        font-family: $font-helvetica;
        letter-spacing: 0.1em;
        line-height: 1;
        font-weight: 500;
        @include media($middle){
          font-size: 3rem;
        };
      }
    }
  }
}

.lower-head{
  width: 100%;
  margin-bottom: 0px;
  padding-top: 31.25%;
  position: relative;
  @include media($middle){
  }

  &--privacy{
    background: url(./assets/img/privacy_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--sitemap{
    background: url(./assets/img/sitemap_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--voice{
    background: url(./assets/img/voice_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--archive{
    background: url(./assets/img/archive_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--staffblog{
    background: url(./assets/img/staffblog_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--staff{
    background: url(./assets/img/staff_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--works{
    background: url(./assets/img/works_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--realestate{
    background: url(./assets/img/realestate_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--contact{
    background: url(./assets/img/contact_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--material{
    background: url(./assets/img/material_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--consultation{
    background: url(./assets/img/consultation_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--recruit{
    background: url(./assets/img/recruit_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--concept{
    background: url(./assets/img/concept_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--performance{
    background: url(./assets/img/performance_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--guarantee{
    background: url(./assets/img/guarantee_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--flow{
    background: url(./assets/img/flow_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--faq{
    background: url(./assets/img/faq_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--after{
    background: url(./assets/img/after_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--orderhouse{
    background: url(./assets/img/orderhouse_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--re{
    background: url(./assets/img/re_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--exterior{
    background: url(./assets/img/exterior_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--lism{
    background: url(./assets/img/lism_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--company{
    background: url(./assets/img/company_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--sdgs{
    background: url(./assets/img/sdgs_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--office{
    background: url(./assets/img/office_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--specialbuilding{
    background: url(./assets/img/specialbuilding_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--modelhouse{
    background: url(./assets/img/modelhouse_head.jpg) no-repeat center center;
    background-size: cover;
  }
  &--oohouse{
    background: url(./assets/img/oohouse_head.jpg) no-repeat center center;
    background-size: cover;

  }
  &_container{
    @include outer-container($content-width);
    @include media($middle){
      margin: 0 5%;
    };
  }

  /*
  &_heading{
    font-size: 4.4rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.05em;
    position: relative;
    margin-bottom: 45px;
    @include media($middle){
      font-size: 2.5rem;
      margin-bottom: 30px;
    }
    &:after{
      display: block;
      content:"";
      display: inline-block;
      width: 75px;
      height: 4px;
      background-color: #babeac;
      @include media($middle){
        width: 40px;
        height: 2px;
        top: 30px;
      }
    }
    &_en{
      display: block;
      font-size: 2.5rem;
      font-weight: normal;
      font-family: $font-helvetica;
      margin-top: 15px;
      position: relative;
      @include media($middle){
        font-size: 1.2rem;
        letter-spacing: 0.2em;
        margin-top: 5px;
      }
    }
  }
  */
}

.local-navi{
  font-family: $font-noto;
  padding: 70px 0 90px;

  @include media($middle){
    padding: 45px 15px;
  }
  &_container{
    @include outer-container(780px);
  }
  &_heading{
    text-align: center;
    font-size: 5.5rem;
    font-family: $font-helvetica;
    margin-bottom: 50px;
    line-height: 0.8;
    font-weight: 500;
    @include media($middle){
      font-size: 4rem;
      line-height: 0.6;
      margin-bottom: 30px;
    };
    span{
      font-size: 2rem;
      font-weight: bold;
      @include media($middle){
        font-size: 1.6rem;
      };
    }
  }
  &_list{
    text-align: center;
    font-size: 0;
    letter-spacing: 0;
    line-height: 1.4;
    @include media($middle){
      line-height: 1.2;
    }
    &_item{
      position: relative;
      display: inline-block;
      background: #fff;
      font-size: 1.8rem;
      font-weight: 500;
      margin-right: 30px;
      @include media($middle){
      }
      &:last-child{
        margin-right: 0;
      }
      a{
        color: #3f3936;
        width: 155px;
        height: 155px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media($middle){
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  &_thumb-navi{

    &_list{
      display: flex;
      justify-content:center;
      align-items: flex-end;
      flex-wrap: wrap;
      margin-bottom: 55px;
      @include media($middle){
        margin-bottom: 0px;
      };
      &_item{
        width: 180px;
        margin-right: 19px;
        text-align: center;
        @include media($middle){
          width: 49%;
          margin-right: 2%;
        };
        &:last-of-type{
          margin-right: 0;
        }
        &:nth-of-type(2n){
          @include media($middle){
            margin-right: 0;
          };
        }
        img{
          @include media($middle){
            padding:  10% 10%;
          }
        }
        &_name{
          height: 70px;
          font-size: 1.8rem;
          font-weight: bold;
          margin: 20px 0;
          padding-left: 12px;
          background-color: #f1ece2;
          border-top: 2px solid #babdab;
          position: relative;
          text-align: left;
          display: flex;
          align-items: center;
          @include media($middle){
            font-size: 1.4rem;
            height: 50px;
            margin: 10px 0;
          };
          &::after{
            content:"";
            display: inline-block;
            width: 21px;
            height: 13px;
            background: url('./assets/img/concept_lineup_right.png') no-repeat center;
            background-size: cover;
            position: absolute;
            top: 27px;
            right: 10px;
            transition: 0.3s;
            @include media($middle){
              top: 18px;
            };
          }
          &:hover::after{
            transform: translateX(5px);
          }
        }
      }
    }
    &_col-04{
      @include clearfix;
      @include media($middle){
        margin-top: 40px;
      };
      &_item{
        width: 180px;
        float: left;
        margin-right: 20px;
        @include media($middle){
          @include span-columns(6);
          @include omega(2n);
          margin-bottom: 5%;
        }
        &:last-child{
          margin-right: 0;
        }
        a{
          display: block;
        }
        &_thumb{
          text-align: center;
          margin-bottom: 15px;
          @include media($middle){
            margin-bottom: 5px;
          }
        }
        &_txt{
          margin: 0;
          line-height: 1.6;
          font-weight: 500;
          font-size: 1.8rem;
          position: relative;
          font-weight: bold;
          @include media($middle){
            font-size: 1.2rem;
          }
          &:after{
            display: inline-block;
            content: "";
            background: url('./assets/img/concept_lineup_right.png') no-repeat center;
            background-size: cover;
            width: 21px;
            height: 13px;
            position: absolute;
            top: 15px;
            right: -10px;
            transform: translate(-50%, -50%);
            @include media($middle){
              display: none;
            }
          }
          span{
            &.icon-blank{
              &:after{
                content: "";
                display: inline-block;
                background: url(./assets/img/icon_blank_white.png) no-repeat center center;
                background-size: cover;
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.inc-lism-bnr{
  @include outer-container($content-width);
  &--mb-120{
    margin-bottom: 120px;
    @include media($middle){
      margin-bottom: 60px;
    }
  }
  &_sp{
    &_thumb{
      text-align: center;
    }
    &_contents{
      position: relative;
      z-index: 1;
      @include outer-container(290px);
      margin-top: -30px;
    }
  }
}

.fixed-btn{
  position: fixed;
  right: 0;
  top: 46%;
  z-index: 11;
  display: none;
  @include media($middle){
    left: 0;
    bottom: 0;
    right: inherit;
    top: inherit;
    text-align: center;
    display: block;
    background: #eb6100;
    width: 100%;
    line-height: 1;
  }
  a{
    display: block;
    color: #fff;
    @include media($middle){
      padding: 10px 0;
    }
  }
  &_list{
    @include clearfix;
    &_item{
      width: 25%;
      float: left;
      font-size: 1.1rem;
      font-weight: 600;
      i{
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}


.required{
  color: #ff0000;
}

.cmp-form{
  @include outer-container($content-width);
  color: #000000;
  background: #fff;
  padding: 60px 140px 100px;
  @include media($middle){
    padding: 20px 15px 30px;
  }
  select{
    width: auto;
  }
  &_table{
    width: 100%;
    margin-bottom: 50px;
    @include media($middle){
      margin-bottom: 20px;
      display: block;
    }
    tr{
      @include media($middle){
        display: block;
      }
      th{
        font-weight: normal;
        border-bottom: 1px solid #dcdcdc;
        width: 210px;
        padding: 15px 0;
        vertical-align: top;
        @include media($middle){
          display: block;
          width: 100%;
          font-weight: bold;
          padding: 10px 0 5px;
          br{
            display: none;
          }
        }
      }
      td{
        border-bottom: none;
        padding: 10px 0 10px 35px;
        @include media($middle){
          display: block;
          width: 100%;
          padding: 10px 0 5px;
        }
      }
    }
  }
  input,textarea{
    margin: 0;
    padding: 5px;
  }
  input[type="checkbox"]{
    margin-right: 5px;
  }
  label{
    font-weight: normal;
    font-size: 1.4rem;
  }
  .wpcf7-checkbox{
    .wpcf7-list-item{
      display: block;
    }
  }
  .btn-submit{
    display: block;
    width: 360px;
    margin: 0 auto;

    font-size: 1.6rem;
    border: 1px solid #4e4743;
    padding: 25px 15px;
    border-radius: 0;
    color: #fff;
    @include media($middle){
      width: 270px;
    }
  }
}



.btn-list-03{
  font-weight: bold;
  &--left{
    text-align: left;
  }
  &--center{
    text-align: center;
  }
  &--right{
    text-align: right;
  }
  &_item{
    display: inline-block;
    background: #333333;
    min-width: 260px;
    a{
      position: relative;
      display: block;
      color: #fff;
      &:before{
        content: "";
        display: inline-block;
        width: 31px;
        height: 12px;
        background: url("./assets/img/btn_right.png") no-repeat center;
        background-size: auto;
        background-size: cover;
        position: absolute;
        right: 0px;
        top: 19px;
        transition: 0.3s;
        z-index: 1;
      }
      &:after{
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 0 0 50px;
        border-color: transparent transparent transparent #333333;
        position: absolute;
        bottom: 0;
        right: -50px;
      }
    }
  }
}

.lower-head-contents{
  padding-bottom: 50px;
  @include media($middle){
    padding: 0 0 25px;
  }
  &--pb{
    padding-bottom: 90px;
    @include media($middle){
      padding: 0 0 60px 0;
    }
  }
  &--pb-large{
    padding-bottom: 140px;
    @include media($middle){
      padding: 0 0 60px 0;
    }
  }
  &_container{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
  }
  &_heading{
    font-size: 4.4rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.05em;
    position: relative;
    display: inline-block;
    background: #fff;
    border-radius: 0 20px 0 0;
    margin: -45px 0 0 0;
    padding: 45px 50px 0 0;
    padding-left: calc((100vw - 1080px) / 2);
    @include media($middle){
      margin: -20px 0 0 0;
      font-size: 2.2rem;
      padding-left: 15px;
      padding: 20px 20px 0 15px;
      border-radius: 0 10px 0 0;
    }
    &:after{
      display: block;
      content:"";
      margin-top: 40px;
      width: 75px;
      height: 4px;
      background-color: #babeac;
      @include media($middle){
        width: 40px;
        height: 2px;
        margin-top: 20px;
      }
    }
    &_en{
      display: block;
      font-size: 2.5rem;
      font-weight: normal;
      font-family: $font-helvetica;
      margin-top: 20px;
      position: relative;
      font-weight: 500;
      @include media($middle){
        font-size: 1.2rem;
        letter-spacing: 0.2em;
        margin-top: 10px;
      }
    }
  }
  &_sub-heading{
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 45px;
    line-height: 1.75;
    @include media($middle){
      font-size: 1.6rem;
      margin-top: 20px;
      line-height: 1.3;
    }
  }
  &_txt{
    margin-top: 30px;
    @include media($middle){
      margin-top: 20px;
    }
  }
}

.contents-wrap{
  background: #efebe2;
  padding-top: 50px;
  padding-bottom: 90px;
  @include media($middle){
    padding-top: 30px;
    padding-bottom: 45px;
  }
}
.contents-wrap-02{
  background: #f9f7f3;
  padding-top: 50px;
  padding-bottom: 90px;
  @include media($middle){
    padding-top: 30px;
    padding-bottom: 45px;
  }
}

.inc-iedukuri{
  margin-bottom: 100px;
  padding-top: 100px;
  @include media($middle){
    margin-bottom: 45px;
    padding-top: 45px;
  }
  &_container{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
  }
  &_heading{
    text-align: center;
    font-size: 2.8rem;
    font-weight: bold;
    vertical-align: bottom;
    letter-spacing: 0.05em;
    margin-bottom: 30px;
    @include media($middle){
      font-size: 1.8rem;
    }
    &:after{
      content: "";
      display: block;
      width: 71px;
      height: 5px;
      background: #babeac;
      margin: 25px auto 0;
    }
    img{
      vertical-align: bottom;
      display: inline-block;
      margin-right: 10px;
      @include media($middle){
        width: 140px;
      }
    }
    span{
      color: #7c8266;
      font-size: 3.4rem;
      vertical-align: bottom;
      margin-left: 5px;
      @include media($middle){
        font-size: 2.2rem;
        margin-left: 5px;
      }
    }
  }
  &_list{
    @include clearfix;
    &_item{
      width: 170px;
      float: left;
      margin-right: 12px;
      @include omega(6n);
      background: #f1ece2;
      @include media($middle){
        @include span-columns(6);
        @include omega(2n);
        margin-bottom: 2.5%;
      }
      a{
        display: block;
        &:hover{
          .inc-iedukuri_list_item_txt{
            &:after{
              right: 5px;
              transition: all 200ms ease-in-out;
            }
          }
        }
      }
      &_thumb{
        text-align: center;
        img{
          width: 100%;
          height: auto;
        }
      }
      &_txt{
        position: relative;
        display: flex;
        padding: 0 15px;
        font-weight: bold;
        line-height: 1.4;
        min-height: 65px;
        display: flex;
        align-items: center;
        border: 1px solid #dedee0;
        border-top: none;
        @include media($middle){
          font-size: 1.2rem;
          min-height: 50px;
        }
        &:after{
          content: "";
          display: block;
          width: 21px;
          height: 13px;
          background: url(./assets/img/inc-iedukuri_arrow.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          top: 0;
          right: 10px;
          bottom: 0;
          margin: auto;
          transition: all 200ms ease-in-out;
        }
      }
    }
  }
}

.inc-lineup-ptn-01{
  padding: 90px 0 100px;
  @include media($middle){
    padding: 45px 0;
  }
  &_container{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
  }
  &_heading{
    text-align: center;
    margin-bottom: 45px;
    line-height: 1.3;
    @include media($middle){
      margin-bottom: 30px;
    }
    &_en{
      font-family: $font-helvetica;
      font-size: 6.8rem;
      font-weight: 500;
      @include media($middle){
        font-size: 4.2rem;
      }
    }
    &_ja{
      font-size: 2rem;
      font-weight: bold;
      display: block;
      @include media($middle){
        font-size: 1.4rem;
      }
    }
  }
  &_list{
    @include clearfix;
    &_item{
      width: 145px;
      float: left;
      margin-right: 10px;
      @include omega(7n);
      @include media($middle){
        @include span-columns(6);
        @include omega(2n);
        margin-bottom: 0;
      }
      a{
        display: block;
        &:hover{
          .inc-lineup-ptn-01_list_item_txt{
            &:after{
              right: 5px;
              transition: all 200ms ease-in-out;
            }
          }
        }
      }
      &_thumb{
        position: relative;
        text-align: center;
        width: 146px;
        height: 120px;
        margin: 0 auto;
        @include media($middle){
          width: 120px;
          height: 90px;
        }
        img{
          position: absolute;
          left: 0;
          right: 0;
          bottom: 20px;
          margin: auto;
          @include media($middle){
            padding: 10px;
            bottom: 0;
          }
        }
      }
      &_txt{
        position: relative;
        display: flex;
        padding: 0 10px;
        font-weight: bold;
        line-height: 1.4;
        min-height: 65px;
        display: flex;
        align-items: center;
        border-top: 2px solid #babdab;
        background: #f1ece2;
        font-size: 1.4rem;
        letter-spacing: 0;
        @include media($middle){
          font-size: 1.2rem;
          min-height: 50px;
        }
        &:after{
          content: "";
          display: block;
          width: 21px;
          height: 13px;
          background: url(./assets/img/inc-iedukuri_arrow.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          top: 0;
          right: 10px;
          bottom: 0;
          margin: auto;
          transition: all 200ms ease-in-out;
        }
      }
    }
  }
}

.mt-30{
  margin-top: 30px;
}