.archive-voice{

  &_container{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
  }

  .archive-voice-list{
    @include clearfix;
    margin-bottom: 0;
    @include media($middle){
    }
    &_item{
      margin-bottom: 2.5%;
      line-height: 1;
      @include media($large){
        @include span-columns(4);
        @include omega(3n);
      }
      @include media($middle){
        @include span-columns(6);
        @include omega(2n);
      }
      &_thumb{}
      &_heading{
        position: relative;
        background: #fff;
        font-size: 1.6rem;
        font-weight: 400;
        padding: 15px 30px;
        margin-bottom: 0;
        @include media($middle){
          font-size: 1.4rem;
          padding: 10px;
        }
        &:after{
          font-family: FontAwesome;
          content: "\f105";
          font-weight: bold;
          color: #babeac;
          font-size: 3rem;
          position: absolute;
          right: 20px;
          top: 7px;
          @include media($middle){
            font-size: 2rem;
            right: 10px;
          }
        }
      }
    }
  }

}

.single-voice{
  &_container{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
  }
  &_faq{
    margin-top: 60px;
    &_heading{
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 25px;
      font-weight: bold;
      @include media($middle){
        font-size: 1.8rem;
        margin-bottom: 20px;
      }
    }
    &_item{
      padding: 25px 50px;
      margin-bottom: 10px;
      background: #fff;
      @include media($middle){
        padding: 20px 10px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &_heading{
        font-size: 1.6rem;
        margin-bottom: 0;
        padding-left: 65px;
        text-indent: -65px;
        margin-bottom: 25px;
        line-height: 1.6;
        font-weight: bold;
        @include media($middle){
          font-size: 1.4rem;
          padding-left: 40px;
          text-indent: -40px;
          margin-bottom: 15px;
        }
        &:before{
          content: "Q";
          font-family: $font-helvetica;
          font-size: 2rem;
          font-weight: 500;
          color: #4e4743;
          padding: 8px 13px;
          margin-right: 20px;
          background: #babeac;
          @include media($middle){
            margin-right: 10px;
            padding: 3px 7px;
          }
        }
      }
      &_txt{
        font-size: 1.6rem;
        padding-left: 65px;
        text-indent: -65px;
        margin-bottom: 0;
        line-height: 1.6;
        @include media($middle){
          font-size: 1.4rem;
          padding-left: 40px;
          text-indent: -40px;
        }
        &:before{
          content: "A";
          font-family: $font-helvetica;
          font-size: 2rem;
          color: #fff;
          background: #333333;
          padding: 8px 14px;
          margin-right: 20px;
          font-weight: 500;
          @include media($middle){
            margin-right: 10px;
            padding: 3px 8px;
          }
        }
      }
    }
  }
}
