.modelhouse{
  &-archive{
    background-color: #f1ece2;
    margin-top: 100px;
    @include media($middle){
      margin-top: 60px;
    };
    &_container{
      position: relative;
      bottom: 100px;
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
        bottom: 50px;
      };
    }
    &-list{
      display: flex;
      flex-wrap: wrap;
      @include media($middle){
        flex-direction: column;
      };

      &_item{
      width: 49%;
      margin-right: 2%;
      margin-bottom: 35px;
      @include omega(2n);
      @include media($middle){
        width: 100%;
          margin-right: 0;
      };
      &_contents{
        padding: 45px 40px;
        background-color: #ffffff;
        @include media($middle){
          padding: 20px;
        };
        &_detail{
          display: flex;
          align-items: center;
          border-top: 1px solid #c0c0c0;
          padding: 10px 0;
          &:nth-of-type(4){
            border-bottom: 1px solid #c0c0c0;
          }
          dt{
            font-size: 1.4rem;
            width: 130px;
            @include media($middle){
              font-size: 1.2rem;
              width: 80px;
            };
          }
          dd{
            font-size: 1.4rem;
            line-height: 1.7;
            @include media($middle){
              font-size: 1.2rem;
            };
          }
          &_tel{
            position: relative;
            font-size: 2.2rem !important;
            font-weight: bold;
            @include media($middle){
              font-size: 1.8rem !important;
            };
            &::before{
              content:"";
              display: inline-block;
              width: 19px;
              height: 19px;
              background: url('./assets/img/modelhouse_tel_icon.png') no-repeat center;
              background-size: cover;
              margin-right: 8px;
              @include media($middle){
                width: 15px;
                height: 15px;
              };
            }
          }
        }
      }
      &_name{
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 30px;
        letter-spacing: 0.05em;
        @include media($middle){
          font-size: 2rem;
          margin-bottom: 10px;
        };
      }
      &_sub{
        font-size: 1.4rem;
        text-align: center;
        margin-bottom: 30px;
        @include media($middle){
          font-size: 1.2rem;
          margin-bottom: 15px;
        };
      }
      &_link{
        margin-top: 22px;
        display: flex;
        justify-content: space-between;
        @include media($middle){
          flex-direction: column;
        };
        &_item{
          width: 220px;
          background-color: #333333;
          color: #ffffff;
          text-align: center;
          padding: 8px 0;
          font-size: 1.4rem;
          position: relative;
          &:first-of-type{
            @include media($middle){
            margin-bottom: 10px;
            };
          }
          @include media($middle){
            width: 100%;
            font-size: 1.2rem;
          };
          &::after{
            content:"";
            display: inline-block;
            width: 5px;
            height: 5px;
            border-right: 2px solid #ffffff;
            border-bottom: 2px solid #ffffff;
            transform: rotate(-45deg);
            position: absolute;
            top: 21px;
            right: 15px;
            @include media($middle){
              top: 17px;
            };
          }
        }
      }
      }
    }
  }

  &-single{
    background-color: #efebe2;
    padding-top: 90px;
    @include media($middle){
      padding-top: 40px;
    };
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        margin: 0 5%;
      };
    }
    &_name{
      text-align: center;
      font-size: 4.4rem;
      font-weight: bold;
      margin-bottom: 40px;
      letter-spacing: 0.05em;
      @include media($middle){
        font-size: 3rem;
        margin-bottom: 10px;
      };
    }
    &_subtxt{
      text-align: center;
      margin-bottom: 40px;
      @include media($middle){
        font-size: 1.2rem;
        margin-bottom: 30px;
      };
    }
    &_thumb{
      margin-bottom: 95px;
      @include media($middle){
        margin-bottom: 30px;
      }
    }
    &_content{
      background-color: #ffffff;
      padding: 100px;
      margin-bottom: 50px;
      @include media($middle){
        padding: 20px 15px;
      }
    }
    &_detail{
      display: flex;
      justify-content: space-between;
      padding-bottom: 100px;
      border-bottom: 1px solid #333333;
      @include media($middle){
        flex-direction: column;
        padding-bottom: 70px;
      };
      &_box{
        background-color: #ffffff;
        border: 1px solid #333333;
        padding: 20px 38px 15px;
        width: 49%;
        height: 270px;
        margin-right: 2%;
        @include media($middle){
          width: 100%;
          margin-right: 0;
          padding: 10px;
          height: auto;
          margin-bottom: 20px;
        };
        &_item{
          display: flex;
          align-items: center;
          border-bottom: 1px solid #c0c0c0;
          padding: 10px 0;
          &:nth-of-type(4){
            border-bottom: 0px solid #c0c0c0;
          }
          dt{
            font-size: 1.4rem;
            width: 130px;
            @include media($middle){
              font-size: 1.2rem;
              width: 80px;
            };
          }
          dd{
            font-size: 1.4rem;
            line-height: 1.7;
            @include media($middle){
              font-size: 1.2rem;
            };
          }
          &_tel{
            position: relative;
            font-size: 2.2rem !important;
            font-weight: bold;
            @include media($middle){
              font-size: 1.8rem !important;
            };
            &::before{
              content:"";
              display: inline-block;
              width: 19px;
              height: 19px;
              background: url('./assets/img/modelhouse_tel_icon.png') no-repeat center;
              background-size: cover;
              margin-right: 8px;
              @include media($middle){
                width: 15px;
                height: 15px;
              };
            }
          }
        }
      }
      &_map iframe{
        @include media($middle){
          width: 100%;
          height: 200px;
        };
      }
    }
    &_tel{
      background-color: #f1ece2;
      padding-top: 100px;
      @include media($middle){
        padding-top: 60px;

      }
      &_container{
        padding-bottom: 100px;
        @include outer-container($content-width);
        @include media($middle){
          margin: 0 5%;
            padding-bottom: 45px;
        }
      }
      &_header{
        font-size: 6.6rem;
        text-align: center;
        line-height: 0.6;
        margin-bottom: 47px;
        font-family: $font-helvetica;
        letter-spacing: 0.1em;
        font-weight: 500;
        @include media($middle){
            line-height: 0.8;
          font-size: 4rem;
          margin-bottom: 30px;
        };
        &-ja{
          font-size: 1.8rem;
          letter-spacing: 0.05em;
          font-weight: bold;
          @include media($middle){
            font-size: 1.4rem;
          };
        }
      }
      &_box{
        display: block;
        @include outer-container(800px);
        background-color: #ffffff;
        border: 5px solid #333333;
        padding: 30px;
        text-align: center;
        @include media($middle){
          padding: 20px;
        }
        &_heading{
          width: 460px;
          line-height: 1.2;
          font-weight: bold;
          display: inline-block;
          padding-bottom: 10px;
          margin-bottom: 22px;
          border-bottom: 3px solid #333333;
          font-size: 2rem;
          @include media($middle){
            width: 100%;
            font-size: 1.2rem;
            line-height: 1.4;
            padding-bottom: 10px;
            margin-bottom: 15px;
          }
        }
        &_small{
          margin-bottom: 0;
          line-height: 1.2;
          @include media($middle){
            font-size: 1.2rem;
          };
        }
        &_btn{
          a{
            display: block;
          }
        }
      }
    }

    &_form{
      background-color: #f1ece2;
      padding-bottom: 90px;
      &_container{
        background-color: #ffffff;
        padding: 80px 140px;
        @include outer-container($content-width);
        @include media($middle){
          margin: 0 5%;
          padding: 30px 20px;
        };
      }
      &_heading{
        font-weight: bold;
        text-align: center;
        font-size: 2rem;
        text-align: center;
        border-bottom: 3px solid #333333;
        margin-bottom: 45px;
        @include media($middle){
          margin-bottom: 20px;
        };
      }
      &_txt{
        @include outer-container(800px);
        font-size: 1.4rem;
        margin-bottom: 20px;
        @include media($middle){
          font-size: 1.2rem;
          margin-bottom: 0px;
        }
      }
      .cmp-form{
        padding: 50px 0 0;
        @include media($middle){
          padding-top: 20px;
        };
      }
    }

    &_lineup{
      background-color: #f1ece2;
      padding-bottom: 100px;
      @include media($middle){
        padding-bottom: 50px;
      };
      &_container{
        @include outer-container($content-width);
        @include media($middle){
          margin: 0 5%;
        };
      }
      &_list{
        display: flex;
        justify-content: center;
        @include media($middle){
          flex-wrap: wrap;
          justify-content: flex-start;
        };
        &_item{
          width: 32%;
          margin-right: 2%;
          @include media($middle){
            width: 49%;
          };
          &:nth-of-type(2n){
            @include media($middle){
              margin-right: 0;
            };
          }
          &:last-of-type{
            margin-right: 0;
          }
          &_name{
            background-color: #ffffff;
            text-align: center;
            font-size: 2.2rem;
            font-weight: bold;
            line-height: 1;
            padding: 35px 0;
            @include media($middle){
              font-size: 1.5rem;
              padding: 20px 5px;

            };
          }
        }
      }
    }
  }

.cmp-form select {
    width: auto;
    display: inline-block;
    margin-right: 5px;
  }
}
