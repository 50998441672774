.notfound{
  &_head{
    text-align: center;
    padding: 190px 0 0 0;
    margin-bottom: 100px;
    @include media($middle){
      padding: 90px 0 0 0;
      margin-bottom: 60px;
    }
    &_heading{
      font-family: $font-helvetica;
      font-weight: bold;
      font-size: 8rem;
      margin-bottom: 45px;
      font-weight: 500;
      @include media($middle){
        font-size: 6rem;
        margin-bottom: 30px;
      }
      &_en{
        display: block;
        font-size: 4rem;
        @include media($middle){
          font-size: 2.2rem;
        }
      }
    }
    &_txt{
      font-size: 1.6rem;
      margin-bottom: 0;
      margin-bottom: 45px;
      @include media($middle){
        font-size: 1.4rem;
        margin-bottom: 30px;
      }
    }
  }
}

.btn-ptn-01{
  a{
    span{
      color: #fff;
    }
  }
}
