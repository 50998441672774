.re{
  padding-bottom: 0;

  &_head{
    margin-bottom: 60px;
    @include media($middle){
      margin-bottom: 45px;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
  }

  &_self{
    background: #efebe2;
    padding: 100px 0 70px;
    @include media($middle){
      padding: 45px 0 45px;
    }
    .heading-ptn-02{
      img{
        @include media($middle){
          width: 24px;
          margin: 0 10px;
        }
      }
    }
    &_col-03{
      @include clearfix;
      @include outer-container(1110px);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      @include media($middle){
        margin-bottom: 0;
        padding: 0 15px;
      }
      &_item{
        position: relative;
        width: 340px;
        background: #fff;
        margin: 0 15px 30px;
        padding: 30px 0 0 0;
        @include media($middle){
          float: none;
          width: 100%;
          margin: 0 0 10px 0;
        }
        &--arrow{
          &:after{
            position: absolute;
            left: 0;
            right: 0;
            bottom: -70px;
            margin: auto;
            content: "";
            display: inline-block;
            width: 10px;
            height: 58px;
            background: url(./assets/img/performance_self_col-03_item--arrow.png) no-repeat center center;
            background-size: cover;
            @include media($middle){
              display: none;
            }
          }
        }
        &_num{
          display: block;
          text-align: center;
          font-family: $font-helvetica;
          color: #babeac;
          font-size: 2.4rem;
          line-height: 1;
          letter-spacing: 0.05em;
          font-weight: 500;
          @include media($middle){
            font-size: 1.4rem;
          }
          span{
            display: block;
            font-size: 7rem;
            @include media($middle){
              font-size: 3.6rem;
              letter-spacing: 0;
            }
          }
        }
        &_head{
          @include media($middle){
            display: flex; /* 子要素をflexboxで揃える */
            justify-content: space-between; /* 子要素をflexboxにより中央に配置する */
            align-items: center;  /* 子要素をflexboxにより中央に配置する */
            padding: 15px 10px 10px 10px;
          }
          &_heading{
            text-align: center;
            font-size: 2.5rem;
            font-weight: 500;
            margin: 0;
            padding: 25px 0 35px;
            @include media($middle){
              font-size: 1.5rem;
              padding: 0 5px;
            }
            &--line-02{
              padding: 15px 0;
            }
          }
          &_thumb{
            @include media($middle){
              width: 130px;
              text-align: right;
            }
          }
        }
        &_txt{
          color: #000;
          margin: 0;
          font-size: 1.4rem;
          padding: 20px 30px 40px;
          @include media($middle){
            font-size: 1.2rem;
            padding: 0 15px 25px;
          }
        }
        &_sp-btn{
          font-size: 1.3rem;
          text-align: center;
          padding-bottom: 30px;
          a{
            display: inline-block;
            background: #4e4743;
            color: #fff;
            padding: 10px 30px;
            &:after{
              font-family: FontAwesome;
              content: "\f107";
              font-size: 1.4rem;
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }
  }

  &_works{
    position: relative;
    background: #babeac;
    padding: 100px 0;
    @include media($middle){
      padding: 45px 0;
    }
    &:before{
      content: "";
      display: inline-block;
      width: 1185px;
      height: 85px;
      background: url(./assets/img/re_works_en_before.png) no-repeat center center;
      background-size: cover;
      position: absolute;
      left: 0;
      top: -5px;
      right: 0;
      margin: auto;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_item{
      margin-bottom: 50px;
      background: #fff;
      padding: 40px 0 75px;
      @include media($middle){
        margin-bottom: 15px;
        padding: 30px 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &_heading{
        margin-bottom: 45px;
        text-align: center;
        font-size: 2.4rem;
        @include media($middle){
          margin-bottom: 20px;
          line-height: 1.6;
        }
        &_num{
          display: inline-block;
          text-align: center;
          font-family: $font-helvetica;
          color: #babeac;
          font-size: 3rem;
          line-height: 1;
          margin-bottom: 30px;
          font-weight: 500;
          @include media($middle){
            font-size: 1.8rem;
          }
          span{
            display: block;
            font-size: 7rem;
            border-bottom: 5px solid #babeac;
            @include media($middle){
              font-size: 4.8rem;
              border-bottom: 3px solid #babeac;
            }
          }
        }
        &_txt{
          display: block;
          vertical-align: middle;
          font-size: 2.5rem;
          font-weight: bold;
          @include media($middle){
            display: block;
            font-size: 1.8rem;
          }
        }
      }
      &_photo{
        @include clearfix;
        margin-bottom: 0;
        @include media($middle){
          margin-bottom: 30px;
        }
        &_left{
          width: 746px;
          float: left;
          @include media($middle){
            width: auto;
            float: none;
            text-align: center;
            margin-bottom: 2.5%;
          }
        }
        &_right{
          width: 330px;
          float: right;
          @include media($middle){
            width: auto;
            float: none;
            @include clearfix;
            text-align: center;
          }
          &_item{
            @include media($middle){
              @include span-columns(6);
              @include omega(2n);
            }
            &:first-child{
              margin-bottom: 5px;
              @include media($middle){
                margin-bottom: 0;
              }
            }
          }
        }
      }
      &_contents{
        position: relative;
        background: #fff;
        border-radius: 10px;
        padding: 50px 50px 0;
        color: #000;
        @include clearfix;
        @include media($middle){
          padding: 15px;
          border-radius: 5px;
        }
        &_txt{
          font-size: 1.4rem;
          width: 700px;
          float: left;
          margin-bottom: 0;
          @include media($middle){
            font-size: 1.3rem;
            width: auto;
            float: none;
          }
        }
        &_thumb{
          width: 250px;
          float: right;
          margin-top: 0;
          @include media($middle){
            width: 190px;
            float: none;
            margin: 0 auto 15px;
          }
        }
      }
    }
  }

}
