.office{
  &_box{
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 6%, #efebe2 6%, #efebe2 100%);
    @include media($middle){
      padding: 0 5%;
    };
  }
  &_list{
    margin-top: 50px;
    padding-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    @include outer-container($content-width);
    @include media($middle){
      padding-bottom: 50px;
    }
    &_item{
      width: 530px;
      padding-bottom: 25px;
      margin-right: 20px;
      @include omega(2n);
      text-align: center;
      background: #ffffff;
      margin-bottom: 30px;
      @include media($middle){
        width: 100%;
        margin: 0 0 20px 0;
      }
      &_thumb{
        text-align: center;
        margin-bottom: 30px;
        @include media($middle){
          margin-bottom: 20px;
        }
      }
      &_contents{
        padding: 0 30px 0px;
        @include media($middle){
          padding: 0 5px 0px;
        }
      }
      &_heading{
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 3rem;
        letter-spacing: 0.05em;
        line-height: 1.4;
        margin-bottom: 0;
        @include media($middle){
          font-size: 1.8rem;
        }
        &:after{
          content: "";
          width: 42px;
          height: 1px;
          background: #fff;
          display: block;
          margin: 10px auto 0;
          @include media($middle){
            width: 30px;
          }
        }
      }
      &_area{
        font-size: 1.4rem;
        margin-bottom: 25px;
        @include media($middle){
          font-size: 1.4rem;
          margin-bottom: 0px;
        }
      }
      &_list{
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        line-height: 1.6;
        margin-bottom: 20px;
        @include media($middle){
          line-height: 1.8;
        }
        &_item{
          width: 49%;
          border-bottom: 1px solid #babeac;
          padding: 2px 16px;
          font-size: 1.4rem;
          margin: 0;
          @include media($middle){
            margin-bottom: 5px;
            padding: 2px 15px;
          }
          a{
            display: block;
          }
        }
        &_txt{
          display: inline-block;
          vertical-align: middle;
        }
        &_num{
          display: inline-block;
          vertical-align: middle;
          font-size: 2rem;
          letter-spacing: 0.05em;
          @include media($middle){
            font-size: 1.6rem;
          }
        }
      }
      &_link{
        text-align: left;
        margin-bottom: 0;
        @include media($middle){
          text-align: center;
        };
        a{
          text-align: center;
        }
      }
    }
  }
}
