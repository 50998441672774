.faq{
  padding-bottom: 0;

  &_head{
    margin-bottom: 80px;
    @include media($middle){
      margin-bottom: 45px;
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
  }

  &_container{
    padding-bottom: 120px;
    background: #efebe2;
    @include media($middle){
      padding: 0 15px 45px;
    }
  }

  &_content-width{
   @include outer-container($content-width);
  }

  &_item{
    @include clearfix;
    position: relative;
    margin-bottom: 70px;
    @include media($middle){
      margin-bottom: 20px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    .btn-list-02{
      margin-top: 25px;
      @include media($middle){
        margin-top: 15px;
      }
    }
    &_question{
      &_container{
        &:after{
        }
      }
      &_heading{
        font-size: 2.4rem;
        font-weight: bold;
        padding-left: 50px;
        line-height: 1;
        @include media($middle){
          padding-left: 0;
          font-size: 1.8rem;
          line-height: 1.4;
        }
        &:before{
          content: "Q.";
          font-family: $font-helvetica;
          color: #babdab;
          font-size: 7rem;
          font-weight: 500;
          vertical-align: middle;
          position: relative;
          bottom: 3px;
          margin-right: 10px;
          @include media($middle){
            font-size: 3.6rem;
            display: block;
            margin: 0 auto 0;
            text-align: center;
            position: static;
          }
        }
      }
    }
    &_answer{
      background: #fff;
      border-radius: 20px;
      padding: 50px;
      @include media($middle){
        border-radius: 10px;
        padding: 20px 15px;
      }
      &_txt{
        line-height: 2.2;
        margin-bottom: 35px;
        @include media($middle){
          line-height: 1.8;
          margin-bottom: 20px;
        }
        &--no-mb{
          margin-bottom: 0;
        }
      }
    }
  }

}
