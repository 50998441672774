.footer{
  padding: 60px 0 0;
  background: #ffffff;
  @include media($middle){
    padding: 30px 0 0px;
  }
  &_company{
    text-align: center;
    margin-bottom: 70px;
    @include media($middle){
      margin-bottom: 20px;
    };
    &_logo{
      margin-bottom: 26px;
      @include media($middle){
        width: 170px;
        margin: 0 auto 20px;
      };
    }
    &_txt{
      font-size: 1.4rem;
    }
  }

  &_navi-home{
    font-weight: 400;
    font-size: 1.4rem;
    @include media($middle){
      font-size: 1.4rem;
    }
    a{
      color: #aea4a0;
      &:before{
        font-family: FontAwesome;
        content: "\f0da";
        font-size: 1.4rem;
        margin: 0 10px;
      }
    }
  }

  &_navi{
    @include outer-container($content-width);
    padding-bottom: 30px;
    @include media($middle){
      display: none;
    }
    &_list{
      display: flex;
      justify-content: space-around;
    }
    &_col{
      line-height: 2;
      letter-spacing: 0;
      @include media($middle){
        float: none;
        width: auto;
        margin-bottom: 15px;
      }
      &_heading{
        font-size: 1.4rem;
        margin-bottom: 5px;
        font-weight: bold;
        @include media($middle){
          font-size: 1.6rem;
        }
      }
      &_list{
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 30px;
        @include media($middle){
          font-size: 1.4rem;
        }
        &_item{
          margin-bottom: 5px;
          &--blank{
            a{
              &:after{
                content: "";
                display: inline-block;
                background: url(./assets/img/footer_linkmark.png) no-repeat center center;
                background-size: cover;
                width: 15px;
                height: 15px;
                margin-left: 10px;
              }
            }
          }
          a{
            display: flex;
            align-items: center;
          }
          &_right{
            font-size: 1rem;
            display: inline-block;
            margin-right: 7px;
          }
        }
      }
    }
  }

  &_bnr{
    width: 180px;
    float: right;
    &_item{
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &_sub-navi{
    @include outer-container($content-width);
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 50px;
    @include media($middle){
      font-size: 1.2rem;
      line-height: 2.2;
      margin-bottom: 40px;
    }
    &_item{
      display: inline-block;
      margin: 0 10px;
      a{
        display: flex;
        align-items: center;
      }
    }
  }

  &_bottom{
    padding: 20px 0;
    background-color: #333333;
    @include media($middle){
      padding: 25px 15px 70px;
    }
    &_container{
      @include outer-container($content-width);
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media($middle){
        flex-direction: column-reverse;
      };
    }
    &_list{
      display: flex;
      @include media($middle){
        flex-direction: column-reverse;
        text-align: center;
      };
      &_item{
        font-size: 1.4rem;
        color: #ffffff;
        margin-right: 44px;
        @include media($middle){
          font-size: 1.2rem;
          margin-right: 10px;
        };
        a{
          font-size: 1.4rem;
          color: #ffffff;
          @include media($middle){
            font-size: 1.2rem;
          };
        }
      }
    }

    &_sns{
      text-align: center;
      margin-bottom: 0px;
      margin-left: auto;
      display: flex;
      align-items: center;
      line-height: 1;
      @include media($middle){
        margin-left: 0;
        margin-bottom: 30px;
      }
      &_txt{
        //display: inline-block;
        font-size: 1.8rem;
        color: #666666;
        margin-right: 45px;
        font-family: $font-helvetica;
        font-weight: 500;
        //vertical-align: middle;
        @include media($middle){
          font-size: 1.6rem;
          margin-right: 20px;
        };
      }
      &_item{
        display: inline-block;
        margin: 0 5px;
        vertical-align: middle;
        position: relative;
        top: 2px;
        @include media($middle){
          width: 25px;
        }
        a{
          display: block;
        }
      }
    }
  }

  &_copy{
    display: block;
    text-align: center;
    color: #999999;
    font-size: 1.2rem;
  }

}
