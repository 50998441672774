.works{
  &_bnr{
    text-align: center;
    background: #f1ece2;
    padding: 60px 0 100px;
    @include media($middle){
      padding: 30px 15px 30px;
    }
    &_container{
      @include outer-container($content-width);
    }
  }
}
.works-archive{

  &_container{
    @include outer-container($content-width);
    margin-bottom: 45px;
    @include media($middle){
      margin-bottom: 15px;
      padding: 0 15px;
    }
  }

  &_tm-list{
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    font-size: 0;
    letter-spacing: 0;
    @include media($middle){
      @include clearfix;
      padding: 0 15px;
      margin-bottom: 10px;
    }
    &_item{
      display: inline-block;
      background: #f1ece2;
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      @include media($middle){
        font-size: 1.2rem;
        width: 49.5%;
        float: left;
        margin-right: 1%;
        @include omega(2n);
        margin-bottom: 1%;
        letter-spacing: 0;
      }
      &:first-child{
        border-radius: 20px 0 0 0;
        @include media($middle){
          border-radius: 0;
        }
      }
      &:last-child{
        border-radius: 0 20px 0 0 ;
        @include media($middle){
          border-radius: 0;
        }
      }
      &.current{
        color: #fff;
        background: #ae976a;
        a{
          color: #fff;
        }
      }
      a{
        display: inline-block;
        padding: 20px 25px;
        @include media($middle){
          padding: 8px 5px;
        }
      }
    }
  }

}

.works-archive-list{
  //@include clearfix;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &_item{
    width: 530px;
    background: #ffffff;
    margin-bottom: 3%;
    border-bottom: 1px solid #dedee0;
    @include media($middle){
      width: 49%;
    }

    @include media($mobile){
      width: 100%;
    }
    &_thumb{
      position: relative;
      img{
        width: 100%;
        height: auto;
      }
    }
    &_icon-movie{
      display: inline-block;
      width: 65px;
      position: absolute;
      right: 10px;
      top: 10px;
      @include media($middle){
        width: 40px;
        right: 5px;
        top: 5px;
      }
    }
    &_contents{
      border: 1px solid #dedee0;
      border-bottom: none;
      position: relative;
      padding: 20px 30px;
      @include media($middle){
        padding: 15px 15px;
        min-height: 140px;
      }
      @include media($mobile){
        min-height: auto;
      }
      &:after{
        content: "";
        display: inline-block;
        width: 12px;
        height: 22px;
        background: url(./assets/img/icon_arrow_white_right.png) no-repeat center center;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto;
        @include media($middle){
          width: 6px;
          height: 11px;
          right: 10px;
        }
      }
      &_num{
        font-size: 7.5rem;
        font-weight: bold;
        font-family: $font-helvetica;
        position: absolute;
        top: -70px;
        right: 22px;
        @include media($middle){
          font-size: 5rem;
          top: -40px;
          right: 15px;
        };
      }
      &_empty{
        padding-top: 26px;
        @include media($middle){
          padding-top: 23px;
        };
      }
    }
    &_cats{
      font-size: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p{
        margin-right: 10px;
        @include media($middle){
          margin-right: 5px;
        }
      }
    }
    &_category{
      color: #333333;
      background-color: #f1ece2;
      font-size: 1.4rem;
      display: inline-block;
      line-height: 1.7;
      font-weight: bold;
      padding: 0 10px;
      min-width: 108px;
      text-align: center;
      @include media($middle){
        font-size: 1.1rem;
        letter-spacing: 0;
        min-width: 60px;
        padding: 3px 5px;
        line-height: 1.3;
      }
    }
    &_type-category{
      background-color: #ccc;
      font-size: 1.4rem;
      display: inline-block;
      line-height: 1.7;
      padding: 0 10px;
      min-width: 108px;
      text-align: center;
      @include media($middle){
        font-size: 1.1rem;
        letter-spacing: 0;
        min-width: 60px;
        padding: 3px 5px;
        line-height: 1.3;
      }
    }
    &_heading{
      font-weight: 500;
      margin-bottom: 25px;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      @include media($middle){
        font-size: 1.3rem;
        line-height: 1.3;
        margin-bottom: 15px;
      }
    }
    &_area{
      margin-bottom: 0;
      line-height: 1.2;
      font-size: 1.4rem;
      margin-bottom: 9px;
      @include media($middle){
        font-size: 1.2rem;
      }
    }

    &_staff{
      margin-bottom: 10px;
      @include media($middle){
        margin-bottom: 0;
      };
      &_name{
        font-size: 1.3rem;
        padding-bottom: 2px;
        text-decoration: underline;
        @include media($middle){
          font-size: 1rem;
        };
      }
      span{
        font-size: 1.3rem;
        font-weight: bold;
        background-color: #333333;
        color: #fff;
        display: inline-block;
        padding: 0 10px;
        margin-right: 10px;
        line-height: 1.8;
        @include media($middle){
          font-size: 1rem;
          padding: 0 5px;
          margin-right: 3px;
        };
      }
    }
  }
  /*
  &_item{
    background: #645b57;
    text-align: center;
    @include media($large){
      border-left: 1.5px solid #4e4743;
      border-right: 1.5px solid #4e4743;
      width: 33.33%;
      float: left;
      margin-bottom: 40px;
    }
    @include media($middle){
      @include span-columns(6);
      @include omega(2n);
      margin-bottom: 2%;
    }
    &_contents{
      position: relative;
      padding: 20px 30px;
      @include media($middle){
        padding: 15px;
      }
      &:after{
        content: "";
        display: inline-block;
        width: 12px;
        height: 22px;
        background: url(./assets/img/icon_arrow_white_right.png) no-repeat center center;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto;
        @include media($middle){
          width: 6px;
          height: 11px;
          right: 10px;
        }
      }
    }
    &_thumb{
      text-align: center;
      position: relative;
    }
    &_icon-movie{
      position: absolute;
      right: 15px;
      bottom: 15px;
      @include media($middle){
        right: 5px;
        bottom: 5px;
        img{
          width: 30px;
        }
      }
    }
    &_category{
      color: #9a9390;
      font-size: 1.4rem;
      text-decoration: underline;
      display: inline-block;
      margin-bottom: 5px;
      @include media($middle){
        font-size: 1.2rem;
      }
    }
    &_heading{
      font-weight: 600;
      margin-bottom: 0;
      font-size: 1.6rem;
      line-height: 1.4;
      margin-bottom: 5px;
      @include media($middle){
        font-size: 1.4rem;
      }
    }
    &_area{
      margin-bottom: 0;
      font-size: 1.4rem;
      @include media($middle){
        font-size: 1.2rem;
      }
    }
  }
  */
}

.works-single{
  padding-bottom: 0;
  &_container{
    @include outer-container($content-width);
    margin-bottom: 45px;
    @include media($middle){
      padding: 0 15px;
      margin-bottom: 30px;
    }
  }
  &_head{
    margin-bottom: 50px;
    text-align: center;
    @include media($middle){
      margin-bottom: 20px;
    }
    &_heading{
      font-size: 3.4rem;
      font-weight: bold;
      margin-bottom: 15px;
      padding: 5px 0;
      @include media($middle){
        font-size: 1.8rem;
        margin-bottom: 10px;
      }
    }
    &_area{
      margin-bottom: 10px;
      @include media($middle){
        margin-bottom: 5px;
      }
    }
  }
  &_thumb{
    margin-bottom: 50px;
    @include media($middle){
      margin-bottom: 30px;
    }
  }
  &_col-02{
    @include clearfix;
    margin-bottom: 45px;
    @include media($middle){
      margin-bottom: 30px;
    }
    &_content{
      width: 520px;
      float: left;
      font-size: 1.6rem;
      @include media($middle){
        width: auto;
        float: none;
        font-size: 1.4rem;
        margin-bottom: 30px;
      }
    }
    &_point{
      width: 380px;
      float: right;
      background: #f1ece2;
      padding: 30px;
      @include media($middle){
        width: auto;
        float: none;
        font-size: 1.4rem;
      }
      &_heading{
        font-size: 2rem;
        font-weight: 600;
        border-bottom: 2px solid #333;
        padding-bottom: 15px;
        margin-bottom: 15px;
        text-align: center;
        @include media($middle){
          font-size: 1.6rem;
        }
      }
      &_txt{
        font-size: 1.6rem;
        margin-bottom: 0;
        @include media($middle){
          font-size: 1.4rem;
        }
      }
    }
  }
  &_more{
    @include outer-container(880px);
  }
  &_more_movie{
    text-align: center;
    margin-bottom: 20px;
    @include media($middle){
      margin-bottom: 10px;
      iframe{
        width: auto;
        height: auto;
      }
    }
  }
  &_new{
    background: #f1ece2;
    padding: 80px 0;
    margin-top: 120px;
    //margin-bottom: -100px;
    @include media($middle){
      padding: 60px 0;
      margin-top: 60px;
      //margin-bottom: -60px;
    }
    &_container{
      position: relative;
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    .works-archive-list{
      display: flex;
      justify-content: space-between;
      .works-archive-list_item{
        width: 350px;
        margin: 0;
      }
    }
  }
}

.works-search{
  &-form{
    &_list{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      @include media($middle){
        margin-bottom: 20px;
      }
      @include media($mobile){
        margin-bottom: 15px;
      }
      &_item{
        margin-right: 5px;
        margin-bottom: 5px;
        input[type=checkbox]{
          display: none !important;
        }
        input[type="checkbox"]:checked + label { 
          background: #ae976a; 
          color: #ffffff; 
        } 
        label{
          cursor: pointer;
          display: block;
          margin-bottom: 0;
          font-size: 1.4rem;
          font-weight: bold;
          background-color: #f1ece2;
          line-height: 1;
          white-space: nowrap;
          padding: 13px 20px;

          @include media($middle){
            font-size: 1.3rem;
          }
          @include media($mobile){
            font-size: 1.2rem;
            padding: 10px 15px;
          }
        }
      }
    }
  }
  &-btn{
    text-align: center;
    button{
      background-color: #ae976a;
      font-size: 1.4rem;
      font-weight: bold;
      color: #fff;
      width: 300px;
      height: 60px;
      border-radius: 0;
      @include media($middle){
        width: calc(300px * 0.8);
        height: calc(60px * 0.8);
        font-size: 1.3rem;
      }
      @include media($mobile){
        width: calc(300px * 0.6);
        height: calc(60px * 0.6);
        font-size: 1.2rem;
      }
      &:hover{
        background-color: #333;
      }
    }
  }
}
