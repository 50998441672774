.consultation{

  &_head{
    &_container{
      @include outer-container($content-width);
      position: relative;
      padding: 0 0 100px;
      @include media($middle){
        padding: 0 15px;
        margin-bottom: 30px;
      }
    }
    &_txt{
      margin-bottom: 0;
    }
  }

  &_soudan{
    margin-bottom: 50px;
    @include media($middle){
      margin-bottom: 15px;
    }
    &_white{
      position: relative;
      color: #000;
      margin-bottom: 50px;
      @include media($middle){
        margin-bottom: 20px;
      }

      .heading-ptn-04{
        margin-bottom: -50px;
        @include media($middle){
          padding: 15px 0;
          width: 90%;
          margin: 0 auto -40px;
          line-height: 1.3;
          font-size: 1.8rem;
        }
      }


      &_container{
        display: flex;
        background: #caccbe;
        @include media($middle){
          padding: 45px 15px 20px;
        }
      }
      &_left{
        width: 45%;
        @include media($middle){
          width: auto;
          float: none;
          margin-bottom: 15px;
        }
      }
      &_right{
        display: flex;
        padding-top: 6%;
        padding-left: 10%;
        @include media($middle){
          padding-top: 6%;
          padding-left: 0;
        }

        &_img{
          margin-bottom: 30px;
          @include media($middle){
            margin-bottom: 20px;
          };
        }
      }
      &_heading{
        margin-bottom: 20px;
      }
      &_list{
        line-height: 1.5;
        letter-spacing: 0;
        @include media($middle){
          line-height: 1.4;
        }
        &_item{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          @include media($middle){
            margin-bottom: 10px;
          }
          i{
            display: inline-block;
            vertical-align: middle;
            @include media($middle){
              width: 30px;
            }
          }
          &_txt{
            font-size: 2rem;
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 0 10px;
            @include media($middle){
              font-size: 1.3rem;
              max-width: 280px;

            }
            &--pt{
              padding-top: 15px;
              @include media($middle){
                padding-top: 0;
              }
            }
          }
        }
      }
    }
    &_bottom-txt{
      text-align: center;
      font-size: 2.5rem;
      font-family: $font-noto;
      font-weight: 500;
      margin-bottom: 0;
      @include media($middle){
        font-size: 1.6rem;
        padding: 0 15px;
      }
    }
  }

  .cmp-form{
    select{
      width: auto;
      display: inline-block;
      margin-right: 5px;
    }
  }

}
