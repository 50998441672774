.flow{
  padding-bottom: 0;

  &_head{
    margin-top: -15px;
    margin-bottom: 90px;
    @include media($middle){
      margin-bottom: 45px;
      padding: 0 15px;
    }
    &_container{
      @include outer-container($content-width);
      border: 6px solid #babeac;
      @include media($middle){
        padding: 20px 15px;
        border: 3px solid #babeac;
      }
    }
    &_schedule{
      background: #fff;
      padding: 30px 40px;
      color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media($middle){
        padding: 0;
        display: block;
        text-align: center;
      }
      &_heading{
        margin: 0;
        font-size: 2.5rem;
        font-weight: 500;
        text-align: center;
        width: 250px;
        @include media($middle){
          width: auto;
          display: block;
          text-align: center;
          font-size: 1.8rem;
          margin: 0 0 15px;
        }
      }
      &_image{
        @include media($middle){
          display: block;
        }
      }
    }
  }

  &_step{
    background: #efebe2;
    padding: 90px 0 90px;
    @include media($middle){
      padding: 45px 0;
    }
    .heading-ptn-02{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
  }

  &_seminar{
    border: 5px solid #babdab;
    background: #fff;
    padding: 50px;
    margin-bottom: 60px;
    @include media($middle){
      border: 3px solid #babdab;
      padding: 30px 20px;
      margin-bottom: 20px;
    }
    &_heading{
      text-align: center;
      font-weight: bold;
      font-size: 2.6rem;
      letter-spacing: 0.05em;
      margin-bottom: 40px;
      @include media($middle){
        font-size: 1.4rem;
        margin-bottom: 15px;
        line-height: 1.6;
        letter-spacing: 0;
      }
      span{
        font-size: 3rem;
        color: #737a51;
        @include media($middle){
          font-size: 1.6rem;
          display: block;
        }
      }
    }
    &_txt{
      margin-bottom: 20px;
      @include media($middle){
        font-size: 1.2rem;
        margin-bottom: 15px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &_step-sec{
    &.flow_step-01{
      margin-bottom: 100px;
      @include media($middle){
        margin-bottom: 30px;
      }
    }
    &.flow_step-02{
      margin-bottom: 100px;
      @include media($middle){
        margin-bottom: 30px;
      }
    }
    &.flow_step-03{
      margin-bottom: 100px;
      @include media($middle){
        margin-bottom: 30px;
      }
    }
    &.flow_step-04{
    }
    &_container{
      @include outer-container($content-width);
      @include media($middle){
        padding: 0 15px;
      }
    }
    &_heading{
      font-size: 3rem;
      font-weight: 500;
      padding: 4px;
      margin-bottom: 50px;
      background: #969b7d;
      display: flex;
      align-items: center;
      @include media($middle){
        display: block;
        font-size: 1.8rem;
        line-height: 1.4;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
      &_num{
        display: block;
        position: relative;
        font-family: $font-helvetica;
        line-height: 1;
        margin-right: 20px;
        background: #fff;
        font-size: 4.4rem;
        color: #969b7d;
        letter-spacing: 0.05em;
        padding: 20px 30px;
        font-weight: 500;
        @include media($middle){
          font-size: 2rem;
          width: 220px;
          padding: 10px;
          text-align: center;
          margin: 0 auto 10px;
        }
        &:after{
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 0 10px 16px;
          border-color: transparent transparent transparent #ffffff;
          position: absolute;
          top: 0;
          right: -16px;
          bottom: 0;
          margin: auto;
          @include media($middle){
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: #ffffff transparent transparent transparent;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: -46px;
            margin: auto;
          }
        }
      }
      &_ja{
        display: block;
        color: #fff;
        padding-left: 15px;
        font-weight: bold;
        @include media($middle){
          display: block;
          font-size: 1.6rem;
          text-align: center;
          padding: 0 5px;
        }
      }
    }
    &_inner{
    }
    &_item{
      @include outer-container(980px);
      background: #fff;
      padding: 50px;
      margin-bottom: 25px;
      @include media($middle){
        padding: 30px 15px;
      }
      &_heading{
        text-align: center;
        font-weight: bold;
        font-size: 2.6rem;
        margin-bottom: 30px;
        @include media($middle){
          font-size: 1.8rem;
          margin-bottom: 20px;
        }
        span{
          display: inline-block;
          position: relative;
          min-width: 560px;
          @include media($middle){
            min-width: 200px;
          }
          &::before{
            content:"";
            display: inline-block;
            width: 28px;
            height: 28px;
            background: url('./assets/img/flow_step-sec_inner_heading_before.png') no-repeat center;
            background-size: cover;
            position: absolute;
            top: -15px;
            left: -29px;
            @include media($middle){
              width: 20px;
              height: 20px;
              top: -12px;
              left: -13px;
            };
          }
          &::after{
            content:"";
            display: inline-block;
            width: 28px;
            height: 28px;
            background: url('./assets/img/flow_step-sec_inner_heading_after.png') no-repeat center;
            background-size: cover;
            position: absolute;
            bottom: -15px;
            right: -26px;
            @include media($middle){
              width: 20px;
              height: 20px;
              bottom: -12px;
              right: -13px;
            };
          }
        }
      }
    }
    &_col-02{
      &_item{
        @include clearfix;
        margin-bottom: 60px;
        @include media($middle){
          margin-bottom: 30px;
        }
        &:last-child{
          margin-bottom: 0;
        }
        &_left{
          width: 340px;
          float: left;
          @include media($middle){
            width: auto;
            float: none;
            text-align: center;
            margin-bottom: 15px;
          }
        }
        &_right{
          width: 500px;
          float: right;
          @include media($middle){
            width: auto;
            float: none;
          }
        }
        &_heading{
          font-size: 2.5rem;
          font-weight: 500;
          margin-bottom: 15px;
          @include media($middle){
            font-size: 1.8rem;
            margin-bottom: 10px;
          }
          &--image{
            @include media($middle){
              text-align: center;
              img{
                width: 210px;
              }
            }
          }
        }
        &_txt{
          margin-bottom: 20px;
          font-size: 1.4rem;
          letter-spacing: 0;
          @include media($middle){
            font-size: 1.4rem;
            line-height: 1.6;
            margin-bottom: 20px;
          }
          &--no-mb{
            margin-bottom: 0;
            @include media($middle){
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    &_flow-list{
      &_item{
        @include media($middle){
          background: #fff;
        }
        &:after{
          content: "";
          display: block;
          width: 27px;
          height: 20px;
          background: url(./assets/img/flow_step-sec_flow-list_item_arrow.png) no-repeat center center;
          background-size: cover;
          margin: 10px auto;
          @include media($middle){
            width: 13px;
            height: 10px;
            position: relative;
            top: 10px;
          }
        }
        &--last{
          &:after{
            content: "";
            display: block;
            width: 69px;
            height: 51px;
            background: url(./assets/img/flow_step-sec_flow-list_item_arrow.png) no-repeat center center;
            background-size: cover;
            margin: 15px auto;
            @include media($middle){
              width: 35px;
              height: 25px;
              position: relative;
              top: 20px;
            }
          }
        }
        &_container{
          position: relative;
          @include clearfix;
          background: #fff;
          padding: 50px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          @include media($middle){
            padding: 15px;
            display: block;
          }
          &--no-pt{
            padding-top: 0;
          }
        }
        &_num{
          width: 70px;
          color: #babeac;
          position: relative;
          display: inline-block;
          font-size: 7rem;
          font-weight: 500;
          vertical-align: top;
          font-family: $font-helvetica;
          vertical-align: middle;
          letter-spacing: 0.05em;
          margin-bottom: 0;
          line-height: 1;
          @include media($middle){
            font-size: 3.2rem;
            letter-spacing: 0;
            margin: 0;
            float: none;
            margin: 0 auto 15px;
            display: block;
            text-align: center;
          }
          &:last-child{
            margin-right: 0;
          }
          span{
            border-bottom: 5px solid #babeac;
            @include media($middle){
              border-bottom: 2px solid #babeac;
            }
          }
        }
        &_contents{
          width: 550px;
          color: #000;
          margin-left: 40px;
          margin-right: 30px;
          @include media($middle){
            width: auto;
            float: none;
            margin: 0 0 10px 0;
          }
          &_heading{
            font-size: 2.6rem;
            font-weight: 500;
            margin-bottom: 15px;
            @include media($middle){
              font-size: 1.8rem;
              text-align: center;
            }
          }
          &_txt{
            font-size: 1.4rem;
            margin-bottom: 0;
            @include media($middle){
              font-size: 1.2rem;
            }
          }
        }
        &_thumb{
          width: 165px;
          @include media($middle){
            float: none;
            margin: 0 auto;
          }
        }
      }
    }
    &_bottom{
      background: #fff;
      border: 3px solid #ffffff;
      text-align: center;
      padding: 30px 15px;
      border: 5px solid #babdab;
      @include media($middle){
        padding: 20px 15px;
        border: 2px solid #ffffff;
      }
      &_heading{
        font-size: 2.6rem;
        font-weight: 500;
        margin-bottom: 15px;
        @include media($middle){
          font-size: 2rem;
        }
      }
      &_txt{
        font-size: 1.4rem;
        margin: 0;
        @include media($middle){
          font-size: 1.3rem;
        }
      }
    }
  }

}
