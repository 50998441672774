.privacy{
  padding-bottom: 90px;
  @include media($middle){
    padding-bottom: 60px;
  }
  &_container{
    @include outer-container($content-width);
    @include media($middle){
      padding: 0 15px;
    }
  }

  &_txt{
    font-size: 1.6rem;
    margin-bottom: 60px;
    @include media($middle){
      font-size: 1.4rem;
      margin-bottom: 30px;
    }
  }

  &_sec{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
    &_heading{
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 20px;
      background: #f9f7f3;
      padding: 15px 20px 15px 20px;
      font-weight: bold;
      border-top: 2px solid #babdab;
      @include media($middle){
        font-size: 1.8rem;
        margin-bottom: 15px;
        padding: 10px 10px 10px 10px;
      }
    }
    &_txt{
      font-size: 1.4rem;
      margin-bottom: 0;
      padding: 0 20px;
      line-height: 2;
      @include media($middle){
        padding: 0;
      }
    }
  }

}
