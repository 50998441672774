.lism{
  &_heading{
    text-align: center;
    @include outer-container(900px);
    border-radius: 20px 20px 0 0;
    margin-top: -60px;
    background: #fff;
    padding: 40px 10px;
    @include media($middle){
      border-radius: 10px 10px 0 0;
      margin-top: -30px;
      padding: 20px 20px;
    }
  }
  &_head{
    background: #fff;
    padding-bottom: 75px;
    @include media($middle){
      padding: 15px 15px 30px;
    }
    &_container{
      @include outer-container($content-width);
      position: relative;
    }
    &_heading{
      text-align: center;
      color: #333333;
      font-size: 3rem;
      font-weight: 400;
      line-height: 1.8;
      margin-bottom: 60px;
      @include media($middle){
        font-size: 1.6rem;
        margin-bottom: 20px;
        br{
          display: none;
        }
        img{
          width: 90px;
        }
      }
    }
    &_icons{
      text-align: center;
      @include media($middle){
        @include clearfix;
      }
      &_item{
        display: inline-block;
        vertical-align: bottom;
        margin: 0 29px;
        @include media($middle){
          padding: 5px;
          margin: 0px;
        }
        &:first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

  &_catch{
    background: #efebe2;
    padding: 75px 0;
    @include media($middle){
      padding: 30px 15px;
    }
    &_heading{
      text-align: center;
      font-size: 2.8rem;
      font-weight: 500;
      line-height: 1.8;
      margin-bottom: 0;
      @include media($middle){
        font-size: 1.8rem;
        img{
          @include media($middle){
            width: 100px;
          }
        }
      }
    }
  }

  &_about{
    position: relative;
    background: #f9f3eb;
    padding-bottom: 120px;
    @include media($middle){
      padding-bottom: 45px;
    }
    &_thumb{
      text-align: center;
      img{
        width: 100%;
        height: auto;
      }
    }
    &_head{
      text-align: center;
      background: #fff;
      padding: 45px 0;
      @include media($middle){
        padding: 30px 15px;
      }
      &_container{
        @include outer-container($content-width);
        position: relative;
      }
      &_heading{
        margin-bottom: 0;
      }
      &_abs{
        position: absolute;
        @include media($middle){
        }
        &--01{
          right: -5px;
          top: -135px;
          @include media($middle){
            width: 70px;
            right: -5px;
            top: -50px;
          }
        }
        &--02{
          left: -120px;
          bottom: -100px;
          @include media($middle){
            width: 110px;
            left: -45px;
            bottom: -75px;
          }
        }
      }
    }
    &_images{
      text-align: center;
      margin-bottom: 45px;
      @include media($middle){
        margin-bottom: 20px;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    &_card{
      @include outer-container($content-width);
      margin-bottom: 70px;
      text-align: center;
      @include media($middle){
        position: relative;
        margin-bottom: 20px;
        padding: 0 10px;
      }
    }
    &_insta{
      @include outer-container(880px);
      @include media($middle){
        text-align: center;
        padding: 0 10px;
      }
    }
  }

  &_lifestyle{
    position: relative;
    @include media($middle){
      margin-top: 0;
    }
    &_thumb{
      text-align: center;
    }
    &_head{
      position: relative;
      z-index: 0;
      text-align: center;
      padding: 45px 0 60px;
      @include media($middle){
        padding: 30px 15px;
      }
      &_heading{
        margin-bottom: 30px;
        @include media($middle){
          margin-bottom: 20px;
          img{
            width: 260px;
          }
        }
      }
      &_txt{
        font-size: 1.8rem;
        margin-bottom: 30px;
        color: #000;
        @include media($middle){
          font-size: 1.2rem;
          margin-bottom: 15px;
        }
      }
      &_hash{
        text-align: center;
        @include outer-container(600px);
        font-size: 0;
        letter-spacing: 0;
        &_item{
          background: #b8a57b;
          color: #fff;
          display: inline-block;
          padding: 3px 30px;
          font-size: 1.6rem;
          margin: 5px;
          @include media($middle){
            font-size: 1.1rem;
            padding: 2px 10px;
            margin: 2px;
          }
        }
      }
    }
    &_images{
      text-align: center;
      img{
        width: 100%;
        height: auto;
      }
    }
  }

  &_lifestyle-info{
    padding: 60px 0 100px;
    @include media($middle){
      padding: 45px 15px;
    }
    &_heading{
      text-align: center;
      margin-bottom: 45px;
      @include media($middle){
        margin-bottom: 30px;
      }
      &_en{
        display: block;
        font-family: $font-helvetica;
        font-size: 6rem;
        font-weight: 500;
        letter-spacing: 0.1em;
        @include media($middle){
          font-size: 3.2rem;
        }
      }
      &_ja{
        display: block;
        font-size: 1.4rem;
        letter-spacing: 0.2em;
        margin-top: 5px;
        @include media($middle){
          font-size: 1.2rem;
          letter-spacing: 0.1em;
        }
      }
    }
    &_container{
      @include outer-container($content-width);
    }
    &_col-02{
      margin-bottom: 100px;
      @include clearfix;
      @include media($middle){
        margin-bottom: 30px;
      }
    }
    &_detail{
      width: 480px;
      float: left;
      background: #fff;
      color: #333;
      padding-top: 15px;
      @include media($middle){
        width: 100%;
        height: auto;
        padding: 30px 15px;
        margin-bottom: 15px;
        padding: 0;
      }
      &_heading{
        text-align: center;
        font-family: $font-helvetica;
        color: #333;
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: 0.2em;
        margin-bottom: 40px;
        font-weight: 500;
        @include media($middle){
          font-size: 2.4rem;
          letter-spacing: 0.1em;
          margin-bottom: 20px;
        }
        &_ja{
          display: block;
          font-size: 1.4rem;
          font-family: $font-noto;
          font-weight: 400;
          letter-spacing: 0.05em;
          margin-top: 15px;
          @include media($middle){
            font-size: 1.2rem;
            margin-top: 10px;
          }
        }
      }
      &_txt{
        text-align: center;
        font-size: 1.3rem;
        margin-bottom: 20px;
        @include media($middle){
          font-size: 1.2rem;
        }
      }
      &_table{
        width: 100%;
        border-top: 2px solid #ebebeb;
        margin-bottom: 0;
        line-height: 1.7;
        @include media($middle){
          font-size: 1.3rem;
        }
        tr{
          &:first-child{
            td,th{
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
          th{
            border-bottom: 2px solid #ebebeb;
            width: 140px;
            padding: 12px 0 12px 30px;
            font-weight: normal;
            @include media($middle){
              padding: 10px;
              width: 80px;
            }
          }
          td{
            border-bottom: 2px solid #ebebeb;
            padding: 12px 10px 12px 0;
            @include media($middle){
              padding: 10px;
              width: auto;
            }
            a{
              font-weight: bold;
              font-size: 2.4rem;
              color: #333;
              line-height: 1.3;
              letter-spacing: 0.05em;
              @include media($middle){
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
    &_map{
      width: 50%;
      float: right;
      height: 325px;
      @include media($middle){
        width: auto;
        float: none;
        height: auto;
      }
      iframe{
        width: 100%;
        height: 100%;
        @include media($middle){
          height: 300px;
        }
      }
    }
    &_btn{
      text-align: center;
    }
  }

}
