.single{
  &_container{
    @include outer-container(800px);
    color: #000;
    margin-bottom: 50px;
    word-break: break-all;
    @include media($middle){
      margin: 0 0 30px;
      padding: 25px 15px;
    }
    a{
      color: #000;
      text-decoration: underline;
    }
  }
  &_head{
    align-items: center;
    @include media($middle){
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
    }
    &_heading{
      font-size: 3.2rem;
      font-weight: 600;
      margin-bottom: 45px;
      @include media($middle){
        font-size: 1.6rem;
        margin-bottom: 30px;

      }
    }
  }
  &_info{
    margin-bottom: 20px;
    @include media($middle){
      margin-bottom: 10px;
    }
    .post-archive_item_category{
      display: inline-block;
      margin-right: 15px;
    }
    .post-archive_item_time{
      display: inline-block;
    }
  }
  &_event-info{
    @include clearfix;
    display: table;
    width: 100%;
    margin-bottom: 30px;
    @include media($middle){
      padding: 10px 0;
      margin-bottom: 20px;
    }
    &_item{
      @include clearfix;
      display: table-row;
      @include media($middle){
        float: none;
        width: 100% !important;
      }
      dt{
        font-size: 1.4rem;
        vertical-align: middle;
        width: 80px;
        display: table-cell;
        padding: 5px 10px;
        @include media($middle){
          font-size: 1.2rem;
          width: 50px;
        }
      }
      dd{
        display: table-cell;
        font-size: 1.4rem;
        vertical-align: middle;
        padding: 5px 10px;
        @include media($middle){
          font-size: 1.2rem;
          margin-left: 5px;
        }
      }
    }
  }
  &_content{
    font-size: 1.6rem;
    margin-bottom: 45px;
    @include media($middle){
      font-size: 1.4rem;
      margin-bottom: 30px;
    }
    iframe{
      @include media($middle){
        width: 100%;
      }
    }
  }

  &_reservation{
    margin-top: 75px;
    @include media($middle){
      margin-top: 45px;
    }
    a{
      color: #000;
      text-decoration: underline;
      &.calendar-daylink,&.booking-timelink{
        color: #327bff;
        font-size: 1.8rem;
      }
    }
    .monthly-calendar caption{
      font-size: 1.4rem;
      font-weight: bold;
    }
    .monthly-prev-next{
      font-size: 1.4rem;
      font-weight: bold;
    }
    .day-calendar th, .day-calendar td{
      font-size: 1.4rem;
    }
  }

  .booking-form_container{
    #action-button{
      margin: 30px 0;
      button[type="submit"]{
        background: #000;
        color: #fff;
        border-radius: 0;
        padding: 15px 10px;
        width: 200px;
        font-family: $font-noto;
        font-weight: 500;
      }
    }
    .content-form table{
      br{
        display: none;
      }
      @include media($middle){
        display: block;
        width: 100%;
      }
    }
    .content-form table tbody{
      @include media($middle){
        display: block;
        width: 100%;
      }
    }
    .content-form table tr{
      @include media($middle){
        display: block;
        width: 100%;
      }
    }
    .content-form th{
      font-size: 1.4rem;
      @include media($middle){
        display: block;
        width: 100%;
        text-align: left;
        padding: 10px 0 0;
        border-bottom: none;
        border-right: none;
      }
    }
    .content-form legend{
      font-size: 1.4rem;
    }
    .content-form td{
      font-size: 1.4rem;
      @include media($middle){
        display: block;
        width: 100%;
        padding: 5px 0 10px;
        border-right: none;
      }
    }
    .content-form input, .content-form textarea{
      font-size: 1.4rem;
      padding: 5px;
      @include media($middle){
        width: 100%;
      }
    }
    #booking-confirm-fieldset{
      @include media($middle){
        padding: 5px 15px;
      }
    }
    #client-adult{
      display: inline-block;
      margin-left: 5px;
      @include media($middle){
        width: 20%;
      }
    }
    #client-child{
      display: inline-block;
      margin-left: 5px;
      @include media($middle){
        width: 20%;
      }
    }
    .input-number{
      br{
        display: none;
      }
      input{
        display: inline-block;
      }
      @include media($middle){
        text-align: left;
        float: none;
      }
      label{
        display: inline;
      }
    }
    .yoyaku-txt{
      text-align: center;
      font-size: 1.4rem;
    }
  }

  #client-company{
    display: none;
  }
  #myoption_hint{
    font-size: 1.4rem;
    padding: 0;
    margin: 0;
  }


}
